import React from "react";
import Button from "../../Button/Button";
import Modal from "../Modal";
const ModalConfirmation = ({ message, subMessage, show, close, mode = "confirmation", successHandler, declineHandler, cancelHandler, textConfirm = "Confirm", textYes = "Yes", }) => {
    function ConfirmationFooter() {
        return (React.createElement("div", { className: "flex" },
            (mode === "confirmation" || mode === "approval") && (React.createElement(Button, { text: "Cancel", clickHandler: () => {
                    cancelHandler?.();
                    close();
                }, 
                // tooltip="Cancel"
                className: "button--height-m font-weight-600 margin-left-auto" })),
            mode === "approval" && (React.createElement(Button, { text: "No", className: "button--height-m font-weight-600 button--margin-left-1", clickHandler: () => {
                    declineHandler?.();
                    close();
                } })),
            React.createElement(Button, { text: mode === "confirmation"
                    ? textConfirm
                    : mode === "message"
                        ? "Proceed"
                        : textYes, className: `button--primary button--height-m font-weight-600 ${mode === "message" ? "margin-left-auto" : "button--margin-left-1"}`, clickHandler: successHandler })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { show: show, close: cancelHandler
                ? () => {
                    cancelHandler();
                    close();
                }
                : close, title: "CONFIRMATION", footer: React.createElement(ConfirmationFooter, null), size: "small", addFooterClassName: "modal__footer--no-bg" },
            message,
            subMessage && (React.createElement("span", { className: "display-inline-block padding-top-1 text-color-grey-71" }, subMessage)))));
};
export default ModalConfirmation;
