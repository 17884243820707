import React from "react";
import classes from "./BadgeDocumentType.module.scss";
import Badge from "../BadgeAligned/Badge";
const DocumentTypeToTextMap = {
    agreement: "Agreement",
    non_agreement: "Non-Agreement",
};
export const getBadgeDocumentTypeProps = ({ type, onClick, size = "xs", }) => {
    return {
        text: DocumentTypeToTextMap[type],
        size: size,
        backgroundColor: type === "agreement"
            ? "blue-light-100"
            : type === "non_agreement"
                ? "orange-light-100"
                : "white",
        textColor: type === "agreement"
            ? "blue-dark-20"
            : type == "non_agreement"
                ? "orange"
                : "black",
        isTextSemiBold: true,
        className: classes.badgeDocument,
        onClick: onClick,
    };
};
const BadgeDocumentType = ({ type, size, onClick }) => {
    return React.createElement(Badge, { ...getBadgeDocumentTypeProps({ type, onClick, size }) });
};
export default BadgeDocumentType;
