import React from "react";
import "../../../styles/base.scss";
import { getTooltipClassNames, getTooltipStyles } from "./Tooltip.utils";
export default function Tooltip({ message, position = "top", size = "default", children, className = "", backgroundWhite = false, gap, delayInSeconds, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { "data-testid": "tooltip", className: getTooltipClassNames({
                position,
                size,
                className,
                backgroundWhite,
            }), style: getTooltipStyles({ gap, delayInSeconds }), "data-tooltip": message }, children)));
}
