import { getEntityStatic, getPosition, getStructureIds, } from "./Canvas.utils.getter_and_setter";
///////////////////////////////////
// START Basic Utility Functions //
///////////////////////////////////
/**
 * Function takes in a canvasData object and
 * returns an array of all the card IDs present in the canvasData.structure object.
 *
 * @param canvasData
 * @returns array of ids
 */
export const getAllCards = (canvasData) => {
    return Object.values(canvasData.structure).flatMap((e) => e.ids);
};
/**
 * Function takes in a canvasData object and returns a set of all the cards in the canvasData structure.
 *
 * @param canvasData
 * @returns set of ids from structure
 */
export const getAllCardsSet = (canvasData) => {
    return new Set(getAllCards(canvasData));
};
/**
 * Function is used to find the root structure ID in the given canvasData object.
 *
 * @param canvasData
 * @returns rootId or undefined
 */
export const getRoot = (canvasData) => {
    const allCards = getAllCardsSet(canvasData);
    return Object.keys(canvasData.structure).find((v) => !allCards.has(v));
};
/**
 * Helper function to check whether the given ID is an Element (Card)
 * and not a structure.
 *
 * @param canvasData
 * @param id
 * @returns true if the id is an element id (card) and false otherwise.
 */
export const isCard = (canvasData, id) => {
    return Object.keys(canvasData.elements).includes(id);
};
/**
 * Helper function to check whether the given ID is an Element (Card)
 * and not a structure in the canvasDataAvailable
 *
 * @param canvasDataAvailable
 * @param id
 * @returns true if the id is an element id (card) and false otherwise.
 */
export const isCardAvailable = (canvasData, id) => {
    return Object.keys(canvasData?.available?.elements || {}).includes(id);
};
/**
 * Create Layout array for all cards inside the given Structure ID
 * for consumption by React-Grid-Layout.
 *
 * @param canvasData
 * @param id
 * @returns array of layout objects which can be read by React-Grid-Layout.
 */
export const getLayout = (canvasData, id) => {
    return canvasData.structure[id].ids.map((e) => ({
        ...getPosition(canvasData, e),
        static: getEntityStatic(canvasData, e),
        i: e,
    }));
};
/**
 * Function that checks if any of the child elements of the given structure ID is selected.
 * If the child is a card, it checks if it is selected. If the child is a structure, it recursively
 * checks its children for selection.
 *
 * @param canvasData
 * @param id
 * @returns true if any child element is selected, otherwise false
 */
export const isStructureSelected = (canvasData, id) => {
    if (!canvasData.structure?.[id]) {
        // Check if the given id is a structure
        throw new Error(`${id} structure not found`);
    }
    const ids = getStructureIds(canvasData, id);
    return ids.some((id) => {
        if (isCard(canvasData, id)) {
            return !!canvasData.elements[id].selected;
        }
        return isStructureSelected(canvasData, id) || false;
    });
};
/**
 * Function that sorts an array of elements or structures based on their positions.
 * It can sort the data either by row first and then column, or by column first and then row.
 *
 * @param data array of element or structure with position as mandotary
 * @param rowFirst (optional) if true, then sort row first then col otherwise col then row.
 * @returns data
 */
export function sortLayout(data, rowFirst) {
    return data.sort((a, b) => {
        if (rowFirst) {
            if (a.x !== b.x) {
                return a.x - b.x;
            }
            return a.y - b.y;
        }
        if (a.y !== b.y) {
            return a.y - b.y;
        }
        return a.x - b.x;
    });
}
/**
 * Function checks if two positions on a canvas overlap with each other.
 *
 * @param sourcePosition
 * @param targetPosition
 * @returns true, if overlap otherwise false
 */
export function isOverlap(sourcePosition, targetPosition) {
    if (sourcePosition.x + sourcePosition.w <= targetPosition.x)
        return false;
    if (sourcePosition.x >= targetPosition.x + targetPosition.w)
        return false;
    if (sourcePosition.y + sourcePosition.h <= targetPosition.y)
        return false;
    if (sourcePosition.y >= targetPosition.y + targetPosition.h)
        return false;
    return true; // boxes overlap
}
/**
 * Function takes in an array of positions (data) and a position object (position).
 * It checks if thier is any entity having coordinate x with any of the coordinate x in acc and
 * returns an entity that does account the height of the found entity.
 *
 * @param data array of type PositionType
 * @param position
 * @returns entity
 */
export function findOverlappingEntity(data, position) {
    return data.find(({ x, w }) => (position.x >= x && position.x < x + w) ||
        (position.x + position.w >= x && position.x + position.w < x + w));
}
/////////////////////////////////
// END Basic Utility Functions //
/////////////////////////////////
