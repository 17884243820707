/** @format */
import React from "react";
import FileUploadingIcon from "../../../static/icons/file-uploading.svg";
import "../../../styles/base.scss";
import "./DocumentUploading.scss";
import ProgressBar from "../../Other/ProgressBar";
import Text from "../../Text/Text";
const DocumentUploading = ({ uploadingImageSrc = null, parentProps = {
    className: "",
}, counterRef, progress = 0, showProgressBar = false, }) => {
    const wrappedParentProps = {
        ...parentProps,
        className: "uploading-document " + parentProps?.className,
    };
    return (React.createElement("div", { "data-testid": "uploading-document", ...wrappedParentProps },
        uploadingImageSrc ? (React.createElement("img", { src: uploadingImageSrc })) : (React.createElement(React.Fragment, null,
            React.createElement(FileUploadingIcon, null),
            React.createElement(Text, { className: "uploading-document--counter-text", innerRef: counterRef, weight: "bold", size: "large", color: "primary" }, "0%"))),
        React.createElement(Text, { className: "uploading-document--label", color: "primary", weight: "semibold", size: "small" }, "Document Uploading"),
        React.createElement(Text, { align: "center", color: "secondary", size: "0.75" },
            "Hold tight while we upload",
            React.createElement("br", null),
            " your document"),
        React.createElement("div", { className: "uploading-document--progress-bar" }, showProgressBar && React.createElement(ProgressBar, { progress: progress }))));
};
export default DocumentUploading;
