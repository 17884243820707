export default {
    primary: "button--primary",
    secondary: "button--secondary",
    "secondary-transparent": "button--secondary-transparent",
    success: "button--success",
    danger: "button--danger",
    link: "button--link",
    text: "button--text",
    plain: "button--plain",
    transparent: "button--transparent",
    select: "button--select",
    prussian: "button--prussian",
    // "state-error": "button--state-error",
    // "state-warning": "button--state-warning",
    // "state-success": "button--state-success",
    // "state-secondary-very-light": "button--state-secondary-very-light",
    "": "",
};
