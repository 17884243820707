import React from "react";
import classes from "./Checkbox.module.scss";
const Checkbox = ({ label = "", checked = false, onChange = () => null, }) => {
    const handleChange = () => {
        onChange(!checked);
    };
    return (React.createElement("label", { className: classes.wrapper },
        React.createElement("input", { type: "checkbox", checked: checked, onChange: handleChange, className: classes.input }),
        React.createElement("span", { className: classes.checkmark }),
        React.createElement("span", { className: classes.label }, label)));
};
export default Checkbox;
