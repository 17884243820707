import React from "react";
import AfternoonIcon from "../../../static/icons/afternoon.svg";
import EveningIcon from "../../../static/icons/evening.svg";
import MorningIcon from "../../../static/icons/morning.svg";
import classes from "./Greetings.module.scss";
import Text from "../../Text/TextAligned/Text";
import Icon from "../Icon";
const Greetings = ({ name, mode }) => {
    return (React.createElement("span", { className: classes.greetings },
        mode === "morning" ? (React.createElement(Icon, { IC: MorningIcon, height: "35", width: "35" })) : mode === "afternoon" ? (React.createElement(Icon, { IC: AfternoonIcon, height: "35", width: "35" })) : (React.createElement(Icon, { IC: EveningIcon, height: "35", width: "35" })),
        React.createElement(Text, { variant: "heading-lg" },
            "Good",
            " ",
            `${mode === "morning"
                ? "Morning"
                : mode === "afternoon"
                    ? "Afternoon"
                    : "Evening"}`,
            ", ",
            name)));
};
export default Greetings;
