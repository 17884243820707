import { $createParagraphNode, $getSelection, $isRangeSelection, $isTextNode, } from "lexical";
import { BlockType, FontFamilyType } from "./EditorPluginToolbar.types";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, } from "@lexical/list";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $getNearestBlockElementAncestorOrThrow } from "@lexical/utils";
import { $isHeadingNode, $createHeadingNode, $isQuoteNode, } from "@lexical/rich-text";
import { $setBlocksType, $isAtNodeEnd, $patchStyleText, } from "@lexical/selection";
export const MIN_ALLOWED_FONT_SIZE = 8;
export const MAX_ALLOWED_FONT_SIZE = 72;
export const DEFAULT_FONT_SIZE = 15;
export const DEFAULT_FONT_COLOR = "#000";
export const DEFAULT_FONT_FACE = "Arial";
export const BLOCK_ITEMS = [
    {
        id: "paragraph",
        text: "Paragraph",
        category: BlockType.paragraph,
    },
    {
        id: "h1",
        text: "Heading 1",
        category: BlockType.h1,
    },
    {
        id: "h2",
        text: "Heading 2",
        category: BlockType.h2,
    },
    {
        id: "h3",
        text: "Heading 3",
        category: BlockType.h3,
    },
];
export const FONT_ITEMS = [
    {
        id: FontFamilyType.ARIAL,
        text: "Arial",
    },
    {
        id: FontFamilyType.COURIER,
        text: "Couier New",
    },
    {
        id: FontFamilyType.GEORGIA,
        text: "Georgia",
    },
    {
        id: FontFamilyType.TIMES_NEW_ROMAN,
        text: "Times New Roman",
    },
    {
        id: FontFamilyType.TREBUCHET,
        text: "Trebuchet MS",
    },
    {
        id: FontFamilyType.VERDANA,
        text: "Verdana",
    },
];
export const COLOR_ITEMS = [
    {
        id: "#1A1A1A",
        text: "black",
        color: "#1A1A1A",
    },
    {
        id: "#93182F",
        text: "primary-red",
        color: "#93182F",
    },
    {
        id: "#F3AE3B",
        text: "state-warning",
        color: "#F3AE3B",
    },
    {
        id: "#10B981",
        text: "state-success",
        color: "#10B981",
    },
];
export const getNextDecrementFontSize = (currentFontSize) => {
    switch (true) {
        case currentFontSize > MAX_ALLOWED_FONT_SIZE:
            return MAX_ALLOWED_FONT_SIZE;
        case currentFontSize >= 48:
            return currentFontSize - 12;
        case currentFontSize >= 24:
            return currentFontSize - 4;
        case currentFontSize >= 14:
            return currentFontSize - 2;
        case currentFontSize >= 9:
            return currentFontSize - 1;
        default:
            return MIN_ALLOWED_FONT_SIZE;
    }
};
export const getNextIncrementFontSize = (currentFontSize) => {
    switch (true) {
        case currentFontSize < MIN_ALLOWED_FONT_SIZE:
            return MIN_ALLOWED_FONT_SIZE;
        case currentFontSize < 12:
            return currentFontSize + 1;
        case currentFontSize < 20:
            return currentFontSize + 2;
        case currentFontSize < 36:
            return currentFontSize + 4;
        case currentFontSize <= 60:
            return currentFontSize + 12;
        default:
            return MAX_ALLOWED_FONT_SIZE;
    }
};
export function formatFontFace(editor, fontFamily) {
    editor.update(() => {
        const selection = $getSelection();
        if (selection) {
            $patchStyleText(selection, {
                ["font-family"]: fontFamily,
            });
        }
    });
}
export function formatColor(editor, color) {
    editor.update(() => {
        const selection = $getSelection();
        if (selection) {
            $patchStyleText(selection, {
                ["color"]: color,
            });
        }
    });
}
export function formatFontSize(editor, fontSize) {
    editor.update(() => {
        const selection = $getSelection();
        if (selection) {
            $patchStyleText(selection, {
                ["font-size"]: `${fontSize}px`,
            });
        }
    });
}
export function formatParagraph(editor) {
    editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createParagraphNode());
        }
    });
}
export function formatHeading(editor, blockType, headingSize) {
    if (blockType !== headingSize) {
        editor.update(() => {
            const selection = $getSelection();
            $setBlocksType(selection, () => $createHeadingNode(headingSize));
        });
    }
}
export function formatBulletList(editor, blockType) {
    if (blockType !== "bullet") {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
    else {
        formatParagraph(editor);
    }
}
export function formatAlphabetList(editor, blockType) {
    if (blockType !== "bullet") {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
    else {
        formatParagraph(editor);
    }
}
export function formatNumberedList(editor, blockType) {
    if (blockType !== "number") {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    }
    else {
        formatParagraph(editor);
    }
}
export function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    else {
        return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
    }
}
export const clearFormatting = (editor) => {
    editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchor = selection.anchor;
            const focus = selection.focus;
            const nodes = selection.getNodes();
            const extractedNodes = selection.extract();
            if (anchor.key === focus.key && anchor.offset === focus.offset) {
                return;
            }
            nodes.forEach((node, idx) => {
                // We split the first and last node by the selection
                // So that we don't format unselected text inside those nodes
                if ($isTextNode(node)) {
                    // Use a separate variable to ensure TS does not lose the refinement
                    let textNode = node;
                    if (idx === 0 && anchor.offset !== 0) {
                        textNode = textNode.splitText(anchor.offset)[1] || textNode;
                    }
                    if (idx === nodes.length - 1) {
                        textNode = textNode.splitText(focus.offset)[0] || textNode;
                    }
                    /**
                     * If the selected text has one format applied
                     * selecting a portion of the text, could
                     * clear the format to the wrong portion of the text.
                     *
                     * The cleared text is based on the length of the selected text.
                     */
                    // We need this in case the selected text only has one format
                    const extractedTextNode = extractedNodes[0];
                    if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
                        textNode = extractedTextNode;
                    }
                    if (textNode.__style !== "") {
                        textNode.setStyle("");
                    }
                    if (textNode.__format !== 0) {
                        textNode.setFormat(0);
                        $getNearestBlockElementAncestorOrThrow(textNode).setFormat("");
                    }
                    node = textNode;
                }
                else if ($isHeadingNode(node) || $isQuoteNode(node)) {
                    node.replace($createParagraphNode(), true);
                }
                else if ($isDecoratorBlockNode(node)) {
                    node.setFormat("");
                }
            });
        }
    });
};
