import React from "react";
import "./ProgressBar.scss";
const ProgressBar = ({ progress }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "progress-bar" },
            React.createElement("div", { className: "progress-bar__fill", style: {
                    "--fill-width": `${Math.round(progress * 100)}%`,
                } }))));
};
export default ProgressBar;
