export default {
    small: "padding-tb-0p375 padding-lr-0p75 font-size-0p75",
    semimedium: "padding-tb-0p5 padding-lr-0p75 font-size-0p875",
    "semimedium-1": "padding-tb-0p625 padding-lr-0p8125 font-size-0p875",
    "semimedium-2": "padding-tb-0p625 padding-lr-1 font-size-0p875",
    medium: "padding-0p75 font-size-0p875",
    large: "padding-tb-0p8 padding-lr-1p25 font-size-1",
    narrow: "button--narrow",
    "font-full": "button--font-full",
    "font-90": "button--font-90",
    "height-m": "button--height-m",
    "wide-full": "button--wide-full",
    "wide-m": "button--wide-m",
    "wide-l": "button--wide-l",
    "wide-l-height-m": "button--wide-l button--height-m",
    "wide-height-m": "button--wide-m button--height-m",
    "no-pad": "button--no-pad",
    "less-pad-tb": "button--less-pad-tb",
    "less-pad-lr": "button--less-pad-lr",
    "no-pad-lr": "button--no-pad-lr",
    "no-pad-tb": "button--no-pad-tb",
    "size-0.65 with 8 4 padding": "font-size-0p625 padding-tb-0p25 padding-lr-0p5",
    "size-0.65 with no padding": "font-size-0p625 button--no-pad",
    // "size-0.75 with 0.5em 1em padding":
    //   "font-size-0p75 padding-tb-0p5 padding-lr-1",
    // "size-0.75 with 0.4375em 0.3125em padding":
    //   "font-size-0p75 padding-tb-0p4375-lr-0p3125",
    "padding-0p375": "padding-0p375",
    "no-pad-tb-0p5-pad-lr": "button--no-pad-tb-0p5-pad-lr",
    "pad-0p5": "button--pad-0p5",
    "": "",
};
