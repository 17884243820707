import debounce from "lodash.debounce";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, } from "react";
import React from "react";
import { CDRT, } from "./Canvas.types";
import { canvasDataReducer } from "./canvasDataReducer";
const CanvasDataContext = createContext(null);
const CanvasDataDispatchContext = createContext(null);
const OnLayoutChangeContext = createContext(() => null);
const OnCanvasDataChange = createContext(() => null);
export const useCanvasData = () => {
    return useContext(CanvasDataContext);
};
export const useCanvasDataDispatch = () => {
    return useContext(CanvasDataDispatchContext);
};
export const useOnLayoutChange = () => {
    return useContext(OnLayoutChangeContext);
};
export const useOnCanvasDataChange = () => {
    return useContext(OnCanvasDataChange);
};
const CanvasDataProvider = ({ canvasData, onCanvasDataChange, children, }) => {
    const memoizedCanvasData = useMemo(() => canvasData, [canvasData]);
    const [data, dispatch] = useReducer(canvasDataReducer, memoizedCanvasData);
    const debouncedOnCanvasDataChange = debounce((canvasData) => onCanvasDataChange(canvasData), 100);
    const onLoadedCanvasDataChange = useCallback((canvasData) => {
        if (data._loaded) {
            // onCanvasDataChange(canvasData);
            debouncedOnCanvasDataChange(canvasData);
        }
    }, [data._loaded]);
    const onLayoutChange = (layout) => {
        dispatch({
            type: CDRT.UPDATE_LAYOUT,
            data: {
                layout: layout,
                onCanvasDataChange: onLoadedCanvasDataChange,
            },
        });
    };
    useEffect(() => {
        dispatch({
            type: CDRT.SET_CANVAS_DATA,
            data: {
                canvasData: memoizedCanvasData,
                onCanvasDataChange: onLoadedCanvasDataChange,
            },
        });
    }, [memoizedCanvasData]);
    useEffect(() => {
        dispatch({
            type: CDRT.SET_LOADED,
            data: {
                value: true,
                onCanvasDataChange: onLoadedCanvasDataChange,
            },
        });
    }, [data._loaded]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CanvasDataContext.Provider, { value: data },
            React.createElement(CanvasDataDispatchContext.Provider, { value: dispatch },
                React.createElement(OnCanvasDataChange.Provider, { value: onLoadedCanvasDataChange },
                    React.createElement(OnLayoutChangeContext.Provider, { value: onLayoutChange }, children))))));
};
export default CanvasDataProvider;
