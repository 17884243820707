import React, { useCallback, useEffect, useState } from "react";
import "./MultipleTextSelectBox.scss";
import DropdownSelect from "../../Dropdown/DropdownSelect";
import Text from "../../Text/Text";
import { getDefaultText } from "../MultipleTextBox/MultipleTextBox.utils";
function MultipleTextSelectBox({ items, itemId = "", toggle, className = "", }) {
    const [selectedValue, setSelectedValue] = useState(getDefaultText({ itemId, items }, (currentIndex, items) => {
        if (currentIndex > -1) {
            return items[currentIndex];
        }
        return null;
    }));
    useEffect(() => {
        setSelectedValue(getDefaultText({ itemId, items }, (currentIndex, items) => {
            if (currentIndex > -1) {
                return items[currentIndex];
            }
            return null;
        }));
    }, [itemId, items]);
    const handleOnItemClick = useCallback((itemProps) => {
        if (typeof toggle === "function") {
            return toggle(itemProps);
        }
        const { id: clickId } = itemProps;
        const selectedItem = items.find(({ id }) => id === clickId);
        if (selectedItem) {
            setSelectedValue(selectedItem);
        }
    }, []);
    return (React.createElement("div", { className: `multiple-text-select-box ${className}` },
        React.createElement(DropdownSelect, { noSelectedText: "Select Section", selectedItem: selectedValue
                ? { id: selectedValue.id, text: selectedValue.title || "" }
                : undefined, itemsProps: (items || []).map(({ id, title = "" }) => ({
                text: title,
                id,
            })), onItemClick: handleOnItemClick }),
        React.createElement(Text, { color: "grey-dark", size: "0.75", weight: "400" }, selectedValue?.text || "")));
}
export default MultipleTextSelectBox;
