import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CLICK_COMMAND, COMMAND_PRIORITY_NORMAL, createCommand, } from "lexical";
import React, { useEffect, useState } from "react";
import EditorMessage from "../../Other/EditorMessage";
import { $deleteTextAtOffset, $deleteTextAtSelection, $insertTextAtOffset, $insertTextAtSelection, $replaceTextAtOffset, } from "./EditorPluginOffset.utils";
export const INSERT_AT_OFFSET_COMMAND = createCommand("INSERT_AT_OFFSET_COMMAND");
export const REPLACE_AT_OFFSET_COMMAND = createCommand("REPLACE_AT_OFFSET_COMMAND");
export const DELETE_AT_OFFSET_COMMAND = createCommand("DELETE_AT_OFFSET_COMMAND");
export const INSERT_AT_SELECTION_COMMAND = createCommand("INSERT_AT_SELECTION_COMMAND");
export const DELETE_AT_SELECTION_COMMAND = createCommand("DELETE_AT_SELECTION_COMMAND");
export const INSERT_AT_SELECTION_INPUT_COMMAND = createCommand("INSERT_AT_SELECTION_INPUT_COMMAND");
const EditorPluginOffset = ({ hideEditorFloatOnId, unhideEditorFloatOnId, }) => {
    const [editor] = useLexicalComposerContext();
    const [isSelectionInputMode, setIsSelectionInputMode] = useState(false);
    const [insertAtSelectionIndicate, setInsertAtSelectionIndicate] = useState();
    const [textToInsertAtSelectionInput, setTextToInsertAtSelectionInput] = useState();
    const [onDoneInsertAtSelectionInput, setOnDoneInsertAtSelectionInput] = useState();
    const FLOAT_IDS_TO_HIDE = ["document", "recommend"];
    useEffect(() => {
        return editor.registerCommand(INSERT_AT_OFFSET_COMMAND, (payload, editor) => {
            editor.update(() => $insertTextAtOffset(payload.startOffset, payload.text, payload.indicate));
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    }, [editor]);
    useEffect(() => {
        return editor.registerCommand(REPLACE_AT_OFFSET_COMMAND, (payload, editor) => {
            editor.update(() => $replaceTextAtOffset(payload.startOffset, payload.endOffset, payload.text, payload.indicate));
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    }, [editor]);
    useEffect(() => {
        return editor.registerCommand(DELETE_AT_OFFSET_COMMAND, (payload, editor) => {
            editor.update(() => $deleteTextAtOffset(payload.startOffset, payload.endOffset));
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    }, [editor]);
    useEffect(() => {
        return editor.registerCommand(INSERT_AT_SELECTION_COMMAND, (payload, editor) => {
            editor.update(() => $insertTextAtSelection(payload.text, undefined, payload.indicate));
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    }, [editor]);
    useEffect(() => {
        return editor.registerCommand(DELETE_AT_SELECTION_COMMAND, (_, editor) => {
            editor.update(() => $deleteTextAtSelection());
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    }, [editor]);
    useEffect(() => {
        return editor.registerCommand(INSERT_AT_SELECTION_INPUT_COMMAND, (payload) => {
            setIsSelectionInputMode(true);
            setTextToInsertAtSelectionInput(payload.text);
            setInsertAtSelectionIndicate(payload.indicate);
            setOnDoneInsertAtSelectionInput(() => payload.onDoneInsert);
            FLOAT_IDS_TO_HIDE.map((i) => {
                hideEditorFloatOnId?.(i);
            });
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    });
    useEffect(() => {
        return editor.registerCommand(CLICK_COMMAND, () => {
            if (isSelectionInputMode) {
                setIsSelectionInputMode(false);
                editor.update(() => {
                    $insertTextAtSelection(textToInsertAtSelectionInput || "", undefined, insertAtSelectionIndicate);
                    onDoneInsertAtSelectionInput?.();
                    setTextToInsertAtSelectionInput(undefined);
                    setInsertAtSelectionIndicate(undefined);
                    setOnDoneInsertAtSelectionInput(undefined);
                });
                FLOAT_IDS_TO_HIDE.map((i) => {
                    unhideEditorFloatOnId?.(i);
                });
            }
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    });
    const onCancel = () => {
        setIsSelectionInputMode(false);
        setTextToInsertAtSelectionInput(undefined);
        FLOAT_IDS_TO_HIDE.map((i) => {
            unhideEditorFloatOnId?.(i);
        });
    };
    return (React.createElement(React.Fragment, null, isSelectionInputMode && (React.createElement(EditorMessage, { message: "Click where you want the section to be added.", onCancel: onCancel }))));
};
export default EditorPluginOffset;
