import React, { forwardRef, useMemo, useState } from "react";
import DownloadIcon from "../../../static/icons/download.svg";
import FileNotSupportedIcon from "../../../static/icons/file-not-supported.svg";
import "./CardRiskFrequencies.scss";
import Accordion from "../../Accordion/Accordion";
import BadgeAligned from "../../Badge/BadgeAligned";
import Button from "../../Button/ButtonRevamped/Button";
import EmptyContentBase from "../../Other/EmptyContentBase";
import HeaderIconAndTitle from "../../Other/HeaderIconAndTitle";
import Text from "../../Text/Text";
import TextAligned from "../../Text/TextAligned";
import Card from "../Card/Card";
const CardRiskFrequencies = ({ title, Icon, frequencies, onClickExport, ...cardBaseProps }, ref) => {
    const [minFreq, setMinFreq] = useState(0.1);
    const frequenciesFilteredEntities = frequencies.map((f) => ({
        ...f,
        entities: f.entities.filter((e) => e.entityFrequencyNorm >= minFreq),
    }));
    const filteredFrequencies = frequenciesFilteredEntities.filter((e) => e.clauseFrequencyNorm >= minFreq);
    const isEmpty = useMemo(() => filteredFrequencies.length === 0, []);
    const headerChildren = (React.createElement(React.Fragment, null,
        React.createElement(HeaderIconAndTitle, { title: title, iconProps: { IC: Icon } },
            React.createElement(Button, { size: "no-pad-tb", variant: "plain", onClick: () => onClickExport(minFreq), tooltipProps: { message: "Download Guide", position: "left" } }, React.createElement(DownloadIcon, { width: "20", height: "20" })))));
    const accordionItemsData = filteredFrequencies.map((c) => ({
        header: {
            children: (React.createElement("div", { className: "card-risk-frequencies__accordion__header" },
                React.createElement("div", { className: "card-risk-frequencies__accordion__header__text-badge" },
                    React.createElement(Text, { display: "block", size: "0.9", color: "black" }, c.clause),
                    c.entities.length > 0 && (React.createElement(BadgeAligned, { outline: "grey-light-50", text: "Click To View Sub-Clauses" }))),
                React.createElement(Button, { isBadge: true, borderRadius: "16", className: "card-risk-frequencies__accordion__header__badge" }, `${c.clauseFrequencyRaw} Documents (${Math.round(c.clauseFrequencyNorm * 100)}%)`))),
        },
        body: {
            children: (React.createElement("ul", { className: "card-risk-frequencies__accordion__body__list-unordered" }, c.entities.map((e, i) => (React.createElement("li", { key: i, className: "card-risk-frequencies__accordion__body__list-item" }, React.createElement(Text, { color: "grey-dark", size: "0.875" }, e.entityDescription)))))),
        },
    }));
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { headerChildren: headerChildren, bodyHeightFill: true, bodyNoPad: true, ...cardBaseProps, ref: ref }, isEmpty ? (React.createElement(EmptyContentBase, { title: "It looks like this document type isn\u2019t supported just yet!", mediaSrc: { IC: FileNotSupportedIcon, height: "15em" }, mediaType: "svg", reasonTextProps: {
                children: (React.createElement(React.Fragment, null,
                    "This might be because your document contains text that we cannot identify.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    " Don\u2019t Worry! We\u2019re already working on fixing it. Support for more file types is",
                    " ",
                    React.createElement(TextAligned, { color: "red-primary" }, "coming soon!"))),
            } })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "card-risk-frequencies__range" },
                React.createElement(Text, { color: "black", size: "0.9" },
                    "Select Minimum Frequency (",
                    Math.round(minFreq * 100),
                    "%)"),
                React.createElement("input", { className: "card-risk-frequencies__range__input", type: "range", min: 0, max: 1, step: 0.1, value: minFreq, onChange: (e) => setMinFreq(Number(e.target.value)) })),
            accordionItemsData && accordionItemsData.length > 0 && (React.createElement(TextAligned, { className: "card-risk-frequencies__clauses-heading", variant: "heading-sm", isBlock: true }, "Clauses")),
            React.createElement(Accordion, { mode: "multiple", itemsData: accordionItemsData }))))));
};
export default forwardRef(CardRiskFrequencies);
