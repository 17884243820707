/**
 * Extracts variant properties from a given variant string.
 * @param variant - The variant string to extract properties from.
 * @returns An object containing variant properties such as variant type, size, weight, and underline status.

 */
// body-sm/underline
export function parseTextVariantStr(variantStr) {
    const match = variantStr.match(/(body|heading)-([a-z]+)(\/(semibold|underline|bold))?/);
    let variant;
    let size;
    let weight;
    let isUnderline = false;
    if (match) {
        variant = match[1];
        size = match[2];
        weight = match[4];
        if (weight === "underline") {
            isUnderline = true;
            weight = "semibold";
        }
        if (variant === "heading") {
            weight = "semibold";
        }
        return { variant, size, isUnderline, weight };
    }
    throw Error(`Got invalid text variant ${variantStr}`);
}
