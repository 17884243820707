import clsx from "clsx";
import React from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./Drawer.module.scss";
const Drawer = ({ children, position, show, close, title, headerChildren, footerChildren, size = "md", }) => {
    return (React.createElement(CSSTransition, { in: show, timeout: 300, classNames: {
            enter: classes[`drawer-${position}-enter`],
            enterActive: classes[`drawer-${position}-enter-active`],
            exit: classes[`drawer-${position}-exit`],
            exitActive: classes[`drawer-${position}-exit-active`],
        }, unmountOnExit: true, mountOnEnter: true },
        React.createElement("div", { className: classes.drawer, onClick: close },
            React.createElement("div", { className: clsx(classes.content, classes[position], classes[size]), onClick: (e) => e.stopPropagation() },
                (title || headerChildren) && (React.createElement("div", { className: classes.header },
                    title && React.createElement("h2", null, title),
                    headerChildren)),
                React.createElement("div", { className: classes.body }, children),
                footerChildren && (React.createElement("div", { className: classes.footer }, footerChildren))))));
};
export default Drawer;
