/**
 * Extracts a string from a ReactNode object recursively.
 * @param obj - The ReactNode object to extract the string from.
 * @returns The extracted string.
 */
import React from "react";
export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
export function groupReduce(elements, keyFunc) {
    const groupedElements = elements.reduce((g, e) => {
        g[keyFunc(e)] = g[keyFunc(e)] || [];
        g[keyFunc(e)].push(e);
        return g;
    }, Object.create(null));
    return groupedElements;
}
/**
 * Creates color mapping for the given set of labels.
 * @param labels
 * @returns object with label on keys and color string on value.
 */
export function createColorMap(labels) {
    const colors = [
        "#E1F2D6",
        "#EFD7B9",
        "#91C6E5",
        "#EAE0B0",
        "#E8B7AA",
        "#9DC6B7",
        "#BCC6E2",
        "#DCCCED",
        "#B5E2E2",
        "#EF9AB2",
        "#C3CC8F",
        "#6EBCCE",
        "#B39FCE",
        "#D8BE7D",
        "#CE99E0",
        "#C68DA2",
        "#7DBC77",
        "#87C1BC",
        "#A2A2DB",
        "#E2B866",
        "#53A587",
        "#C27799",
        "#26BC9F",
    ];
    const uniqueLabels = [...new Set(labels)];
    return uniqueLabels.reduce((acc, e, i) => {
        acc[e] = colors[i % colors.length];
        return acc;
    }, {});
}
export function riskHighlightColor(riskLevel) {
    switch (riskLevel) {
        case "High":
            return "#FFCCCB";
        case "Medium":
            return "#FFE28F";
        case "Low":
            return "#7DBC77";
    }
}
// Same as in Webapp
// TODO: Make sure a single implemetation remains
//       Could convert it into a component and share
//       it to be used in webapp, or figure out a way
//       to export utility functions from components
export function formatDate(date) {
    date = new Date(date);
    return date.toLocaleString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
    });
}
// https://stackoverflow.com/a/18650828
export function formatBytes(bytes, decimals = 2) {
    if (!+bytes)
        return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
        "Bytes",
        "kb", // "KiB",
        "mb", // "MiB",
        "gb", // "GiB",
        "tb", // "TiB",
        "pb", // "PiB",
        "eb", // "EiB",
        "zb", // "ZiB",
        "yb", // "YiB",
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
function getKey() {
    return { key: 13 };
}
function ceasarCipher(message, count) {
    const a = "abcdefghijklmnopqrstuvwxyz".split("");
    let digest = "";
    for (const i of message) {
        const index = a.indexOf(i);
        const newIndex = (index + count) % (a.length - 1);
        digest += a[newIndex];
    }
    return digest;
}
export function cipherEncrypt(message) {
    const { key } = getKey();
    return ceasarCipher(message, key);
}
export function cipherDecrypt(digest) {
    const { key } = getKey();
    return ceasarCipher(digest, -key);
}
export function getStorageKey(name, id) {
    name = cipherEncrypt(name.toLocaleLowerCase());
    return `component/${name}/${id}`;
}
export function clearStorage() {
    window.localStorage.clear();
}
export function setStorage(name, id, value) {
    window.localStorage.setItem(getStorageKey(name, id), JSON.stringify(value));
}
export function getStorage(name, id, defaultValue) {
    const value = window.localStorage.getItem(getStorageKey(name, id));
    if (value === null) {
        return defaultValue;
    }
    return JSON.parse(value);
}
export function extractString(obj) {
    if (typeof obj === "string")
        return obj;
    else if (React.isValidElement(obj)) {
        return extractString(obj.props.children);
    }
    else if (Array.isArray(obj)) {
        return obj.map(extractString).join(" ");
    }
    else
        return obj.toString();
}
export default { extractString };
// Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values_inclusive
export function getRandomInt(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}
export function camelCaseToTitleCase(input) {
    return toTitleCase(input.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`));
}
export function camelCaseToSnakeCase(input) {
    return input.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
