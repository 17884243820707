import React from "react";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "../../../static/icons/close.svg";
import "../../../styles/base.scss";
import "./Modal.scss";
const Modal = ({ children, show, close, title, addHeader, footer, size = "medium", addHeaderClassName, addFooterClassName, addContentClassName, addClassName, addBodyClassName, }) => {
    let className = "modal";
    if (addClassName)
        className += " " + addClassName;
    let headerClassName = "modal__header " + (title ? "modal__header--border-bottom" : "");
    if (addHeaderClassName)
        headerClassName += " " + addHeaderClassName;
    let contentClassName = `modal__content modal__content--${size}`;
    if (addContentClassName)
        contentClassName += " " + addContentClassName;
    let footerClassName = "modal__footer";
    if (addFooterClassName)
        footerClassName += " " + addFooterClassName;
    let bodyClassName = "modal__body";
    if (addBodyClassName)
        bodyClassName += " " + addBodyClassName;
    return (React.createElement(CSSTransition, { classNames: "modal", in: show, mountOnEnter: true, unmountOnExit: true, timeout: 150 },
        React.createElement("div", { className: className, onClick: close },
            React.createElement("div", { className: contentClassName, onClick: (e) => e.stopPropagation() },
                (addHeader || title) && (React.createElement("div", { className: headerClassName },
                    title && React.createElement("span", { className: "modal__title" }, title),
                    addHeader,
                    close && (React.createElement(CloseIcon, { className: "modal__close-svg", fill: "#1A1A1A", onClick: close })))),
                React.createElement("div", { className: bodyClassName }, children),
                footer && React.createElement("footer", { className: footerClassName }, footer)))));
};
export default Modal;
