import React, { forwardRef } from "react";
import "./InputText.scss";
import borderRadiusMapping from "../../../constants/borderRadiusMapping";
import colorMapping from "../../../constants/colorMapping";
import fontSizeMapping from "../../../constants/fontSizeMapping";
import fontWeightMapping from "../../../constants/fontWeightMapping";
function InputText({ value, placeholder = "", onChange = () => null, className = "", weight = "", fontSize = "", color = "", borderRadius = "", type, disabled, style = {}, ...rest }, ref) {
    const InputTextClasses = `input-text
    ${colorMapping[color]}
    ${fontSizeMapping[fontSize]}
    ${fontWeightMapping[weight]}
    ${borderRadiusMapping[borderRadius]}
    ${className}`;
    return (React.createElement("input", { type: type, className: InputTextClasses, value: value, placeholder: placeholder, onChange: onChange, ref: ref, style: style, disabled: disabled, ...rest }));
}
export default forwardRef(InputText);
