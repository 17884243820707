import React, { forwardRef, useEffect, useState } from "react";
import CopySmIcon from "../../../static/icons/copy-sm.svg";
import classes from "./CardDigest.module.scss";
import Button from "../../Button/ButtonAligned/Button";
import { TitleAndText } from "../../Other";
import HeaderIconAndTitle from "../../Other/HeaderIconAndTitle";
import { default as IconComponent } from "../../Other/Icon";
import Tabs from "../../Other/Tabs";
import Text from "../../Text/TextAligned/Text";
import Card from "../Card/Card";
const CardDigest = ({ title, Icon, getDocumentSummary, getSummary, sectionItems, ...cardBaseProps }, ref) => {
    // TODO: set loading false when all loading is completed
    // const [sectionCopied, setSectionCopied] = useState<boolean[]>(
    //   sectionItems.map(() => false),
    // );
    const [copiedAll, setCopiedAll] = useState(false);
    const [isDocumentSummaryLoading, setIsDocumentSummaryLoading] = useState(true);
    const [documentSummary, setDocumentSummary] = useState("");
    const [sectionsData, setSectionsData] = useState(sectionItems.reduce((acc, s) => {
        acc[s.id] = { ...s, isLoading: true, summary: "", isCopied: false };
        return acc;
    }, {}));
    const sectionsDataArray = Object.values(sectionsData);
    useEffect(() => {
        setIsDocumentSummaryLoading(true);
        getDocumentSummary(setDocumentSummary, () => setIsDocumentSummaryLoading(false));
        sectionsDataArray.map((s) => getSummary(s.text, (updateFn) => {
            setSectionsData((old) => ({
                ...old,
                [s.id]: { ...old[s.id], summary: updateFn(old[s.id].summary) },
            }));
        }, () => {
            setSectionsData((old) => ({
                ...old,
                [s.id]: { ...old[s.id], isLoading: false },
            }));
        }));
    }, []);
    const handleCopy = (text, id) => {
        navigator.clipboard.writeText(text);
        setSectionsData((old) => ({
            ...old,
            [id]: { ...old[id], isCopied: true },
        }));
        setTimeout(() => {
            setSectionsData((old) => ({
                ...old,
                [id]: { ...old[id], isCopied: false },
            }));
        }, 1000);
    };
    const handleCopyAll = () => {
        const formattedSummaries = sectionsDataArray
            .map(({ summary, title }) => `${title}\n${summary}`)
            .join("\n\n");
        navigator.clipboard.writeText(formattedSummaries);
        setCopiedAll(true);
        setTimeout(() => setCopiedAll(false), 1000);
    };
    return (React.createElement(Card, { ...cardBaseProps, ref: ref, bodyHeightFill: true, bodyNoPad: true, headerChildren: React.createElement(HeaderIconAndTitle, { title: title, iconProps: { IC: Icon }, isLoading: isDocumentSummaryLoading ||
                sectionsDataArray.some((s) => s.isLoading) }) },
        React.createElement(Tabs, { tabs: [
                {
                    id: "Document Summary",
                    text: "Document Summary",
                    children: (React.createElement("div", { className: classes.documentSummary },
                        React.createElement(Text, null, documentSummary))),
                },
                {
                    id: "Key Ideas Summary",
                    text: "Key Ideas Summary",
                    children: (React.createElement("div", { className: classes.sectionSummaries },
                        sectionsDataArray.map((s, i) => {
                            return (React.createElement("div", { className: classes.sectionSummary, key: s.id, onClick: () => handleCopy(s.summary, s.id) },
                                React.createElement(TitleAndText, { key: s.id, headerText: s.title, contentText: s.summary, headerChildren: React.createElement("span", { className: classes.copy },
                                        React.createElement(IconComponent, { IC: CopySmIcon, size: "xs", stroke: "#888888" }),
                                        React.createElement(Text, { variant: "body-xs/semibold", color: "grey-dark-60" }, sectionsData[s.id].isCopied
                                            ? "Copied!"
                                            : "Click to Copy")) }),
                                i !== sectionsDataArray.length - 1 && (React.createElement("span", { className: classes.divider }))));
                        }),
                        React.createElement(Button, { text: copiedAll ? "Copied!" : "Copy All", variant: "tertiary", size: "md", outline: true, isFullWidth: true, onClick: handleCopyAll }))),
                },
            ] })));
};
export default forwardRef(CardDigest);
