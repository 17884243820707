import React, { forwardRef, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./CardCompareDetail.scss";
import { useCardStateImport } from "../../../hooks";
import BadgeDifference from "../../Badge/BadgeDifference";
import ButtonAligned from "../../Button/ButtonAligned";
import Button from "../../Button/ButtonRevamped/Button";
import { CDRT } from "../../Canvas/Canvas/Canvas.types";
import { useCanvasData, useCanvasDataDispatch, useOnCanvasDataChange, } from "../../Canvas/Canvas/CanvasDataProvider";
import { isCardAvailable } from "../../Canvas/Canvas/utils/Canvas.utils.basic";
import { getStructureIdOfEntity } from "../../Canvas/Canvas/utils/Canvas.utils.getter_and_setter";
import { findElementsWithCategory, findLinkFromElementWithCategory, scrollElementIntoView, } from "../../Canvas/Canvas/utils/Canvas.utils.misc";
import Spinner from "../../Loader/Spinner";
import CompareGuide from "../../Other/CompareGuide";
import Expander from "../../Other/Expander";
import HeaderIconAndTitle from "../../Other/HeaderIconAndTitle";
import Text from "../../Text/Text";
import Card from "../Card/Card";
const CardCompareDetail = ({ title, Icon, section, risk, coverage = null, getDescription, description, suggestion, presentAttributes, missingAttributes, commonAttributes, draftElement, documentReferenceText, missingDocumentReferenceText, riskDetailItems, loading, fileIds, isAllowEditDocument, showDescription, ...cardBaseProps }, ref) => {
    const [dataProps, setDataProps] = useState({
        title: title,
        Icon: Icon,
        risk: risk,
        section: section,
        coverage: coverage || null,
        getDescription: getDescription,
        description: description,
        suggestion: suggestion,
        presentAttributes: presentAttributes,
        missingAttributes: missingAttributes,
        commonAttributes: commonAttributes,
        documentReferenceText: documentReferenceText,
        missingDocumentReferenceText: missingDocumentReferenceText,
        riskDetailItems: riskDetailItems,
        loading: loading,
        fileIds: fileIds,
        isAllowEditDocument: isAllowEditDocument,
        showDescription: showDescription,
    });
    const { elementId } = cardBaseProps;
    const [descriptionStream, setDescriptionStream] = useState("");
    const [linkFromState, linkFromSetState] = useCardStateImport({
        elementId: elementId,
    }); // Should be card compare, can also be CardDocumentEditor
    const [launchedEditor, setLaunchedEditor] = useState(false);
    const canvasData = useCanvasData();
    const dispatch = useCanvasDataDispatch();
    const onCanvasDataChange = useOnCanvasDataChange();
    // Update props from linked card
    useEffect(() => {
        if (Object.keys(linkFromState).length > 0) {
            setDataProps({ ...dataProps, ...linkFromState });
            setDescriptionStream("");
            linkFromState.getDescription?.(linkFromState.section, setDescriptionStream, () => null);
        }
    }, [linkFromState]);
    useEffect(() => {
        if (elementId && launchedEditor && dataProps.fileIds) {
            // update the compare state on the editor
            linkFromSetState?.({ selectedFileIds: dataProps.fileIds }),
                // close the current card
                setTimeout(() => dispatch?.({
                    type: CDRT.REMOVE_ELEMENT,
                    data: {
                        onCanvasDataChange,
                        elementId: elementId,
                    },
                }), 200);
            if (canvasData) {
                // hide pdf
                const pdfElements = findElementsWithCategory(canvasData, "pdf", false);
                // hide main compare card
                console.log("closing pdf element", pdfElements);
                if (pdfElements) {
                    pdfElements.map((pe) => setTimeout(() => dispatch?.({
                        type: CDRT.SET_ELEMENT_HIDDEN,
                        data: {
                            onCanvasDataChange,
                            elementId: pe,
                            value: true,
                        },
                    }), 500));
                }
                const compareElements = findElementsWithCategory(canvasData, "compare", false);
                // hide main compare card
                if (compareElements) {
                    console.log("closing compare element", compareElements);
                    compareElements.map((ce) => {
                        setTimeout(() => dispatch?.({
                            type: CDRT.SET_ELEMENT_HIDDEN,
                            data: {
                                onCanvasDataChange,
                                elementId: ce,
                                value: true,
                            },
                        }), 500);
                    });
                }
            }
        }
    }, [linkFromState, launchedEditor]);
    // If getDescription is given, generate the stream
    useEffect(() => {
        if (dataProps.showDescription) {
            getDescription?.(section, setDescriptionStream, () => null);
        }
    }, []);
    // If draftElement is given, view suggestion button would appear. This handler
    // will be used onClick.
    const viewDraftHandler = () => {
        if (elementId && canvasData) {
            const targetElementId = findLinkFromElementWithCategory(canvasData, elementId, "draft");
            if (!targetElementId && draftElement) {
                const draftElementId = uuidv4();
                dispatch?.({
                    type: CDRT.ADD_ELEMENT,
                    data: {
                        idGenerator: () => draftElementId,
                        onCanvasDataChange: onCanvasDataChange,
                        structureId: getStructureIdOfEntity(canvasData, elementId),
                        element: draftElement,
                    },
                });
                setTimeout(() => {
                    scrollElementIntoView(draftElementId);
                }, 100);
            }
            else {
                scrollElementIntoView(targetElementId);
            }
        }
    };
    const handleEditDocument = () => {
        if (canvasData !== null) {
            const targetElementIds = findElementsWithCategory(canvasData, "editor", true);
            if (targetElementIds.length > 0) {
                targetElementIds.map((te) => {
                    if (isCardAvailable(canvasData, te)) {
                        dispatch?.({
                            type: CDRT.LAUNCH_AVAILABLE_ELEMENT,
                            data: {
                                availableElementId: te,
                                onCanvasDataChange: onCanvasDataChange,
                            },
                        });
                    }
                });
            }
            // triggers the useEffect
            setLaunchedEditor(true);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { headerChildren: React.createElement(HeaderIconAndTitle, { title: dataProps.title, iconProps: { IC: dataProps.Icon || Icon }, isLoading: dataProps.loading }), footerChildren: isAllowEditDocument && (React.createElement("div", { className: "card-risk-detail__footer" },
                React.createElement(ButtonAligned, { text: "Edit Document", onClick: handleEditDocument, isFullWidth: true }))), className: "card-risk-detail", bodyHeightFill: true, bodyNoPad: true, ...cardBaseProps, ref: ref },
            React.createElement("div", { className: "padding-1" },
                React.createElement(CompareGuide, { leftText: "Base Document", rightText: "Selected Document(s)" })),
            React.createElement("div", { className: "card-risk-detail__badges" },
                dataProps.presentAttributes &&
                    dataProps.presentAttributes.map((e, i) => (React.createElement(BadgeDifference, { key: i, variant: "new", text: e }))),
                dataProps.missingAttributes &&
                    dataProps.missingAttributes.map((e, i) => (React.createElement(BadgeDifference, { key: i, variant: "missing", text: e }))),
                dataProps.commonAttributes &&
                    dataProps.commonAttributes.map((e, i) => (React.createElement(BadgeDifference, { key: i, variant: "match", text: e })))),
            dataProps.showDescription && (React.createElement(Text, { className: "card-risk-detail__description", display: "block", color: "grey-dark", size: "0.875", lineHeight: "1.6", preserveNewlines: true },
                dataProps.description,
                descriptionStream)),
            loading && React.createElement(Spinner, { size: "small", position: "center" }),
            dataProps.suggestion && (React.createElement("div", { className: "card-risk-detail__suggestion" },
                React.createElement(Expander, { outline: true, headerChildren: React.createElement(React.Fragment, null,
                        React.createElement(Text, { display: "block", size: "0.875", weight: "600", color: "grey-29" },
                            React.createElement("span", null, "Suggestion"))), bodyChildren: React.createElement(Text, null, dataProps.suggestion) }))),
            draftElement && (React.createElement(Button, { className: "margin-left-1 margin-bottom-1", outline: true, onClick: viewDraftHandler, variant: "primary" }, "View Suggestion")),
            React.createElement("div", { className: "margin-1" }, dataProps.documentReferenceText && (React.createElement(Expander, { backgroundColor: "#F9F3F4", headerChildren: React.createElement(Text, { size: "0.875", color: "grey-29" }, "Reference From Base Document"), bodyChildren: React.createElement(Text, { color: "grey-53", size: "0.875" }, dataProps.documentReferenceText) }))),
            React.createElement("div", { className: "margin-1" }, dataProps.missingDocumentReferenceText && (React.createElement(Expander, { backgroundColor: "#F5F5F5", headerChildren: React.createElement(Text, { size: "0.875", color: "grey-29" }, "Reference From Selected Document"), bodyChildren: React.createElement(Text, { color: "grey-53", size: "0.875" }, dataProps.missingDocumentReferenceText) }))))));
};
export default forwardRef(CardCompareDetail);
