import clsx from "clsx";
import React from "react";
import colorClasses from "../../../styles/color.module.scss";
import classes from "./Text.module.scss";
import { TextVariant } from "./Text.types";
import { parseTextVariantStr } from "./Text.utils";
// Private text component
const _Text = ({ variant, size, weight, isUnderline, isStrikeThrough, children, color, isBlock, className, whiteSpace, isOverflowEllipsis, }) => {
    return (React.createElement("span", { className: clsx(classes.text, classes[variant], classes[size], classes[weight], colorClasses[color], className, {
            [classes["underline"]]: isUnderline,
            [classes["strikeThrough"]]: isStrikeThrough,
            [classes["block"]]: isBlock,
            [classes["overflowEllipsis"]]: isOverflowEllipsis,
        }), style: { whiteSpace: whiteSpace || "initial" } }, children));
};
// Main text component to be imported and used
const Text = ({ variant = "body-sm", children, color = "black", isBlock = false, className, whiteSpace, isOverflowEllipsis, // use whiteSpace: nowrap for this to work correctly
isStrikeThrough = false, }) => {
    if (!Object.keys(TextVariant).includes(variant)) {
        throw Error(`Got invalid text variant ${variant}`);
    }
    const data = parseTextVariantStr(variant);
    return (React.createElement(_Text, { variant: data.variant, size: data.size, weight: data.weight ?? "normal", color: color, isUnderline: data.isUnderline, isStrikeThrough: isStrikeThrough, isBlock: isBlock, className: className, whiteSpace: whiteSpace, isOverflowEllipsis: isOverflowEllipsis }, children));
};
export default Text;
