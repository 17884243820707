import React, { forwardRef, useState } from "react";
import "./CardRiskBarSections.scss";
import { useCardStateExport } from "../../../hooks";
import HeaderIconAndTitle from "../../Other/HeaderIconAndTitle";
import Text from "../../Text/Text";
import VizBarRiskRES from "../../Viz/VizBarRiskRES";
import Card from "../Card/Card";
const CardRiskBarSections = ({ title, Icon, riskSectionAndDetails, ...cardBaseProps }, ref) => {
    const [exportState, setExportState] = useState();
    const { elementId } = cardBaseProps;
    useCardStateExport({
        elementId: elementId,
        state: exportState,
        setState: setExportState,
    });
    const clickHandler = (details) => {
        setExportState(details);
    };
    return (React.createElement(Card, { headerChildren: React.createElement(HeaderIconAndTitle, { title: title, iconProps: { IC: Icon } }), bodyHeightFill: true, bodyNoPad: true, ref: ref, ...cardBaseProps }, riskSectionAndDetails.map((e, i) => {
        return (React.createElement("div", { className: "card-risk-bar-sections__section", key: i, onClick: () => clickHandler(e) },
            React.createElement(Text, { color: "black", size: "0.9" }, e.title),
            React.createElement("div", { className: "card-risk-bar-sections__section__bar" },
                React.createElement(VizBarRiskRES, { percent: e.risk, thickness: 8, cornerRadius: 4 }))));
    })));
};
export default forwardRef(CardRiskBarSections);
