import clsx from "clsx";
import React from "react";
import CheckBoxRoundedIcon from "../../../static/icons/check-box-rounded.svg";
import UnCheckBoxRoundedIcon from "../../../static/icons/uncheck-box-rounded.svg";
import classes from "./TagList.module.scss";
import Button from "../../Button/ButtonAligned/Button";
import Text from "../../Text/TextAligned/Text";
const TagList = ({ tags = [], onTagChange, onDeselectAll }) => {
    return (React.createElement("div", { className: clsx(classes.tagList) },
        React.createElement("div", { className: clsx(classes.header) },
            React.createElement(Text, { variant: "body-sm/semibold" }, "Select Tags"),
            React.createElement(Button, { textColor: "black", variant: "ghost", size: "sm", onClick: onDeselectAll, text: "Deselect All" })),
        React.createElement("hr", { className: classes.divider }),
        React.createElement("div", { className: classes.box }, tags.map((tag) => (React.createElement("span", { key: tag.id, className: classes.tag, onClick: () => onTagChange(tag) },
            tag.checked ? React.createElement(CheckBoxRoundedIcon, null) : React.createElement(UnCheckBoxRoundedIcon, null),
            React.createElement(Text, { color: "grey-dark-60", variant: "body-sm/semibold" }, tag.text)))))));
};
export default TagList;
