import clsx from "clsx";
import React from "react";
import CardHoverMovementRightIcon from "../../../static/icons/card-hover-movement-right.svg";
import classes from "./CardMovementButton.module.scss";
import { CDRT } from "../../Canvas/Canvas/Canvas.types";
import { useCanvasData, useCanvasDataDispatch, useOnCanvasDataChange, } from "../../Canvas/Canvas/CanvasDataProvider";
import { isCard } from "../../Canvas/Canvas/utils/Canvas.utils.basic";
import Icon from "../../Other/Icon";
const CardMovementButton = ({ id, direction, selected = false, }) => {
    const dispatch = useCanvasDataDispatch();
    const canvasData = useCanvasData();
    const onCanvasDataChange = useOnCanvasDataChange();
    const handleOnDirectionClick = () => {
        if (canvasData) {
            if (isCard(canvasData, id)) {
                dispatch?.({
                    type: CDRT.MOVE_ELEMENT,
                    data: {
                        onCanvasDataChange: onCanvasDataChange,
                        elementId: id,
                        direction,
                    },
                });
            }
            else {
                dispatch?.({
                    type: CDRT.MOVE_STRUCTURE,
                    data: {
                        onCanvasDataChange: onCanvasDataChange,
                        structureId: id,
                        direction,
                    },
                });
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: clsx(classes.movementButton, { [classes.selected]: selected }, classes[direction.toLowerCase()]), onClick: handleOnDirectionClick },
            React.createElement(Icon, { IC: CardHoverMovementRightIcon, className: clsx(classes.movementIcon), height: 8, width: 8 }))));
};
export default CardMovementButton;
