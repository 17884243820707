import clsx from "clsx";
import { $getRoot } from "lexical";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import CompareEditorIcon from "../../../static/icons/compare-editor.svg";
import DocumentIcon from "../../../static/icons/document.svg";
import classes from "./EditorPluginFooter.module.scss";
import Badge from "../../Badge/BadgeAligned/Badge";
import Button from "../../Button/ButtonAligned/Button";
import Icon from "../../Other/Icon";
import Text from "../../Text/TextAligned/Text";
import { getWordCount } from "./EditorPluginFooter.utils";
export const defaultEditorPluginFooterHandleRef = {
    textContentListener: () => null,
    onLoadCallback: () => null,
};
const EditorPluginFooter = ({ showWordCount, editorPluginHighlightHandleRef, isCompare, fileItems, editorPluginHighlightHandleState, }, ref) => {
    console.log("isCompare on EditorPluginFooter", isCompare);
    const [wordCount, setWordCount] = useState(0);
    useImperativeHandle(ref, () => ({
        textContentListener: textContentListener,
        onLoadCallback: onLoadCallback,
    }), []);
    const textContentListener = (text) => {
        setWordCount(getWordCount(text));
    };
    const onLoadCallback = (editorState) => {
        editorState.read(() => {
            const text = $getRoot().getTextContent();
            textContentListener(text);
        });
    };
    return (React.createElement("div", { className: clsx(classes.container, {
            [classes.compareMode]: isCompare,
        }) },
        React.createElement(Button, { size: "sm", variant: "alternative-2", selected: editorPluginHighlightHandleState?.unfocus, onClick: editorPluginHighlightHandleRef?.current?.toggleUnfocus, text: "Hide Analyzed Text" }),
        React.createElement("span", { className: !isCompare ? classes.right : undefined }, !isCompare && showWordCount && (React.createElement(Text, { variant: "heading-xs" },
            wordCount,
            " ",
            wordCount <= 1 ? "Word" : "Words"))),
        isCompare && (React.createElement("span", { className: classes.compareSection },
            isCompare && fileItems && fileItems.length > 0 && (React.createElement("span", { className: classes.compareSection },
                React.createElement(Badge, { text: fileItems[0].text, textColor: "red-primary", size: "xs", outline: "red-primary", isTextSemiBold: true, leftIconProps: {
                        IC: DocumentIcon,
                    } }),
                fileItems.length > 1 && (React.createElement(Badge, { text: `+${fileItems.length - 1}`, textColor: "red-primary", size: "xs", outline: "red-primary", isTextSemiBold: true })))),
            React.createElement(Icon, { IC: CompareEditorIcon, size: "xs" }),
            React.createElement(Text, { variant: "body-xs/bold", color: "red-primary" }, "Document Compare Mode")))));
};
export default forwardRef(EditorPluginFooter);
