export default {
    "main-color": "text-color-main",
    green: "text-color-green",
    "link-grey-72": "text-link-grey-72",
    "link-main-color": "text-link-main-color",
    grey: "text-color-grey",
    "grey-72": "text-color-grey-72",
    "grey-71": "text-color-grey-71",
    black: "text-color-black",
    "grey-9d": "font-grey-9d",
    white: "text-color-white ",
    primary: "text-color-primary",
    secondary: "text-color-secondary",
    success: "text-color-success",
    danger: "text-color-danger",
    "black-12": "text-color-black-12",
    "grey-dark": "text-grey-dark",
    "grey-9c": "text-color-grey-9c",
    "grey-9a": "text-color-grey-9a",
    "grey-888": "text-color-grey-888",
    "grey-88": "text-color-grey-88",
    "grey-f3": "text-color-grey-f3",
    "grey-29": "text-color-grey-29",
    "grey-53": "text-color-grey-53",
    "prussian-blue-secondary": "text-color-prussian-blue-secondary",
    "black-181": "text-color-black-181",
    "blue-78": "text-color-blue-78",
    "grey-9e": "text-color-grey-9e",
    "": "",
};
