import React from "react";
import InfoIcon from "../../../static/icons/info.svg";
import "../../../styles/base.scss";
import { getTooltipClassNames } from "../Tooltip/Tooltip.utils";
export default function Helptip({ message, messagePosition = "top", className = "", backgroundWhite = false, }) {
    return (React.createElement("span", { className: getTooltipClassNames({
            position: messagePosition,
            className,
            backgroundWhite,
        }), "data-tooltip": message },
        React.createElement(InfoIcon, null)));
}
