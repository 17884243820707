import clsx from "clsx";
import React from "react";
import classes from "./Badge.module.scss";
import { Color } from "../../../types/color";
import Icon from "../../Other/Icon";
import { getTooltipClassNames, getTooltipStyles, } from "../../Other/Tooltip/Tooltip.utils";
import Text from "../../Text/TextAligned/Text";
import { getTextPropsFromBadgeProps } from "./Badge.utils";
const BadgeSizeToTooltipGapMap = {
    xxs: undefined,
    xs: "2.2em",
    sm: undefined,
    md: "3em",
    lg: "3.2em",
    xl: undefined,
};
// Sets the core look and style of the Badge. Has no defaults.
export const _Badge = ({ size, children, className, onClick, isBlock, backgroundColor, outline = "transparent", tooltipProps, title, }) => {
    return (React.createElement("div", { className: clsx(classes["badge"], classes[size], className, {
            [classes["block"]]: isBlock,
            [getTooltipClassNames({
                ...(tooltipProps || {}),
            })]: tooltipProps,
        }), style: {
            "--background-color": backgroundColor === "transparent"
                ? "transparent"
                : Color[backgroundColor],
            "--outline-color": outline === "transparent" ? "transparent" : Color[outline],
            ...getTooltipStyles({
                gap: tooltipProps?.gap ?? BadgeSizeToTooltipGapMap[size],
            }),
        }, onClick: onClick, "data-tooltip": tooltipProps?.message, title: title }, children));
};
// Sets the content of the badge and enforces the styleguide, has defaults.
// This Badge component is to be imported and used everywhere.
const Badge = ({ size = "xs", leftIconProps, rightIconProps, text = "", textColor = "black", className = "", isTextSemiBold = false, onClick, isBlock = false, backgroundColor = "white", outline, tooltipProps, title, }) => {
    if (!["xs", "md", "lg"].includes(size)) {
        throw Error(`Invalid size ${size}, only "xs", "md" and "lg" supported`);
    }
    const textProps = getTextPropsFromBadgeProps({
        size,
        isTextSemiBold,
    });
    return (React.createElement(_Badge, { size: size, className: className, onClick: onClick, isBlock: isBlock, backgroundColor: backgroundColor, outline: outline, tooltipProps: tooltipProps, title: title },
        leftIconProps && React.createElement(Icon, { ...leftIconProps, size: "xs" }),
        React.createElement(Text, { ...textProps, color: textColor ? textColor : textProps.color, whiteSpace: "nowrap" }, text),
        rightIconProps && React.createElement(Icon, { ...rightIconProps, size: "xs" })));
};
export default Badge;
