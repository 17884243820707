import clsx from "clsx";
import React from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./Modal.module.scss";
const Modal = ({ children, position, show, close, headerChildren, footerChildren, size, }) => {
    return (React.createElement(CSSTransition, { classNames: "modal", in: show, mountOnEnter: true, unmountOnExit: true, timeout: 150 },
        React.createElement("div", { className: classes.modal, onClick: close },
            React.createElement("div", { className: clsx(classes.content, classes[position], classes[size || ""]), onClick: (e) => e.stopPropagation() },
                React.createElement("div", { className: classes.header }, headerChildren),
                React.createElement("div", { className: classes.body }, children),
                React.createElement("footer", { className: classes.footer }, footerChildren)))));
};
export default Modal;
