import React from "react";
import classes from "./MyFilesCardFileSelect.module.scss";
import ButtonAligned from "../../Button/ButtonAligned";
import Text from "../../Text/TextAligned/Text";
import DocumentIcon from "../DocumentIcon";
import FileSelect from "../FileSelect";
const MyFilesCardFileSelect = ({ title, files, isSelectMultiple, selectedFileIds, setSelectedFileIds, }) => {
    const selectedFile = selectedFileIds
        ? files.find(({ id }) => id === selectedFileIds[0])
        : undefined;
    return (React.createElement("div", { className: classes.myFilesCardFileSelect },
        React.createElement("div", { className: classes.header },
            React.createElement(Text, { variant: "heading-sm", color: "grey-dark-80" }, title)),
        (isSelectMultiple || selectedFileIds?.length === 0) && (React.createElement("div", { className: classes.fileSelect },
            React.createElement(FileSelect, { files: files, selectedState: selectedFileIds, setSelectedState: setSelectedFileIds, isSelectMultiple: isSelectMultiple, size: "lg", defaultIsFileSelectionVisible: true }))),
        !isSelectMultiple &&
            selectedFileIds &&
            selectedFileIds.length > 0 &&
            selectedFile && (React.createElement("div", { className: classes.singleSelectedFile },
            React.createElement("span", { className: classes.singleSelectedFileDetails },
                selectedFile?.documentFormat && (React.createElement(DocumentIcon, { format: selectedFile?.documentFormat })),
                React.createElement(Text, { variant: "heading-md" }, selectedFile?.name)),
            React.createElement(ButtonAligned, { text: "Change Document", variant: "ghost", size: "sm", textColor: "black", onClick: () => setSelectedFileIds([]) })))));
};
export default MyFilesCardFileSelect;
