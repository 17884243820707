export var EDITOR_PLUGIN;
(function (EDITOR_PLUGIN) {
    EDITOR_PLUGIN["TOOLBAR"] = "TOOLBAR";
    EDITOR_PLUGIN["RICH_TEXT"] = "RICH_TEXT";
    EDITOR_PLUGIN["HOVER_TOOLBAR"] = "HOVER_TOOLBAR";
    EDITOR_PLUGIN["LIST"] = "LIST";
    EDITOR_PLUGIN["ON_CHANGE"] = "ON_CHANGE";
    EDITOR_PLUGIN["HISTORY"] = "HISTORY";
    EDITOR_PLUGIN["SET_EDITABLE"] = "SET_EDITABLE";
    EDITOR_PLUGIN["OFFSET"] = "OFFSET";
    EDITOR_PLUGIN["SET_EDIT_CALLBACK"] = "SET_EDIT_CALLBACK";
    EDITOR_PLUGIN["HIGHLIGHT"] = "HIGHLIGHT";
    EDITOR_PLUGIN["SET_LISTENER"] = "SET_LISTENER";
    EDITOR_PLUGIN["ON_LOAD"] = "ON_LOAD";
    EDITOR_PLUGIN["FLOAT"] = "FLOAT";
    EDITOR_PLUGIN["FOOTER"] = "FOOTER";
    EDITOR_PLUGIN["SET_HANDLE_REF"] = "SET_HANDLE_REF";
    EDITOR_PLUGIN["CALLBACK"] = "CALLBACK";
})(EDITOR_PLUGIN || (EDITOR_PLUGIN = {}));
