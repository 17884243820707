/* eslint-disable prefer-const */
import React, { useState } from "react";
import "../../../styles/base.scss";
import "./Button.scss";
import ModalConfirmation from "../../Modal/ModalConfirmation/ModalConfirmation";
import Tooltip from "../../Other/Tooltip/Tooltip";
// import useShow from "../hooks/useShow";
// Need to add active status
export default function Button({ text, iconSpanClass, iconFilePath, iconComponent, clickHandler, active, disabled = false, className, tooltip = "", tooltipPosition = "bottom", style, confirmation = false, confirmationMessage = "", confirmationSubMessage = "", }) {
    const message = tooltip ? tooltip : null;
    className = `${className} ${active ? "button--active" : ""}`;
    let successClickHandler, successHandler;
    const [show, setShow] = useState(false);
    const close = () => setShow(false);
    const open = () => setShow(true);
    successClickHandler = clickHandler;
    successHandler = () => {
        successClickHandler();
        close();
    };
    if (confirmation) {
        clickHandler = open;
    }
    const buttonChildren = (React.createElement(React.Fragment, null,
        iconFilePath ? (React.createElement("img", { src: iconFilePath, className: "icon--fi" })) : (iconSpanClass && React.createElement("span", { className: iconSpanClass })),
        iconComponent,
        React.createElement("span", { className: `${iconComponent ? "text-vertical-align" : ""}` }, text)));
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: className, onClick: clickHandler, style: style, disabled: disabled },
            React.createElement(React.Fragment, null, message ? (React.createElement(Tooltip, { message: message, position: tooltipPosition }, buttonChildren)) : (buttonChildren))),
        confirmation && (React.createElement(ModalConfirmation, { message: confirmationMessage, subMessage: confirmationSubMessage, show: show, close: close, mode: "confirmation", successHandler: successHandler }))));
    // return (<button>Hello Hi</button>)
}
