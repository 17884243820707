import React, { useCallback, useEffect, useMemo, useState } from "react";
import ChevronLeftIcon from "../../../static/icons/chevron-left.svg";
import ChevronRightIcon from "../../../static/icons/chevron-right.svg";
import "./MultipleTextBox.scss";
import Text from "../../Text/Text";
import { getDefaultText, getForwardBackwardTextBoxItem, isDisabledDerived, } from "./MultipleTextBox.utils";
function MultipleTextBox({ items, mode = "non-cycle", itemId = "", toggle, className = "", isForwardDisabled, isBackwardDisabled, }) {
    const [currentTextBox, setCurrentTextBox] = useState(getDefaultText({ itemId, items }, (currentIndex, items) => {
        if (currentIndex > -1) {
            return items[currentIndex];
        }
        return items[0];
    }));
    useEffect(() => {
        setCurrentTextBox(getDefaultText({ itemId, items }, (currentIndex, items) => {
            if (currentIndex > -1) {
                return items[currentIndex];
            }
            return items[0];
        }));
    }, [itemId, items]);
    const handleOnClick = useCallback((direction) => {
        if (currentTextBox) {
            if (typeof toggle === "function") {
                toggle({ type: direction, currentItem: currentTextBox });
            }
            else {
                const newTextBox = getForwardBackwardTextBoxItem({
                    items,
                    currentTextBox,
                    direction,
                });
                setCurrentTextBox(newTextBox);
            }
        }
    }, [currentTextBox, items, setCurrentTextBox, toggle]);
    const isBackwardDisabledDerived = useMemo(() => isDisabledDerived("backward", currentTextBox, items, isBackwardDisabled), [currentTextBox, isBackwardDisabled]);
    const isForwardDisabledDerived = useMemo(() => isDisabledDerived("forward", currentTextBox, items, isForwardDisabled), [currentTextBox, isForwardDisabled]);
    return (React.createElement("div", { className: `multiple-text-box ${className}` },
        React.createElement("div", { className: "multiple-text-box__header" },
            React.createElement(Text, { color: "black", size: "1", weight: "semibold" }, currentTextBox?.title || ""),
            React.createElement("span", { className: "multiple-text-box__header--icon-group" },
                React.createElement(ChevronLeftIcon, { onClick: () => handleOnClick("backward"), className: `multiple-text-box__header--icon-group--icon${mode !== "cycle" && isBackwardDisabledDerived ? "--disabled" : ""}` }),
                React.createElement(ChevronRightIcon, { onClick: () => handleOnClick("forward"), className: `multiple-text-box__header--icon-group--icon${mode !== "cycle" && isForwardDisabledDerived ? "--disabled" : ""}` }))),
        React.createElement(Text, { color: "grey-71", size: "0.875", weight: "400" }, currentTextBox?.text || "")));
}
export default MultipleTextBox;
