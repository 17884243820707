export var BlockType;
(function (BlockType) {
    BlockType["bullet"] = "Bulleted List";
    BlockType["h1"] = "Heading 1";
    BlockType["h2"] = "Heading 2";
    BlockType["h3"] = "Heading 3";
    BlockType["number"] = "Numbered List";
    BlockType["paragraph"] = "Normal";
    BlockType["quote"] = "Quote";
    BlockType["check"] = "Check List";
})(BlockType || (BlockType = {}));
export var FontFamilyType;
(function (FontFamilyType) {
    FontFamilyType["ARIAL"] = "Arial";
    FontFamilyType["COURIER"] = "Courier New";
    FontFamilyType["GEORGIA"] = "Georgia";
    FontFamilyType["TIMES_NEW_ROMAN"] = "Times New Roman";
    FontFamilyType["TREBUCHET"] = "Trebuchet MS";
    FontFamilyType["VERDANA"] = "Verdana";
})(FontFamilyType || (FontFamilyType = {}));
