import clsx from "clsx";
import React, { useState } from "react";
import CopyRoundedIcon from "../../../static/icons/copy-rounded.svg";
import DislikeRoundedIcon from "../../../static/icons/dislike-rounded.svg";
import EditRoundedIcon from "../../../static/icons/edit-rounded.svg";
import RetryRoundedIcon from "../../../static/icons/retry-rounded.svg";
import ShareRoundedIcon from "../../../static/icons/share-rounded.svg";
import classes from "./QuestionAnswerMessage.module.scss";
import Button from "../../Button/ButtonAligned/Button";
import Text from "../../Text/TextAligned";
function QuestionAnswerMessage({ variant, text, className, isLoading, isError, ...rest }) {
    const [isCopied, setIsCopied] = useState(false);
    const handleCopyClick = () => {
        setIsCopied(true);
        navigator.clipboard.writeText(text);
        setTimeout(() => setIsCopied(false), 1000);
    };
    if (variant === "user") {
        return (React.createElement("div", { className: clsx(classes.userMessage, {
                [classes.error]: isError,
            }, className) },
            React.createElement(Text, { variant: "body-sm", color: isError ? "red-primary" : "black" }, text)));
    }
    return (React.createElement("div", { className: clsx(classes.systemMessage, {
            [classes.error]: isError,
        }, className) },
        React.createElement("div", { className: classes.systemMessageText },
            React.createElement(Text, { variant: "body-sm", whiteSpace: "pre-wrap", color: isError ? "red-primary" : "black" }, text)),
        !isLoading && (React.createElement("div", { className: classes.footer },
            React.createElement("span", { className: classes.footerGroup },
                !isError && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { variant: "tertiary", text: isCopied ? "copied!" : undefined, textColor: "grey-dark-60", size: "xs", leftIconProps: {
                            IC: CopyRoundedIcon,
                            onClick: handleCopyClick,
                        } }),
                    rest.onClickEdit && (React.createElement(Button, { variant: "tertiary", size: "xs", leftIconProps: {
                            IC: EditRoundedIcon,
                            onClick: rest
                                .onClickEdit,
                        } })))),
                rest.onClickRetry && (React.createElement(Button, { variant: "tertiary", size: "xs", leftIconProps: {
                        IC: RetryRoundedIcon,
                        onClick: rest
                            .onClickRetry,
                    } }))),
            React.createElement("span", { className: classes.footerGroup }, !isError && (React.createElement(React.Fragment, null,
                rest.onClickDislike && (React.createElement(Button, { variant: "tertiary", size: "xs", leftIconProps: {
                        IC: DislikeRoundedIcon,
                        onClick: rest
                            .onClickDislike,
                    } })),
                rest.onClickShare && (React.createElement(Button, { variant: "tertiary", size: "xs", leftIconProps: {
                        IC: ShareRoundedIcon,
                        onClick: rest
                            .onClickShare,
                    } })))))))));
}
export default QuestionAnswerMessage;
