import React, { useState } from "react";
import CopyAllIcon from "../../../static/icons/copy-all.svg";
import CopyIcon from "../../../static/icons/copy.svg";
import Button from "../../Button/ButtonRevamped";
const CopyToClipboard = ({ text, height, width, isCopyAll = false, tooltipProps = {}, }) => {
    const [copied, setCopied] = useState(false);
    const handleCopyClick = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Reset copied state after 1.5 seconds
    };
    return (React.createElement("span", null,
        React.createElement(Button, { size: "pad-0p5", variant: "plain", noBorder: true, tooltipProps: {
                ...tooltipProps,
                message: copied
                    ? "Copied!"
                    : tooltipProps.message
                        ? tooltipProps.message
                        : "Copy Text",
            }, onClick: handleCopyClick, translucentActive: true }, isCopyAll ? (React.createElement(CopyAllIcon, { height: height, width: width })) : (React.createElement(CopyIcon, { height: height, width: width })))));
};
export default CopyToClipboard;
