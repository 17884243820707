import React from "react";
import CheckIcon from "../../../icons/check.svg";
import Button from "../Button";

function PricingFeature({ text }) {
  return (
    <div className="pricing-card__features">
      <CheckIcon className="icon--svg" fill="#25A757" viewBox="0 0 24 24" />
      <span className="pricing-card__features__text">{text}</span>
    </div>
  );
}

export default function PricingCard({
  badgeComponent,
  description,
  amountPerMonth,
  points,
  clickHandler,
}) {
  return (
    <>
      <div className="pricing-card flex flex--direction-column flex--justify-content-space-between">
        <div>
          {badgeComponent}
          <p>{description}</p>
          <p>
            <span className="font-black-600 font-size-2">
              ₹{amountPerMonth}
            </span>
            /mo
          </p>
          {points.map((e, i) => (
            <PricingFeature key={i} text={e} />
          ))}
        </div>
        <Button
          text="Buy Now"
          className="pricing-card__buy-now button--primary"
          clickHandler={clickHandler}
        />
      </div>
    </>
  );
}
