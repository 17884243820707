export var TextVariant;
(function (TextVariant) {
    TextVariant[TextVariant["heading-xl"] = 0] = "heading-xl";
    TextVariant[TextVariant["heading-lg"] = 1] = "heading-lg";
    TextVariant[TextVariant["heading-md"] = 2] = "heading-md";
    TextVariant[TextVariant["heading-sm"] = 3] = "heading-sm";
    TextVariant[TextVariant["heading-xs"] = 4] = "heading-xs";
    TextVariant[TextVariant["body-xs"] = 5] = "body-xs";
    TextVariant[TextVariant["body-xs/semibold"] = 6] = "body-xs/semibold";
    TextVariant[TextVariant["body-xs/underline"] = 7] = "body-xs/underline";
    TextVariant[TextVariant["body-xs/bold"] = 8] = "body-xs/bold";
    TextVariant[TextVariant["body-sm"] = 9] = "body-sm";
    TextVariant[TextVariant["body-sm/semibold"] = 10] = "body-sm/semibold";
    TextVariant[TextVariant["body-sm/underline"] = 11] = "body-sm/underline";
    TextVariant[TextVariant["body-sm/bold"] = 12] = "body-sm/bold";
    TextVariant[TextVariant["body-md"] = 13] = "body-md";
    TextVariant[TextVariant["body-md/semibold"] = 14] = "body-md/semibold";
    TextVariant[TextVariant["body-md/underline"] = 15] = "body-md/underline";
    TextVariant[TextVariant["body-md/bold"] = 16] = "body-md/bold";
})(TextVariant || (TextVariant = {}));
