/** @format */
import React, { useCallback, useState } from "react";
import PremiumIcon from "../../../static/icons/premium.svg";
import "./ModalExportRedact.scss";
import { useSelect } from "../../../hooks";
import Button from "../../Button/ButtonRevamped";
import CheckboxSwitch from "../../Checkbox/CheckboxSwitch";
import InfoBox from "../../Other/InfoBox";
import LexaparLogo from "../../Other/LexaparLogo";
import withPremium from "../../Other/withPremium/withPremium";
import Text from "../../Text/Text";
import Modal from "../Modal";
const ModalExportRedact = ({ show, close, defaultFormatId = "", defaultColorId = "", defaultReplaceId = "", documentFormatOptions = [], colorOptions = [], replaceOptions = [], isPremium = true, onClickRemoveWatermark, onExport = () => null, }) => {
    const ButtonWithPremium = withPremium(Button);
    const [ignorePartialChecked, setIgnorePartialChecked] = useState(true);
    const toggleIgnorePartialChecked = () => setIgnorePartialChecked(!ignorePartialChecked);
    const { value: selectedDocumentFormatId, handleOnValueChange: onDocumentFormatClick, } = useSelect(defaultFormatId);
    const { value: selectedColorId, handleOnValueChange: onColorClick } = useSelect(defaultColorId);
    const { value: selectedReplaceId, handleOnValueChange: onReplaceClick } = useSelect(defaultReplaceId);
    const onClickExport = useCallback(() => onExport({
        documentFormat: selectedDocumentFormatId,
        color: selectedColorId,
        replace: selectedReplaceId,
        ignorePartialChecked: ignorePartialChecked,
    }), [
        selectedDocumentFormatId,
        selectedColorId,
        selectedReplaceId,
        ignorePartialChecked,
        show,
    ]);
    return (React.createElement(Modal, { show: show, close: close, title: "EXPORT DOCUMENT", size: "semimedium-fixed", footer: React.createElement("div", { className: "modal-export-redact__footer" },
            isPremium && (React.createElement("div", { className: "modal-export-redact__footer--premium" },
                React.createElement("div", { className: "modal-export-redact__footer--premium--top" },
                    React.createElement(Text, { color: "grey-dark", weight: "500" }, "Redacted by"),
                    React.createElement(LexaparLogo, null)),
                React.createElement("div", { className: "modal-export-redact__footer--premium--bottom" },
                    React.createElement(PremiumIcon, null),
                    React.createElement(Button, { variant: "link", size: "no-pad", onClick: onClickRemoveWatermark },
                        React.createElement(Text, { color: "main-color", weight: "semibold" }, "Remove Watermark"))))),
            React.createElement(Button, { className: "modal-export-redact__footer--button", variant: "primary", size: "wide-l-height-m", display: "block", onClick: onClickExport, tooltipProps: {
                    message: "Counted for subscription",
                } },
                React.createElement(Text, { weight: "bold", size: "small" }, "Export"))) },
        React.createElement(InfoBox, { divider: true, text: "Redacted information will be permanently removed in the exported document" }),
        React.createElement(Text, { className: "modal-export-redact--first-label", color: "grey-888", size: "", weight: "semibold", display: "inline-block" }, "Document Format"),
        React.createElement("div", { className: "modal-export-redact__options" }, documentFormatOptions.map(({ text, id, Icon, isPremium, disabled }) => (React.createElement(React.Fragment, null,
            React.createElement("span", { key: id, className: "modal-export-redact__option" },
                React.createElement(ButtonWithPremium, { variant: "secondary", size: "large", borderRadius: "1.5em", isSelected: selectedDocumentFormatId == id, onClick: () => onDocumentFormatClick(id), isPremium: isPremium, disabled: disabled },
                    Icon && Icon,
                    React.createElement(Text, { className: "padding-left-0p5" }, text))))))),
        React.createElement(Text, { color: "grey-888", size: "", weight: "semibold" }, "Highlight Colour"),
        React.createElement("div", { className: "modal-export-redact__options" }, colorOptions.map(({ id, color, isPremium, disabled }) => (React.createElement(React.Fragment, null,
            React.createElement("span", { key: id, className: "modal-export-redact__option" },
                React.createElement(ButtonWithPremium, { variant: "secondary", size: "no-pad", borderRadius: "1.5em", isSelected: selectedColorId === id, isPremium: isPremium, disabled: disabled, onClick: () => onColorClick(id) },
                    React.createElement("span", { className: "modal-export-redact__color", style: { background: color } }))))))),
        React.createElement(Text, { color: "grey-888", size: "", weight: "semibold" }, "Replace With"),
        React.createElement("div", { className: "modal-export-redact__options" }, replaceOptions.map(({ id, text, isPremium, disabled }) => (React.createElement(React.Fragment, null,
            React.createElement("span", { key: id, className: "modal-export-redact__option" },
                React.createElement(ButtonWithPremium, { variant: "secondary", size: "large", borderRadius: "1.5em", isPremium: isPremium, disabled: disabled, isSelected: selectedReplaceId === id, onClick: () => onReplaceClick(id) },
                    React.createElement(Text, null, text))))))),
        React.createElement("div", { className: "modal-export-redact__checkbox" },
            React.createElement("div", null,
                React.createElement(Text, { color: "grey-888", weight: "semibold" }, "Retain partially overlapping redaction (Recommended)")),
            React.createElement(CheckboxSwitch, { checked: ignorePartialChecked, onChange: toggleIgnorePartialChecked })),
        !ignorePartialChecked && (React.createElement(Text, { display: "block", className: "padding-top-1", color: "main-color" }, "Warning: If not retained, the smaller redaction boxes which partially overlap with other larger boxes will be deleted on export with replacement"))));
};
export default ModalExportRedact;
