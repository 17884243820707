export var CDRT;
(function (CDRT) {
    CDRT[CDRT["UPDATE_LAYOUT"] = 0] = "UPDATE_LAYOUT";
    CDRT[CDRT["UPDATE_ELEMENT_STATE"] = 1] = "UPDATE_ELEMENT_STATE";
    CDRT[CDRT["ADD_ELEMENT"] = 2] = "ADD_ELEMENT";
    CDRT[CDRT["SET_LOADED"] = 3] = "SET_LOADED";
    CDRT[CDRT["SET_CANVAS_DATA"] = 4] = "SET_CANVAS_DATA";
    CDRT[CDRT["SET_ELEMENT_HIDDEN"] = 5] = "SET_ELEMENT_HIDDEN";
    CDRT[CDRT["SET_STRUCTURE_HIDDEN"] = 6] = "SET_STRUCTURE_HIDDEN";
    CDRT[CDRT["LAUNCH_AVAILABLE_ELEMENT"] = 7] = "LAUNCH_AVAILABLE_ELEMENT";
    CDRT[CDRT["LAUNCH_AVAILABLE_STRUCTURE"] = 8] = "LAUNCH_AVAILABLE_STRUCTURE";
    CDRT[CDRT["SET_ELEMENT_SIZE"] = 9] = "SET_ELEMENT_SIZE";
    CDRT[CDRT["SET_STRUCTURE_SIZE"] = 10] = "SET_STRUCTURE_SIZE";
    CDRT[CDRT["SET_ELEMENT_HOVER"] = 11] = "SET_ELEMENT_HOVER";
    CDRT[CDRT["SET_ELEMENT_SELECTED"] = 12] = "SET_ELEMENT_SELECTED";
    CDRT[CDRT["SET_STRUCTURE_SELECTED"] = 13] = "SET_STRUCTURE_SELECTED";
    CDRT[CDRT["SET_ELEMENT_STATIC"] = 14] = "SET_ELEMENT_STATIC";
    CDRT[CDRT["SET_STRUCTURE_STATIC"] = 15] = "SET_STRUCTURE_STATIC";
    CDRT[CDRT["REMOVE_ELEMENT"] = 16] = "REMOVE_ELEMENT";
    CDRT[CDRT["MOVE_ELEMENT"] = 17] = "MOVE_ELEMENT";
    CDRT[CDRT["MOVE_STRUCTURE"] = 18] = "MOVE_STRUCTURE";
    CDRT[CDRT["UPDATE_ROOT_COLS"] = 19] = "UPDATE_ROOT_COLS";
    CDRT[CDRT["SET_ELEMENT_PINNED"] = 20] = "SET_ELEMENT_PINNED";
    CDRT[CDRT["SET_STRUCTURE_PINNED"] = 21] = "SET_STRUCTURE_PINNED";
})(CDRT || (CDRT = {}));
