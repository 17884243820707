import React from "react";
import CloudErrorIcon from "../../../static/icons/cloud-error.svg";
import CloudSavedIcon from "../../../static/icons/cloud-saved.svg";
import CloudSavingIcon from "../../../static/icons/cloud-saving.svg";
import DoubleTickIcon from "../../../static/icons/double-tick.svg";
import RedoSuccessIcon from "../../../static/icons/redo-success.svg";
import classes from "./DocumentSaveStatus.module.scss";
import Spinner from "../../Loader/SpinnerAligned/SpinnerAligned";
import Text from "../../Text/TextAligned/Text";
import Icon from "../Icon";
import { DOCUMENT_SAVE_STATUS_MODE, } from "./DocumentSaveStatus.types";
const DocumentSaveModeMapping = {
    [DOCUMENT_SAVE_STATUS_MODE.SAVING]: {
        iconProps: {
            IC: CloudSavingIcon,
        },
        text: "Saving Document",
    },
    [DOCUMENT_SAVE_STATUS_MODE.SAVED]: {
        iconProps: {
            IC: CloudSavedIcon,
        },
        text: "Saved Document",
    },
    [DOCUMENT_SAVE_STATUS_MODE.ERROR]: {
        iconProps: {
            IC: CloudErrorIcon,
        },
        text: "Error In Saving",
    },
    [DOCUMENT_SAVE_STATUS_MODE.EVALUATING]: {
        spinnerProps: { size: "sm", color: "red-primary" },
        text: "Evaluating",
    },
    [DOCUMENT_SAVE_STATUS_MODE.UPDATING_HIGHLIGHTS]: {
        iconProps: {
            IC: RedoSuccessIcon,
        },
        text: "Updating Highlights",
    },
    [DOCUMENT_SAVE_STATUS_MODE.UPDATED_HIGHLIGHTS]: {
        iconProps: {
            IC: DoubleTickIcon,
        },
        text: "Updated Highlights",
    },
};
const DocumentSaveStatus = ({ mode }) => {
    const { iconProps, text, spinnerProps } = DocumentSaveModeMapping[mode];
    return (React.createElement("span", { className: classes.container },
        iconProps && React.createElement(Icon, { ...iconProps }),
        spinnerProps && React.createElement(Spinner, { ...spinnerProps }),
        React.createElement(Text, { variant: "body-xs/semibold", color: "grey-dark-80" }, text)));
};
export default DocumentSaveStatus;
