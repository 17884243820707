import React from "react";
import { deleteUndefinedProps } from "./Icon.utils";
const IconSizeToPxMap = {
    xxs: undefined,
    xs: 12,
    sm: 16,
    md: 18,
    lg: 24,
    xl: undefined,
};
const Icon = ({ IC, height, width, size, fill, stroke, viewBox, className, opacity, onClick, }) => {
    if (size && !["xs", "sm", "md", "lg"].includes(size)) {
        throw Error(`Invalid size ${size}, only "xs", "sm", "md" and "lg" supported`);
    }
    if (size) {
        height = IconSizeToPxMap[size];
        width = IconSizeToPxMap[size];
    }
    const props = deleteUndefinedProps({
        fill,
        height,
        width,
        stroke,
        viewBox,
        opacity,
    });
    return React.createElement(IC, { className: className, onClick: onClick, ...props });
};
export default Icon;
