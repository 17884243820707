import clsx from "clsx";
import React from "react";
import classes from "./Button.module.scss";
import SpinnerAligned from "../../Loader/SpinnerAligned";
import Icon from "../../Other/Icon";
import { getTooltipClassNames, getTooltipStyles, } from "../../Other/Tooltip/Tooltip.utils";
import Text from "../../Text/TextAligned/Text";
import { getSpinnerPropsFromButtonProps, getTextPropsFromButtonProps, } from "./Button.utils";
const ButtonSizeToIconSizeMap = {
    xxs: undefined,
    xs: "xs",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: undefined,
};
const ButtonSizeToTooltipGapMap = {
    xxs: "1.6em",
    xs: "2.2em",
    sm: "2.2em",
    md: "3em",
    lg: "3.2em",
    xl: "2.4em",
};
// Sets the core look and style of the Button. Has no defaults.
const _Button = ({ size, variant, isFullWidth, disabled, children, onClick, className, selected, loading, isBlock, outline, tooltipProps, title, type, }) => {
    return (React.createElement("button", { className: clsx(classes["button"], classes[variant], classes[size], className, {
            [classes["fullWidth"]]: isFullWidth,
            [classes["outline"]]: outline,
            [classes["selected"]]: selected,
            [classes["loading"]]: loading,
            [classes["block"]]: isBlock,
            [getTooltipClassNames({
                ...(tooltipProps || {}),
            })]: tooltipProps,
        }), style: getTooltipStyles({
            gap: tooltipProps?.gap ?? ButtonSizeToTooltipGapMap[size],
            delayInSeconds: tooltipProps?.delayInSeconds ?? undefined,
        }), disabled: disabled, onClick: onClick, "data-tooltip": tooltipProps?.message, title: title, type: type }, children));
};
// Sets the content of the button and enforces the styleguide, has defaults.
// This Button component is to be imported and used everywhere.
const Button = ({ size = "md", variant = "primary", text = "", textColor, leftIconProps, rightIconProps, loading = false, disabled, onClick, isFullWidth = false, className, selected = false, outline = false, isBlock = true, tooltipProps, title, type, }) => {
    const textProps = getTextPropsFromButtonProps({
        variant,
        size,
        disabled,
        outline,
    });
    const spinnerProps = getSpinnerPropsFromButtonProps({ variant, size });
    return (React.createElement(_Button, { variant: variant, size: size, onClick: onClick, isFullWidth: isFullWidth, disabled: disabled, className: className, outline: outline, selected: !disabled && !loading && selected, loading: loading, isBlock: isBlock, tooltipProps: tooltipProps, title: title, type: type }, loading ? (React.createElement(React.Fragment, null,
        React.createElement(SpinnerAligned, { ...spinnerProps }))) : (React.createElement(React.Fragment, null,
        leftIconProps && (React.createElement(Icon, { ...leftIconProps, size: ButtonSizeToIconSizeMap[size] })),
        text && (React.createElement(Text, { ...textProps, color: textColor ? textColor : textProps.color, whiteSpace: "nowrap" }, text)),
        rightIconProps && (React.createElement(Icon, { ...rightIconProps, size: ButtonSizeToIconSizeMap[size] }))))));
};
export default Button;
