/** @format */
import React, { useMemo } from "react";
import "../../../styles/base.scss";
import { DOCUMENT_UPLOADER_STEPS } from "../../../constants";
import { useDocumentUploader } from "../../../hooks";
import InputText from "../../Input/InputText";
import Modal from "../../Modal/Modal";
import DocumentEdit from "../DocumentEdit";
import DocumentSelect from "../DocumentSelect";
import DocumentUploading from "../DocumentUploading";
const Step1 = (props) => React.createElement(DocumentSelect, { ...props });
const Step2 = (props) => (React.createElement("div", { className: "flex margin-auto height--full", style: { width: "400px" } },
    React.createElement(DocumentEdit, { ...props })));
const Step3 = (props) => React.createElement(DocumentUploading, { ...props });
const withModal = (Component) => 
// eslint-disable-next-line react/display-name
({ resetAcceptedState, ...props }) => (React.createElement(Modal, { size: "small", show: true, close: resetAcceptedState, addBodyClassName: "modal__body--no-pad" },
    React.createElement(Component, { ...props })));
const ModalStep2 = withModal(Step2);
const ModalStep3 = withModal(Step3);
const DocumentUploadSwitch = ({ step, firstScreenProps, secondScreenProps, thirdScreenProps, resetAcceptedState, isModal = false, }) => {
    const stepComponents = {
        [DOCUMENT_UPLOADER_STEPS.STEP_1]: React.createElement(Step1, { ...firstScreenProps }),
        [DOCUMENT_UPLOADER_STEPS.STEP_2]: isModal ? (React.createElement(ModalStep2, { resetAcceptedState: resetAcceptedState, ...secondScreenProps })) : (React.createElement(Step2, { ...secondScreenProps })),
        [DOCUMENT_UPLOADER_STEPS.STEP_3]: isModal ? (React.createElement(ModalStep3, { resetAcceptedState: resetAcceptedState, ...thirdScreenProps })) : (React.createElement(Step3, { ...thirdScreenProps })),
    };
    return stepComponents[step] || Step1;
};
const DocumentUploadController = ({ onDocumentUpload = () => null, accept = [], maxFiles = 1, onDocumentError = () => null, multiple = false, uploadingImageSrc = null, isModal = false, showProgressBar = false, progress = 0, }) => {
    const { getRootProps, getInputProps, isDragActive, open, ref, ocrChecked, toggleCheckedState, fileName, resetAcceptedState, setFileName, step, handleOnUploadClicked, acceptedFiles = [], } = useDocumentUploader({
        onDocumentUpload,
        accept,
        maxFiles,
        onDocumentError,
        multiple,
    });
    const acceptedFileConfiguration = useMemo(() => {
        if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
            const currentFile = acceptedFiles[0];
            const isMimeTypeIconAvailable = accept.find(({ mimeType, extentions }) => {
                return (currentFile.type === mimeType ||
                    extentions.find((t) => currentFile.name.includes(t)));
            });
            if (isMimeTypeIconAvailable) {
                const { 
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                mimeType, 
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                extentions, message, helptipProp = null, ...rest } = isMimeTypeIconAvailable;
                const isAnyKey = Object.keys(rest).length > 0;
                return isAnyKey
                    ? {
                        ...rest,
                        helptipProp: message || helptipProp
                            ? {
                                ...(helptipProp || {}),
                                message: message || helptipProp?.message || "",
                            }
                            : null,
                    }
                    : null;
            }
            return null;
        }
        return null;
    }, [acceptedFiles]);
    return (React.createElement("div", { ...getRootProps({ className: "height-80vh" }) },
        React.createElement(InputText, { ...getInputProps() }),
        React.createElement(DocumentUploadSwitch, { step: step, isModal: isModal, resetAcceptedState: resetAcceptedState, firstScreenProps: {
                isDragActive: isDragActive,
                onUploadButtonClick: open,
            }, secondScreenProps: {
                fileName: fileName,
                ocrValue: ocrChecked,
                onToggleOcr: toggleCheckedState,
                onChangeFileName: setFileName,
                onUploadDocumentClick: handleOnUploadClicked,
                onDocumentUploaderClicked: open,
                acceptedFileConfiguration,
            }, thirdScreenProps: {
                counterRef: ref,
                uploadingImageSrc: uploadingImageSrc,
                showProgressBar,
                progress,
            } })));
};
export default DocumentUploadController;
