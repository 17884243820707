import React from "react";
import "./CardDocumentHoverMenu.scss";
import Button from "../../Button/ButtonRevamped";
import Text from "../../Text/Text";
const CardDocumentHoverMenu = ({ items: actions, // onClick = () => null,
 }) => {
    return (React.createElement("div", { className: "card-document-hover-menu__container" }, actions.map((action) => (React.createElement(Button, { key: action.id, onClick: () => action.onClick?.(), variant: "plain", className: "card-document-hover-menu__container__action-item", size: "less-pad-lr" },
        React.createElement("span", { className: "card-document-hover-menu__container__action-item__icon" }, action?.Icon ? React.createElement(action.Icon, null) : null),
        React.createElement(Text, { size: "0.75", weight: "600" }, action.label))))));
};
export default CardDocumentHoverMenu;
