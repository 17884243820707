import React from "react";
import ChevronRightMainIcon from "../../../static/icons/chevron-right-main.svg";
import DocMultiplePlainIcon from "../../../static/icons/doc-multiple-plain.svg";
import DocumentTextPlainIcon from "../../../static/icons/document-text-plain.svg";
import "./CompareGuide.scss";
import Text from "../../Text/Text";
import Icon from "../Icon";
const CompareGuide = ({ leftText, rightText, onToggle, leftTooltipProps, rightTooltipProps, isMultiple = false, }) => {
    return (React.createElement("div", { className: "compare-guide", onClick: onToggle },
        React.createElement("div", { className: "compare-guide__section compare-guide__section--left" },
            React.createElement(DocumentTextPlainIcon, null),
            React.createElement(Text, { size: "0.75", weight: "600", color: "grey-dark", tooltipProps: leftTooltipProps }, leftText)),
        React.createElement("div", { className: "compare-guide__fab" },
            React.createElement(Icon, { IC: ChevronRightMainIcon })),
        React.createElement("div", { className: "compare-guide__section compare-guide__section--right" },
            isMultiple ? React.createElement(DocMultiplePlainIcon, null) : React.createElement(DocumentTextPlainIcon, null),
            React.createElement(Text, { size: "0.75", weight: "600", color: "grey-dark", tooltipProps: rightTooltipProps }, rightText))));
};
export default CompareGuide;
