import React, { useState } from "react";
import GoogleIcon from "../../../static/icons/google.svg";
import classes from "./AuthSignIn.module.scss";
import { validateEmail, validatePassword } from "../../../utils/validators";
import Button from "../../Button/ButtonAligned/Button";
import Field from "../../Form/Field/Field";
import Form from "../../Form/Form/Form";
import Text from "../../Text/TextAligned/Text";
import BaseAuthContent from "../AuthContentBase";
const AuthSignIn = ({ url, requestHeaders, onSuccess, onClickSignUp, onClickResetPassword, onClickBack, onClickLoginGoogle, }) => {
    const [authMessage, setAuthMessage] = useState();
    return (React.createElement(BaseAuthContent, { onClickBack: onClickBack, title: "Log in with your email", subTitle: "Use your email to log in to your workspace", form: React.createElement("div", { className: classes.formBody },
            React.createElement(Form, { url: url, requestHeaders: requestHeaders, onSuccess: onSuccess, onError: (error) => {
                    console.log(error);
                    if (error instanceof Error) {
                        setAuthMessage({
                            text: "Error",
                            secondaryText: "An error occured. Please try again.",
                        });
                    }
                    else {
                        setAuthMessage({
                            text: "Error",
                            secondaryText: error?.form.map((f) => f.text).join(", ") || "",
                        });
                    }
                } },
                React.createElement(Field, { id: "username", key: "username", label: "Email ID", isRequired: true, placeholder: "Enter Email Here", className: classes.horizontalField, initialValue: { value: "" }, validators: [(v) => validateEmail(v?.value)] }),
                React.createElement(Field, { id: "password", key: "password", label: "Password", type: "password", isRequired: true, placeholder: "Enter Password Here", className: classes.horizontalField, initialValue: { value: "" }, validators: [(v) => validatePassword(v?.value, "sign-in")] }),
                React.createElement("div", { className: classes.buttonContainer },
                    React.createElement(Button
                    // className={classes.formLoginButton}
                    , { 
                        // className={classes.formLoginButton}
                        type: "submit", variant: "primary", text: "Login", isFullWidth: true }),
                    React.createElement(Button, { variant: "alternative-2", onClick: onClickLoginGoogle, isFullWidth: true, leftIconProps: { IC: GoogleIcon }, text: "Login with Google" })))), authMessageProps: authMessage, footer: React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "body-xs", color: "grey-dark-70" }, "Don't have an account?"),
            React.createElement(Button, { text: "Sign-Up Here", textColor: "red-primary", variant: "ghost", size: "xs", onClick: onClickSignUp })) }));
};
export default AuthSignIn;
