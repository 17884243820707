import React from "react";
import RedactionIntroBlackSVG from "../../../images/redaction-intro-black.svg";
import RedactionIntroRulesSVG from "../../../images/redaction-intro-rules.svg";
// import RedactionIntroConvertSVG from "../../../images/redaction-intro-convert.svg"; // added as image.
import Button from "../Button";

const ICONPATH = "../../../../static/images/";

export default function IntroRedaction({}) {
  return (
    <div className="landing__about-us">
      <div className="landing__about-us__container">
        {/* <div className="landing__about-us__text-content"> */}
        <p className="text-align-center">About Us</p>
        <p className="font-black-700 text-align-center font-size-2">
          Document Processing <br />
          Like Never Before
        </p>

        {/* BLOCK ONE */}
        <div className="landing__about-us__main-content">
          <div className="landing__about-us__main-content__text">
            <p className="font-black-600 font-size-2">
              Automatically find <br />
              and securely redact
              <br />
              confidential information <br />
            </p>
            <p className="text-color-grey-71">
              Lexapar AI comes with pretrained AI models capable <br />
              of capturing information in 20+ label categories, <br />
              trained on high quality, non-public datasets. It works <br />
              on digital and scanned documents.
            </p>
            <Button
              text="Sign-up Now"
              className="button--primary button--font-full font-weight-600 margin-top-2"
              clickHandler={() => (window.location.href = "/create_account/")}
            />
          </div>
          <RedactionIntroBlackSVG className="landing__about-us__main-content__image" />
        </div>

        {/* BLOCK TWO */}
        <div className="landing__about-us__main-content">
          <RedactionIntroRulesSVG className="landing__about-us__main-content__image" />
          <div className="landing__about-us__main-content__text">
            <p className="font-black-600 font-size-2">
              Personalize the system by <br />
              adding custom rules to suit <br />
              your special requirements
            </p>
            <p className="text-color-grey-71">
              Create Block rules to always find and redact <br />
              particular words and phrases in all documents
              <br />
              or Allow rules to never redact them in any document.
            </p>
            <Button
              text="Sign-up Now"
              className="button--primary button--font-full font-weight-600 margin-top-2"
              clickHandler={() => (window.location.href = "/create_account/")}
            />
          </div>
        </div>

        {/* BLOCK THREE */}
        <div className="landing__about-us__main-content">
          <div className="landing__about-us__main-content__text">
            <p className="font-black-600 font-size-2">
              Export and convert <br />
              documents in multiple <br />
              formats
            </p>
            <p className="text-color-grey-71">
              Securely export your documents with <br />
              redacted content permanently removed <br />
              and download them in a variety of formats.
            </p>
            <Button
              text="Sign-up Now"
              className="button--primary button--font-full font-weight-600 margin-top-2"
              clickHandler={() => (window.location.href = "/create_account/")}
            />
          </div>
          {/* <RedactionIntroConvertSVG className="landing__about-us__main-content__image" /> */}
          <img
            src={`${ICONPATH + "redaction-intro-convert.svg"}`}
            className="landing__about-us__main-content__image"
          />
        </div>
      </div>
    </div>
  );
}
