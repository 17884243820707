import React from "react";
import GoogleIcon from "../../../static/icons/google.svg";
import BaseAuthContent from "../AuthContentBase";
const AuthVerificationFailed = ({ onClickLogin, onClickLoginGoogle, onClickBack, }) => {
    return (React.createElement(BaseAuthContent, { onClickBack: onClickBack, title: "Account Verified Failed", 
        // iconProps={{ IC: DoubleTickIcon, width: 45, height: 45 }}
        subTitle: "Please check the verification link", actionButtons: [
            {
                type: "submit",
                variant: "primary",
                text: "Login",
                isFullWidth: true,
                onClick: onClickLogin,
            },
            {
                variant: "alternative-2",
                isFullWidth: true,
                leftIconProps: { IC: GoogleIcon },
                text: "Login with Google",
                onClick: onClickLoginGoogle,
            },
        ] }));
};
export default AuthVerificationFailed;
