import clsx from "clsx";
import React, { useState } from "react";
import ThreeDotIcon from "../../../static/icons/three-dot.svg";
import classes from "./CardHoverMenuAligned.module.scss";
import Button from "../../Button/ButtonAligned/Button";
import ButtonGroup from "../../Button/ButtonGroup";
const CardHoverMenuAligned = ({ items = [], className, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [heirarchy, setHeirarchy] = useState([]);
    const onMouseEnter = () => setIsOpen(true);
    const onMouseLeave = () => setIsOpen(false);
    const currentItems = heirarchy.reduce((acc, id) => {
        const findItem = acc.find((it) => it.id === id)?.items;
        return findItem;
    }, items);
    return (React.createElement("div", { className: clsx(classes.container, className), onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        !isOpen && (React.createElement(Button, { variant: "alternative-1", size: "xs", leftIconProps: {
                IC: ThreeDotIcon,
            } })),
        isOpen && (React.createElement(ButtonGroup, null, currentItems.map((item) => (React.createElement(Button, { variant: "alternative-1", size: "xs", text: item.text, onClick: () => {
                if (item?.items) {
                    setHeirarchy([...heirarchy, item.id]);
                }
                else {
                    item.onClick?.();
                }
            }, leftIconProps: item.iconProps, key: item.id, tooltipProps: item.tooltipProps })))))));
};
export default CardHoverMenuAligned;
