import React from "react";
import HeroBackgroundPattern from "../../../static/icons/hero-background-pattern.svg";
import SingleTickIcon from "../../../static/icons/single-tick.svg";
import classes from "./MyFilesEmpty.module.scss";
import Text from "../../Text/TextAligned/Text";
import Icon from "../Icon";
import IconWrapped from "../IconWrapped";
const MyFilesEmpty = ({ title, subTitle, items }) => {
    return (React.createElement("div", { className: classes.myFilesEmpty },
        React.createElement("div", { className: classes.iconAndText },
            React.createElement(Icon, { IC: HeroBackgroundPattern, width: 240.51, height: 174 }),
            React.createElement("div", { className: classes.text },
                React.createElement(Text, { variant: "heading-lg" }, title),
                React.createElement(Text, { variant: "body-sm" }, subTitle))),
        React.createElement("hr", { className: classes.border }),
        React.createElement("div", { className: classes.items }, items.map((item) => (React.createElement("div", { key: item.id, className: classes.item },
            React.createElement(IconWrapped, { IC: SingleTickIcon, stroke: "black", size: "sm", backgroundColor: "grey-light-20" }),
            React.createElement(Text, { variant: "heading-sm", color: "grey-dark-80" }, item.text)))))));
};
export default MyFilesEmpty;
