// Make sure they match with Main color variables in base.scss
/**
 * DO NOT EDIT THIS FILE, THIS IS LOCKED WITH THE DESIGN STYLE-GUIDE
 */
export var Color;
(function (Color) {
    Color["white"] = "#ffffff";
    Color["red-primary"] = "#93182f";
    Color["black"] = "#1a1a1a";
    Color["grey-dark-85"] = "#444444";
    Color["grey-dark-80"] = "#515151";
    Color["grey-dark-70"] = "#6b6b6b";
    Color["grey-dark-60"] = "#888888";
    Color["grey-light-60"] = "#d8d8d8";
    Color["grey-light-40"] = "#cfcdcd";
    Color["grey-light-50"] = "#eaeaea";
    Color["grey-light-20"] = "#f7f7f7";
    Color["success"] = "#2f704b";
    Color["success-accent"] = "#e4f4eb";
    Color["warning"] = "#f3ae3b";
    Color["warning-accent"] = "#fef6e8";
    Color["error"] = "#c11e1e";
    Color["error-accent"] = "#f8e6e6";
    Color["red-primary-dark-50"] = "#6a091";
    Color["red-primary-dark-70"] = "#63091c";
    Color["red-primary-dark-80"] = "#3f000d";
    Color["black-80"] = "#303030";
    Color["brown"] = "#78350f";
    Color["purple-dark-100"] = "#9747ff";
    Color["purple-light-100"] = "#f5edff";
    Color["orange-dark-100"] = "#7d1a11";
    Color["orange-light-100"] = "#ffe6d5";
    Color["orange"] = "#ff8c21";
    Color["yellow-light-100"] = "#fef6e8";
    Color["yellow"] = "#ffe55e";
    Color["yellow-dark-100"] = "#786500";
    Color["blue"] = "#005bd3";
    Color["blue-dark-100"] = "#3e53a6";
    Color["blue-light-100"] = "#007aff26";
    Color["blue-dark-20"] = "#007aff";
})(Color || (Color = {}));
