import React from "react";
import BadgeBasic from "../../../icons/badge-basic.svg";
import BadgePremium from "../../../icons/badge-premium.svg";
import BadgeFree from "../../../icons/badge-free.svg";
import PricingCard from "./PricingCard";

export const pricingCardData = {
  free: {
    badgeComponent: <BadgeFree />,
    description: "Introductory 1 Month Trial",
    amountPerMonth: 0,
    points: [
      "5 Documents",
      "Private & Secure PDF Redaction",
      // "Convert PDF to Word, Excel & PPT",
      "Export to PDF",
      // "Export to PDF, Word, Excel & PPT",
    ],
    clickHandler: null,
  },
  basic: {
    badgeComponent: <BadgeBasic />,
    description: "For Individuals",
    amountPerMonth: 790,
    points: [
      "100 Documents",
      "Private & Secure PDF Redaction",
      "Custom AI Models",
      // "Convert PDF to Word, Excel and PPT",
      // "Export to PDF, Word, Excel or PPT",
      "Export to PDF",
    ],
    clickHandler: () => (window.location.href = "/subscription/basic/"),
  },
  premium: {
    badgeComponent: <BadgePremium />,
    description: "For Professionals",
    amountPerMonth: 3490,
    points: [
      "100 Documents",
      "Private & Secure PDF Redaction",
      "Custom AI Models",
      "Export to PDF, Word, Excel or PPT",
      "Convert PDF to Word, Excel and PPT",
    ],
    clickHandler: () => (window.location.href = "/subscription/pro/"),
  },
};

type Props = {
  sectionHeader?: string;
  header?: string;
  boxClass?: string;
};

export default function Pricing({
  sectionHeader = "PRICING",
  header = "Plan that suits you best",
  boxClass = "box--body",
}: Props) {
  return (
    <div className={boxClass}>
      <div className="container landing__pricing__container" id="pricing">
        <p className="text-align-center">{sectionHeader}</p>
        <p className="font-black-700 text-align-center font-size-2">{header}</p>
        <div className="landing__pricing-cards">
          {Object.entries(pricingCardData).map(([k, v], i) => (
            <PricingCard key={i} {...v} />
          ))}
        </div>
      </div>
    </div>
  );
}
