import React from "react";
import BoxIcon from "../../../static/icons/box.svg";
import { Color } from "../../../types";
import { toTitleCase } from "../../../utils";
import Badge from "../BadgeAligned/Badge";
import { getRiskLevel } from "../BadgeRisk/BadgeRisk";
const riskLevelToColorMap = {
    low: "success",
    medium: "warning",
    high: "error",
};
export const getBadgeRiskProps = (riskLevel, percent) => {
    if (!riskLevel) {
        if (percent) {
            riskLevel = getRiskLevel(percent).toLowerCase();
        }
        else {
            riskLevel = undefined;
        }
    }
    const text = `${toTitleCase(riskLevel || "")} Risk`;
    return {
        text: text,
        leftIconProps: {
            IC: BoxIcon,
            fill: Color[riskLevelToColorMap[riskLevel || "high"]],
            stroke: Color[riskLevelToColorMap[riskLevel || "high"]],
        },
    };
};
const BadgeRisk = ({ percent, riskLevel }) => {
    return React.createElement(Badge, { ...getBadgeRiskProps(riskLevel, percent) });
};
export default BadgeRisk;
