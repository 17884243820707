import clsx from "clsx";
export const positionClass = {
    top: "tooltip--top", // default
    left: "tooltip--left",
    right: "tooltip--right",
    bottom: "tooltip--bottom",
};
const sizeClass = {
    default: "",
    medium: "tooltip--medium",
};
/**
 * function returns a string of CSS class names based on the input parameters.
 * The function takes an object as its parameter, which can have properties like position, size, className, and backgroundWhite.
 * It uses the clsx library to concatenate and conditionally apply CSS class names based on the input values.
 *
 * @param TooltipClassNamesType
 * @returns string of className
 */
export function getTooltipClassNames({ position = "top", size = "default", className = "", backgroundWhite = false, }) {
    return clsx([
        `tooltip`,
        positionClass[position],
        sizeClass[size],
        {
            "tooltip--background-white": backgroundWhite,
        },
        className,
    ]);
}
export function getTooltipStyles({ gap, delayInSeconds }) {
    const style = {};
    if (gap) {
        style["--tooltip-gap"] = gap;
    }
    if (delayInSeconds !== null && delayInSeconds !== undefined) {
        style["--tooltip-delay"] = `${delayInSeconds}s`;
    }
    return style;
}
