import React from "react";
import MailConfirmIcon from "../../../static/icons/mail-confirm.svg";
import Button from "../../Button/ButtonAligned/Button";
import Text from "../../Text/TextAligned/Text";
import BaseAuthContent from "../AuthContentBase";
const AuthResetPasswordLinkSent = ({ onClickLogin, onClickBack, }) => {
    return (React.createElement(BaseAuthContent, { onClickBack: onClickBack, title: "Reset Link Sent", iconProps: {
            IC: MailConfirmIcon,
            width: 40,
            height: 40,
        }, subTitle: "We have sent you the password reset link on your email. Please follow the link sent on your email to reset the password", footer: React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "body-xs", color: "grey-dark-60" }, "Already Have An Account?"),
            React.createElement(Button, { text: "Login", textColor: "red-primary", variant: "ghost", size: "xs", onClick: onClickLogin })) }));
};
export default AuthResetPasswordLinkSent;
