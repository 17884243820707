import clsx from "clsx";
import React from "react";
import classes from "./CheckboxYesOrNo.module.scss";
import Button from "../../Button/ButtonRevamped";
import Text from "../../Text/Text";
const CheckboxYesOrNo = ({ checked = false, onChange = () => null, }) => {
    const handleChange = () => {
        onChange(!checked);
    };
    return (React.createElement("span", { className: classes.wrapper, onClick: handleChange },
        React.createElement(Button, { variant: checked ? "primary" : "", className: clsx(classes.first, {
                [classes.yes]: checked,
                [classes.no]: !checked,
            }), size: "" },
            React.createElement(Text, { weight: "semibold", size: "small", color: checked ? "" : "secondary" }, "Yes")),
        React.createElement(Button, { variant: !checked ? "primary" : "", className: clsx(classes.second, {
                [classes.yes]: !checked,
                [classes.no]: checked,
            }), size: "" },
            React.createElement(Text, { weight: "semibold", size: "small", color: !checked ? "" : "secondary" }, "No"))));
};
export default CheckboxYesOrNo;
