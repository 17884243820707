import React from "react";
/**
 * AccordionContext
 * {
 *  toggle: PropTypes.func.isRequired,
 *  openId: PropTypes.string,
 * }
 */
export default React.createContext({
    open: "",
    toggle: () => null,
});
