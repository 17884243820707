import clsx from "clsx";
import React, { Suspense, forwardRef, useState, } from "react";
import CompactHeightCompactWidthIcon from "../../../static/icons/compact-height-compact-width.svg";
import CompactHeightFullWidthIcon from "../../../static/icons/compact-height-full-width.svg";
import FullHeightCompactWidthIcon from "../../../static/icons/full-height-compact-width.svg";
import FullHeightLessWidthIcon from "../../../static/icons/full-height-less-width.svg";
import MinimizeIcon from "../../../static/icons/minimize.svg";
import OpenInFullIcon from "../../../static/icons/open-in-full.svg";
import PinIcon from "../../../static/icons/pin-active.svg";
import UnPinIcon from "../../../static/icons/unpin.svg";
import "../../../styles/base.scss";
import "./Card.scss";
import { Color } from "../../../types";
import { CDRT } from "../../Canvas/Canvas/Canvas.types";
import { useCanvasData, useCanvasDataDispatch, useOnCanvasDataChange, } from "../../Canvas/Canvas/CanvasDataProvider";
import { getWidthHeight } from "../../Canvas/Canvas/utils/Canvas.utils.getter_and_setter";
import { CardBody } from "../CardBody";
import { CardHeader } from "../CardHeader";
import { CardMovementDirectionType } from "./Card.types";
// import { SIZE_W_H_MAPPING, getSizeKeyFromDimensions } from "./Card.utils";
const CardMovementButton = React.lazy(() => import("../CardMovementButton")); // added lazy import, Card story was not working
const CardHoverMenuAligned = React.lazy(() => import("../CardHoverMenuAligned"));
export const cardSizeToHeightWidthMap = {
    sm: {
        h: 1,
        w: 2,
    },
    md: {
        h: 2,
        w: 2,
    },
    lg: {
        h: 4,
        w: 2,
    },
    xl: {
        h: 4,
        w: 4,
    },
};
const Card = ({ children, onClick, className, headerChildren, footerChildren, cardHeightFull, bodyHeightFull, bodyHeightFill, bodyNoPad, ...cardBaseProps }, ref) => {
    const dispatch = useCanvasDataDispatch();
    const onCanvasDataChange = useOnCanvasDataChange();
    const canvasData = useCanvasData();
    const [showIntro, setShowIntro] = useState(cardBaseProps.Intro ? true : false);
    const { hoverMenu, movementButtons, Intro, showPin, pinned, elementId } = cardBaseProps;
    // current height and current width
    let ch, cw;
    // sizeKey: SizeMapping | undefined = undefined;
    if (canvasData && elementId) {
        const elementHeightWidth = getWidthHeight(canvasData, elementId);
        ch = elementHeightWidth.h;
        cw = elementHeightWidth.w;
    }
    const currentSize = Object.keys(cardSizeToHeightWidthMap).find((k) => cardSizeToHeightWidthMap[k]?.h === ch &&
        cardSizeToHeightWidthMap[k]?.w === cw);
    const onMouseEnter = () => handleOnHoverChange(true);
    const onMouseLeave = () => handleOnHoverChange(false);
    const handleOnHoverChange = (hover) => {
        if (elementId) {
            dispatch?.({
                type: CDRT.SET_ELEMENT_HOVER,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    elementId: elementId,
                    hover,
                },
            });
        }
    };
    const handleOnClick = (e) => {
        // Call any callback applied on the Card
        onClick?.(e);
        e.stopPropagation();
        // Set the Card as selected
        if (elementId) {
            dispatch?.({
                type: CDRT.SET_ELEMENT_SELECTED,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    elementId: elementId,
                    selected: true,
                },
            });
        }
    };
    const handleOnClickMinimize = () => {
        if (elementId) {
            dispatch?.({
                // type: CDRT.SET_ENTITY_HIDDEN,
                type: CDRT.SET_ELEMENT_HIDDEN,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    elementId: elementId,
                    value: true,
                },
            });
            dispatch?.({
                type: CDRT.SET_ELEMENT_STATIC,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    id: elementId,
                    static: false,
                },
            });
        }
    };
    const handleOnClickPin = () => {
        if (elementId) {
            dispatch?.({
                type: CDRT.SET_ELEMENT_PINNED,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    id: elementId,
                    pinned: !pinned,
                },
            });
        }
    };
    const handleOnClickToggleSize = (size) => {
        if (elementId) {
            dispatch?.({
                type: CDRT.SET_ELEMENT_SIZE,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    elementId: elementId,
                    width: cardSizeToHeightWidthMap[size]?.w || cw,
                    height: cardSizeToHeightWidthMap[size]?.h || ch,
                },
            });
        }
    };
    const hoverMenuItems = [
        ...(showPin
            ? [
                {
                    id: "pin",
                    text: "",
                    onClick: handleOnClickPin,
                    iconProps: {
                        IC: pinned ? PinIcon : UnPinIcon,
                    },
                    tooltipProps: {
                        position: "bottom",
                        message: "Pin",
                        delayInSeconds: 0,
                    },
                },
            ]
            : []),
        {
            id: "minimize",
            text: "",
            onClick: handleOnClickMinimize,
            iconProps: { IC: MinimizeIcon, fill: "white", stroke: "white" },
            tooltipProps: {
                position: "bottom",
                message: "Minimize",
                delayInSeconds: 0,
            },
        },
        {
            id: "size",
            text: "",
            iconProps: {
                IC: OpenInFullIcon,
            },
            tooltipProps: {
                position: "bottom",
                message: "Resize",
                delayInSeconds: 0,
            },
            items: [
                {
                    id: "sm",
                    text: "",
                    onClick: () => handleOnClickToggleSize("sm"),
                    iconProps: {
                        IC: CompactHeightCompactWidthIcon,
                        stroke: currentSize === "sm" ? Color["orange"] : undefined,
                    },
                    tooltipProps: {
                        position: "bottom",
                        message: "Small",
                        delayInSeconds: 0,
                    },
                },
                {
                    id: "md",
                    text: "",
                    onClick: () => handleOnClickToggleSize("md"),
                    iconProps: {
                        IC: FullHeightCompactWidthIcon,
                        stroke: currentSize === "md" ? Color["orange"] : undefined,
                    },
                    tooltipProps: {
                        position: "bottom",
                        message: "Medium",
                        delayInSeconds: 0,
                    },
                },
                {
                    id: "lg",
                    text: "",
                    onClick: () => handleOnClickToggleSize("lg"),
                    iconProps: {
                        IC: FullHeightLessWidthIcon,
                        stroke: currentSize === "lg" ? Color["orange"] : undefined,
                    },
                    tooltipProps: {
                        position: "bottom",
                        message: "Tall",
                        delayInSeconds: 0,
                    },
                },
                {
                    id: "xl",
                    text: "",
                    onClick: () => handleOnClickToggleSize("xl"),
                    iconProps: {
                        IC: CompactHeightFullWidthIcon,
                        stroke: currentSize === "xl" ? Color["orange"] : undefined,
                    },
                    tooltipProps: {
                        position: "bottom",
                        message: "Large",
                        delayInSeconds: 0,
                    },
                },
            ],
        },
    ];
    return (React.createElement("div", { className: clsx([
            "card",
            className,
            { "card--flex": bodyHeightFill },
            {
                "card--hover": cardBaseProps.hover,
            },
            { "card--selected": cardBaseProps.selected },
            { "card--height-full": cardHeightFull },
            { "card--pinned": pinned },
        ]), ref: ref, id: elementId, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: handleOnClick, ...cardBaseProps, 
        // The title we use for different purposes in cardProps conflict with HTML
        // title which leads to unwanted system tooltip on the whole card.
        title: undefined, style: {
            ...(cardBaseProps.style || {}),
            position: cardBaseProps.isPositionFixed
                ? "fixed"
                : cardBaseProps.style?.position || "initial",
        } },
        !pinned && elementId && movementButtons && (React.createElement(Suspense, null, Object.keys(CardMovementDirectionType)
            .filter((direction) => isNaN(Number(direction)))
            .map((direction) => (React.createElement(CardMovementButton, { key: direction, id: elementId, selected: !!cardBaseProps.selected, direction: direction }))))),
        hoverMenu && elementId && cardBaseProps.hover && (React.createElement(Suspense, null,
            React.createElement(CardHoverMenuAligned, { items: hoverMenuItems }))),
        showIntro && Intro ? (React.createElement(Intro, { onClickDone: () => setShowIntro(false) })) : (React.createElement(React.Fragment, null,
            React.createElement(CardHeader, null, headerChildren),
            React.createElement(CardBody, { heightFill: bodyHeightFill, heightFull: bodyHeightFull, noPad: bodyNoPad }, children),
            React.createElement("div", { className: "card__footer" }, footerChildren)))));
};
export default forwardRef(Card);
