import React from "react";
import classes from "./ChatSuggestions.module.scss";
import Text from "../../Text/TextAligned/Text";
const ChatSuggestions = ({ documentName, suggestions = [], onClick, }) => {
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.heading },
            React.createElement(Text, { variant: "heading-md", color: "red-primary", isBlock: true },
                "Get Started with ",
                React.createElement("br", null),
                documentName),
            React.createElement(Text, { variant: "body-sm" }, "Ask a question or select one")),
        React.createElement("div", { className: classes.suggestions }, suggestions.map(({ title, description, id }) => (React.createElement("div", { key: id, className: classes.suggestion, onClick: () => onClick?.({ id, title, description }) },
            React.createElement(Text, { variant: "heading-xs" }, title),
            React.createElement(Text, { variant: "body-xs" }, description)))))));
};
export default ChatSuggestions;
