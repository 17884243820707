/** @format */
import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./CanvasSpaceLayout.scss";
const ReactGridLayout = WidthProvider(RGL);
const CanvasSpaceLayout = (props) => {
    return (React.createElement(ReactGridLayout, { ...props, "data-testid": "canvas-draggable-resizable", autoSize: false, measureBeforeMount: false, useCSSTransforms: true }, props.children));
};
export default CanvasSpaceLayout;
