import clsx from "clsx";
import React from "react";
import Markdown from "react-markdown";
import colorClasses from "../../../styles/color.module.scss";
import textClasses from "../TextAligned/Text.module.scss";
import classes from "./TextMarkdown.module.scss";
import { TextVariant } from "../TextAligned/Text.types";
import { parseTextVariantStr } from "../TextAligned/Text.utils";
const TextMarkdown = ({ children, className, color = "black", variant, }) => {
    if (variant && !Object.keys(TextVariant).includes(variant)) {
        throw Error(`Got invalid text variant ${variant}`);
    }
    const data = parseTextVariantStr(variant || "body-sm");
    return (React.createElement(Markdown, { className: clsx(classes.textMarkdown, colorClasses[color], className, {
            [textClasses[data.variant]]: variant,
            [textClasses[data.size]]: variant,
            [textClasses[data.weight]]: variant,
        }) }, children));
};
export default TextMarkdown;
