export default {
    "1.1": "line-height-1p1",
    "1.2": "line-height-1p2",
    "1.25": "line-height-1p25",
    "1.3": "line-height-1p3",
    "1.4": "line-height-1p4",
    "1.5": "line-height-1p5",
    "1.6": "line-height-1p6",
    "1.7": "line-height-1p7",
    "1.8": "line-height-1p8",
    "1.9": "line-height-1p9",
    "2": "line-height-2",
    "2.5": "line-height-2p5",
    "2.25": "line-height-2p25",
    "": "",
};
