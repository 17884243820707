import clsx from "clsx";
import React, { Suspense, forwardRef } from "react";
import MinimizeIcon from "../../../static/icons/minimize.svg";
import PinIcon from "../../../static/icons/pin-active.svg";
import UnPinIcon from "../../../static/icons/unpin.svg";
import "../Card/Card.scss";
import { CDRT } from "../../Canvas/Canvas/Canvas.types";
import { useCanvasDataDispatch, useOnCanvasDataChange, } from "../../Canvas/Canvas/CanvasDataProvider";
import { CardMovementDirectionType } from "../Card/Card.types";
const CardHoverMenuAligned = React.lazy(() => import("../CardHoverMenuAligned"));
const CardMovementButton = React.lazy(() => import("../CardMovementButton")); // added lazy import, Card story was not working
const CardGroup = ({ children, className, id, bodyHeightFull = true, ...cardBaseProps }, ref) => {
    const dispatch = useCanvasDataDispatch();
    const onCanvasDataChange = useOnCanvasDataChange();
    const { hoverMenu, showPin, pinned } = cardBaseProps;
    const handleOnClick = (e) => {
        // Set the Card as selected
        e.stopPropagation();
        if (id) {
            dispatch?.({
                type: CDRT.SET_STRUCTURE_SELECTED,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    structureId: id,
                    selected: true,
                },
            });
        }
    };
    const handleOnClickMinimize = () => {
        if (id) {
            dispatch?.({
                // type: CDRT.SET_ENTITY_HIDDEN,
                type: CDRT.SET_STRUCTURE_HIDDEN,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    structureId: id,
                    value: true,
                },
            });
            dispatch?.({
                type: CDRT.SET_STRUCTURE_STATIC,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    id: id,
                    static: false,
                },
            });
        }
    };
    const handleOnClickPin = () => {
        if (id) {
            dispatch?.({
                type: CDRT.SET_STRUCTURE_PINNED,
                data: {
                    onCanvasDataChange: onCanvasDataChange,
                    id,
                    pinned: !pinned,
                },
            });
        }
    };
    const hoverMenuItems = [
        ...(showPin
            ? [
                {
                    id: "pin",
                    text: "",
                    onClick: handleOnClickPin,
                    iconProps: {
                        IC: pinned ? PinIcon : UnPinIcon,
                    },
                    tooltipProps: {
                        position: "bottom",
                        message: "Pin",
                        delayInSeconds: 0,
                    },
                },
            ]
            : []),
        {
            id: "minimize",
            text: "",
            onClick: handleOnClickMinimize,
            iconProps: { IC: MinimizeIcon, stroke: "white" },
            tooltipProps: {
                position: "bottom",
                message: "Minimize",
                delayInSeconds: 0,
            },
        },
    ];
    return (React.createElement("div", { className: clsx("card-group", className, {
            "card-group--selected": cardBaseProps.selected,
        }, { "card-group--pinned": pinned }), ref: ref, id: id, onClick: handleOnClick, ...cardBaseProps, 
        // The title we use for different purposes in cardProps conflict with HTML
        // title which leads to unwanted system tooltip on the whole card.
        title: undefined, style: {
            ...(cardBaseProps.style || {}),
            position: cardBaseProps.isPositionFixed
                ? "fixed"
                : cardBaseProps.style?.position || "initial",
        } },
        hoverMenu && id && cardBaseProps.selected && (React.createElement(Suspense, null,
            React.createElement(CardHoverMenuAligned, { items: hoverMenuItems }))),
        hoverMenu && (React.createElement(Suspense, null, Object.keys(CardMovementDirectionType)
            .filter((direction) => isNaN(Number(direction)))
            .map((direction) => (React.createElement(CardMovementButton, { key: direction, id: id, direction: direction, selected: !!cardBaseProps.selected }))))),
        React.createElement("div", { className: `card-group__body ${bodyHeightFull ? "card-group__body--height-full" : ""}` }, children)));
};
export default forwardRef(CardGroup);
