import React from "react";
import CancelIcon from "../../../static/icons/cancel.svg";
import RoundedExclamationIcon from "../../../static/icons/rounded-exclamation-sm.svg";
import classes from "./EditorMessage.module.scss";
import Button from "../../Button/ButtonAligned/Button";
import Text from "../../Text/TextAligned/Text";
import Icon from "../Icon";
const EditorMessage = ({ onCancel, message }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.container },
            React.createElement("span", { className: classes.textGroup },
                React.createElement(Icon, { IC: RoundedExclamationIcon }),
                React.createElement(Text, { variant: "body-sm", color: "black" }, message)),
            React.createElement(Button, { variant: "ghost", size: "sm", text: "Cancel", textColor: "grey-dark-60", onClick: onCancel, leftIconProps: {
                    IC: CancelIcon,
                    stroke: "#395266",
                } }))));
};
export default EditorMessage;
