import React from "react";
import DocIcon from "../../../static/icons/doc.svg";
import DocxIcon from "../../../static/icons/docx.svg";
import ExcelIcon from "../../../static/icons/excel.svg";
import PdfIcon from "../../../static/icons/pdf.svg";
import PowerpointIcon from "../../../static/icons/powerpoint.svg";
import { MimeType } from "../../../types/mime";
import Icon from "../Icon";
export const MimeDocumentIconMap = {
    [MimeType.PDF]: PdfIcon,
    [MimeType.DOCX]: DocxIcon,
    [MimeType.PPTX]: PowerpointIcon,
    [MimeType.XLSX]: ExcelIcon,
};
export function getDocumentIconProps(format) {
    switch (format) {
        case "pdf":
            return { IC: PdfIcon, height: 24, width: 24 };
        case "docx":
            return { IC: DocxIcon, height: 24, width: 24 };
        case "pptx":
            return { IC: PowerpointIcon, height: 24, width: 24 };
        case "xlsx":
            return { IC: ExcelIcon, height: 24, width: 24 };
        default:
            return { IC: DocIcon, height: 24, width: 24 };
    }
}
/**
 * Returns the appropriate document icon <img> element based on given format
 */
const DocumentIcon = ({ format, className }) => {
    return React.createElement(Icon, { ...getDocumentIconProps(format), className: className });
};
export default DocumentIcon;
