import { $createMarkNode, $getMarkIDs, $isMarkNode, MarkNode, } from "@lexical/mark";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister, registerNestedElementResolver } from "@lexical/utils";
import { produce } from "immer";
import { $getNodeByKey, $getSelection, $isRangeSelection, $isTextNode, COMMAND_PRIORITY_EDITOR, createCommand, } from "lexical";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, } from "react";
import classes from "./EditorPluginHighlight.module.scss";
import Button from "../../Button/ButtonAligned/Button";
import Text from "../../Text/TextAligned/Text";
import EditorCardDocument from "../EditorCardDocument";
import EditorCardGenerate from "../EditorCardGenerate";
import EditorCardHighlightComponent from "../EditorCardHighlightComponent/EditorCardHighlightComponent";
import EditorCardHighlightSubComponent from "../EditorCardHighlightSubComponent";
import EditorCardRecommend from "../EditorCardRecommend";
import { EditorCardResolvedMode } from "../EditorCardResolved/EditorCardResolved.types";
import EditorFloat from "../EditorFloat/EditorFloat";
import { $getSelectionFromOffsets } from "../EditorPluginOffset/EditorPluginOffset.utils";
import { EditorHighlightKind, } from "./EditorPluginHighlight.types";
import { $getContextSelectionFromSelection, $getWrappedIds, $markHighlight, $selectHighlightStart, $unmarkHighlight, getComponentHighlight, getCustomHighlight, getNewId, getSelectIds, getSubComponentHighlight, hideFloat, isHighlightComponentMissing, scrollIntoViewHighlight, showFloatOnAnchorTop, showFloatOnHighlight, } from "./EditorPluginHighlight.utils";
export const INSERT_INLINE_HIGHLIGHT = createCommand("INSERT_INLINE_HIGHLIGHT");
// Note:
// - Currently there is lot of code duplication between the types of highlight, for example
//   risk and component. This separation is for initial discovery of how varied the highlights
//   would need to be. Once the patterns are discovered, the common code must be united and
//   updated.
// - For now, even the CardHighlight are separated for each type - for example EditorCardHighlightRisk
//   and EditorCardHighlightComponent. But there could be a better way to keep things together
// - MarkNodeMap and Editor could potentially be two props we can think if required as a context. But passing
//   as props is explicit and clear.
// TODO: When highlights are updated, figure out a way to identify the deviation between old highlights
//       and new highlights, and then apply the deviation without deleting and creating all new highlights
// TODO: FIX: If the risk highlights are removed before the suggestion stream is completed, then it throws
//       an error that the draft[id] is not found, because in highlights its not able to find the highlight id
//       Need to fix this to allow highlight toggle.
const EditorPluginHighlight = ({ highlightOffsetsAndDataComponent, highlightOffsetsAndDataSubComponent, setEditorFloatDataOnId, unsetEditorFloatDataOnId, anchorElement = document.body, superAnchorElement = document.body, skipHistoryStack = false, debug, showRisk, isCompare, getSubComponentEdits, setEditorPluginHighlightHandleState, }, ref) => {
    const [editor] = useLexicalComposerContext();
    const markNodeMap = useMemo(() => {
        return new Map();
    }, []);
    const [activeIDs, setActiveIDs] = useState([]);
    const [showCardHighlightComponent, setShowCardHighlightComponent] = useState(false);
    const [showCardHighlightSubComponent, setShowCardHighlightSubComponent] = useState(false);
    const [highlights, setHighlights] = useState({});
    const [wrappedSubComponentIds, setWrappedSubComponentIds] = useState([]);
    const [showCardDocument, setShowCardDocument] = useState(false);
    const [showCardRecommend, setShowCardRecommend] = useState(false);
    const [unfocus, setUnfocus] = useState(false);
    const highlightFloatHandleRef = useRef(null);
    const highlightFloatHandleRefDR = useRef(null);
    const [showCardGenerate, setShowCardGenerate] = useState(false);
    const [editorCardGenerateProps, setEditorCardGenerateProps] = useState();
    const floatRef = useRef(null);
    const floatRefDR = useRef(null); // float ref for Document and Recommend
    const [isLock, setIsLock] = useState(false);
    const toggleUnfocus = () => setUnfocus((old) => !old);
    const lock = () => setIsLock(true);
    const unlock = () => setIsLock(false);
    const toggleEditorCardDocument = () => {
        setShowCardDocument((old) => !old);
        setShowCardRecommend(false);
        setShowCardHighlightComponent(false);
        setShowCardHighlightSubComponent(false);
        setShowCardGenerate(false);
    };
    const toggleEditorCardRecommend = () => {
        setShowCardRecommend((old) => !old);
        setShowCardDocument(false);
        setShowCardHighlightComponent(false);
        setShowCardHighlightSubComponent(false);
        setShowCardGenerate(false);
    };
    useImperativeHandle(ref, () => ({
        lock,
        unlock,
        toggleEditorCardDocument,
        toggleEditorCardRecommend,
        toggleUnfocus,
        getRecommendationCount: () => recommendComponentHighlights.length,
    }), [unfocus, highlights]);
    useEffect(() => {
        setEditorPluginHighlightHandleState({ unfocus: unfocus });
    }, [unfocus]);
    const highlightKindClassName = {
        [EditorHighlightKind.COMPONENT]: classes.highlightComponent,
        [EditorHighlightKind.SUB_COMPONENT]: classes.highlightSubComponent,
        [EditorHighlightKind.CUSTOM]: classes.highlightCustom,
    };
    const highlightImportanceClassName = {
        [EditorHighlightKind.COMPONENT]: {
            high: classes.highComponent,
            medium: classes.mediumComponent,
            low: classes.lowComponent,
        },
        [EditorHighlightKind.SUB_COMPONENT]: {
            high: classes.highSubComponent,
            medium: classes.mediumSubComponent,
            low: classes.lowSubComponent,
        },
        [EditorHighlightKind.CUSTOM]: {
            high: classes.high,
            medium: classes.medium,
            low: classes.low,
        },
    };
    const highlightKindSelectedClassName = {
        [EditorHighlightKind.COMPONENT]: classes.selectedComponent,
        [EditorHighlightKind.SUB_COMPONENT]: classes.selectedSubComponent,
        [EditorHighlightKind.CUSTOM]: classes.selectedCustom,
    };
    const firstMarkNodeClassName = classes.firstMarkNode;
    const reset = (floatElement, exempt) => {
        if (floatElement) {
            hideFloat(floatElement);
        }
        if (!exempt?.includes("HighlightComponent")) {
            setShowCardHighlightComponent(false);
        }
        if (!exempt?.includes("HighlightSubComponent")) {
            setShowCardHighlightSubComponent(false);
        }
        if (!exempt?.includes("Document")) {
            setShowCardDocument(false);
        }
        if (!exempt?.includes("Recommend")) {
            setShowCardRecommend(false);
        }
        setEditorCardGenerateProps(undefined);
    };
    const updateFloat = useCallback(() => {
        const floatElement = floatRef.current;
        const floatElementDR = floatRefDR.current;
        if (floatElementDR && showCardRecommend) {
            showFloatOnAnchorTop(floatElementDR, superAnchorElement, 0, superAnchorElement.getBoundingClientRect().width -
                floatElementDR.getBoundingClientRect().width, false);
            // So that when the Recommend card selects the highlight start, the full component highlight
            // is selected
            setShowCardHighlightComponent(true);
        }
        else if (floatElementDR && showCardDocument) {
            showFloatOnAnchorTop(floatElementDR, superAnchorElement, 0, superAnchorElement.getBoundingClientRect().width -
                floatElementDR.getBoundingClientRect().width, false);
            // So that when the document card selects the highlight start, the full component highlight
            // is selected
            setShowCardHighlightComponent(true);
        }
        else if (floatElement && activeIDs.length !== 0) {
            // Incase of component and sub-component, the activeIDs[0] would be the ID of the
            // component, and the activeIDs[1] would be the ID of the sub-component. We need
            // the floating box to come on the sub-component when the user has clicked on the
            // sub-component otherwise, over the component.
            const activeId = activeIDs.length === 1 ? activeIDs[0] : activeIDs[1];
            showFloatOnHighlight(activeId, markNodeMap, editor, floatElement, anchorElement);
        }
        else {
            reset(floatElement);
        }
    }, [
        activeIDs,
        editor,
        markNodeMap,
        highlights,
        showCardDocument,
        showCardRecommend,
    ]);
    const deleteHighlights = useCallback((ids) => {
        editor.update(() => {
            for (const id of ids) {
                $unmarkHighlight(id, markNodeMap);
                setHighlights((old) => produce(old, (draft) => {
                    delete draft[id];
                }));
            }
        }, skipHistoryStack ? { tag: "historic" } : {});
    }, [editor, markNodeMap, highlights, isLock]);
    const addCustomHighlight = useCallback(() => {
        const id = getNewId();
        editor.update(() => {
            const selection = $getSelection();
            const text = selection?.getTextContent() || "";
            let contextText;
            if ($isRangeSelection(selection)) {
                const contextSelection = $getContextSelectionFromSelection(selection);
                contextText = contextSelection?.getTextContent() || "";
            }
            else {
                contextText = "";
            }
            const highlight = getCustomHighlight(id, text, contextText);
            setHighlights((highlights) => ({ ...highlights, id: highlight }));
            if ($isRangeSelection(selection)) {
                $markHighlight(selection, id);
            }
        }, skipHistoryStack ? { tag: "historic" } : {});
    }, [editor]);
    const addComponentHighlight = useCallback((startOffset, endOffset, data, actions) => {
        const id = getNewId();
        editor.update(() => {
            const selection = $getSelectionFromOffsets(startOffset, endOffset);
            const text = selection?.getTextContent() || "";
            let contextText;
            if ($isRangeSelection(selection)) {
                const contextSelection = $getContextSelectionFromSelection(selection);
                contextText = contextSelection?.getTextContent() || "";
            }
            else {
                contextText = "";
            }
            const highlight = getComponentHighlight(id, text, contextText, data);
            setHighlights((highlights) => ({ ...highlights, [id]: highlight }));
            if ($isRangeSelection(selection)) {
                $markHighlight(selection, id, true);
            }
        }, skipHistoryStack ? { tag: "historic" } : {});
    }, []);
    const addSubComponentHighlight = useCallback((startOffset, endOffset, data, actions) => {
        const id = getNewId();
        editor.update(() => {
            const selection = $getSelectionFromOffsets(startOffset, endOffset);
            const text = selection?.getTextContent() || "";
            let contextText;
            if ($isRangeSelection(selection)) {
                const contextSelection = $getContextSelectionFromSelection(selection);
                contextText = contextSelection?.getTextContent() || "";
            }
            else {
                contextText = "";
            }
            const highlight = getSubComponentHighlight(id, text, contextText, data, actions);
            setHighlights((highlights) => ({ ...highlights, [id]: highlight }));
            if ($isRangeSelection(selection)) {
                $markHighlight(selection, id, true);
            }
        }, skipHistoryStack ? { tag: "historic" } : {});
    }, []);
    const onClickGoToSubComponent = (id) => {
        editor.update(() => {
            $selectHighlightStart(id, markNodeMap);
        });
        setTimeout(() => {
            scrollIntoViewHighlight(id, markNodeMap, editor);
            setShowCardHighlightComponent(false);
            setShowCardHighlightSubComponent(true);
        }, 1);
    };
    const onClickGoToComponent = (id) => {
        editor.update(() => {
            $selectHighlightStart(id, markNodeMap);
        });
        setTimeout(() => {
            scrollIntoViewHighlight(id, markNodeMap, editor);
            setShowCardDocument(false);
            setShowCardRecommend(false);
            setShowCardHighlightComponent(true);
            setShowCardHighlightSubComponent(false);
        }, 1);
    };
    useEffect(() => {
        if (editorCardGenerateProps) {
            setShowCardGenerate(true);
        }
        else if (showCardGenerate) {
            setShowCardGenerate(false);
        }
    }, [editorCardGenerateProps]);
    useEffect(() => {
        const componentHighlightIds = activeIDs.filter((id) => highlights[id].kind === EditorHighlightKind.COMPONENT);
        if (componentHighlightIds.length > 0) {
            editor.getEditorState().read(() => {
                let wrappedIds = [];
                for (const id of componentHighlightIds) {
                    wrappedIds = wrappedIds.concat(Array.from($getWrappedIds(id, markNodeMap)));
                }
                setWrappedSubComponentIds(Array.from(new Set(wrappedIds)).filter((id) => highlights[id].kind === EditorHighlightKind.SUB_COMPONENT));
            });
        }
    }, [activeIDs, highlights, isLock]);
    // TODO: When select state component is added, translate the following
    //       effect to work with the array of IDs, such that based on the preference
    //       and current highlights in active ids, a new array - open ids - is set
    //       which is used to render the highlights (whether component or sub component)
    //       and also sets the selected.
    useEffect(() => {
        // If any component highlight and no sub component highlight
        const someComponentHighlights = activeIDs.some((id) => highlights[id].kind === EditorHighlightKind.COMPONENT);
        const someSubComponentHighlights = activeIDs.some((id) => highlights[id].kind === EditorHighlightKind.SUB_COMPONENT);
        setShowCardGenerate(false);
        if (someComponentHighlights && !someSubComponentHighlights) {
            setShowCardHighlightComponent(true);
            setShowCardHighlightSubComponent(false);
        }
        else if (!someComponentHighlights && someSubComponentHighlights) {
            setShowCardHighlightComponent(false);
            setShowCardHighlightSubComponent(true);
        }
        else if (!someComponentHighlights && !someSubComponentHighlights) {
            setShowCardHighlightComponent(false);
            setShowCardHighlightSubComponent(false);
        }
        else if (someComponentHighlights &&
            someSubComponentHighlights &&
            !showCardHighlightComponent &&
            !showCardHighlightSubComponent) {
            setShowCardHighlightComponent(false);
            setShowCardHighlightSubComponent(true);
        }
        else if (
        // TODO: Fix: Need to double click on goToComponent when the component and subcomponent
        //       starts together, because then on the second time, activeIds doesn't change
        //       and from goToComponent, the showCardHighlightComponent(true) is set, other
        //       wise even on goToComponent, when the active ids change, the following
        //       overrides it.
        someComponentHighlights &&
            someSubComponentHighlights &&
            showCardHighlightComponent &&
            !showCardHighlightSubComponent) {
            setShowCardHighlightComponent(false);
            setShowCardHighlightSubComponent(true);
        }
    }, [activeIDs]);
    // For Float Update Position
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            updateFloat();
        });
        if (floatRef.current) {
            resizeObserver.observe(floatRef.current);
        }
        if (floatRefDR.current) {
            resizeObserver.observe(floatRefDR.current);
        }
        return () => resizeObserver.disconnect();
    }, [activeIDs, editor, markNodeMap, showCardDocument, showCardRecommend]);
    useEffect(() => {
        if (!isLock) {
            setActiveIDs([]); // reset active ids
            if (highlights) {
                const currentHighlightIds = Object.values(highlights)
                    .filter((h) => [
                    EditorHighlightKind.COMPONENT,
                    EditorHighlightKind.SUB_COMPONENT,
                ].includes(h.kind))
                    .map((h) => h.id);
                deleteHighlights(currentHighlightIds);
            }
            if (highlightOffsetsAndDataSubComponent) {
                highlightOffsetsAndDataSubComponent.map(({ startOffset, endOffset, data, actions }) => {
                    addSubComponentHighlight(startOffset, endOffset, data, actions);
                });
            }
            if (highlightOffsetsAndDataComponent) {
                highlightOffsetsAndDataComponent.map(({ startOffset, endOffset, data, actions }) => {
                    addComponentHighlight(startOffset, endOffset, data, actions);
                });
            }
        }
    }, [highlightOffsetsAndDataComponent, highlightOffsetsAndDataSubComponent]);
    // Add classNames to mark nodes. If a mark node is part of multiple
    // highlights, then all classNames applicable are applied.
    // As of now CSS Cascading would decide which styles would apply
    useEffect(() => {
        for (const highlight of Object.values(highlights)) {
            const id = highlight.id;
            const kind = highlight.kind;
            const className = highlightKindClassName[kind];
            const keys = markNodeMap.get(id);
            if (keys) {
                for (const [idx, key] of Array.from(keys).entries()) {
                    const element = editor.getElementByKey(key);
                    if (idx === 0 &&
                        !element?.classList.contains(firstMarkNodeClassName)) {
                        element?.classList.add(firstMarkNodeClassName);
                    }
                    if (element && !element.classList.contains(className)) {
                        element.classList.add(className);
                    }
                    if (highlight.data?.importance) {
                        const importanceClassName = highlightImportanceClassName[highlight.kind][highlight.data.importance];
                        if (!element?.classList.contains(importanceClassName)) {
                            element?.classList.add(importanceClassName);
                        }
                    }
                    if (unfocus && !element?.classList.contains(classes.unfocus)) {
                        element?.classList.add(classes.unfocus);
                    }
                    else if (!unfocus) {
                        element?.classList.remove(classes.unfocus);
                    }
                }
            }
        }
    }, [highlights, markNodeMap, isLock, unfocus]);
    useEffect(() => {
        const changedElems = [];
        const selectIds = getSelectIds(activeIDs, highlights, showCardHighlightComponent);
        for (let i = 0; i < selectIds.length; i++) {
            const id = selectIds[i];
            const keys = markNodeMap.get(id);
            if (keys) {
                for (const key of keys) {
                    const element = editor.getElementByKey(key);
                    if (element !== null) {
                        element.classList.add(highlightKindSelectedClassName[highlights[id].kind]);
                        changedElems.push(element);
                    }
                }
            }
        }
        return () => {
            for (let i = 0; i < changedElems.length; i++) {
                const changedElem = changedElems[i];
                Object.values(highlightKindSelectedClassName).map((c) => changedElem.classList.remove(c));
            }
        };
    }, [
        activeIDs,
        editor,
        markNodeMap,
        highlights,
        isLock,
        unfocus,
        showCardHighlightComponent,
    ]);
    useEffect(() => {
        const markNodeKeysToIDs = new Map();
        return mergeRegister(registerNestedElementResolver(editor, MarkNode, (from) => {
            return $createMarkNode(from.getIDs());
        }, (from, to) => {
            const ids = from.getIDs();
            ids.forEach((id) => {
                to.addID(id);
            });
        }), editor.registerMutationListener(MarkNode, (mutations) => {
            editor.getEditorState().read(() => {
                for (const [key, mutation] of mutations) {
                    const node = $getNodeByKey(key);
                    let ids = [];
                    if (mutation === "destroyed") {
                        ids = markNodeKeysToIDs.get(key) || [];
                    }
                    else if ($isMarkNode(node)) {
                        ids = node.getIDs();
                    }
                    for (let i = 0; i < ids.length; i++) {
                        const id = ids[i];
                        let markNodeKeys = markNodeMap.get(id);
                        markNodeKeysToIDs.set(key, ids);
                        if (mutation === "destroyed") {
                            if (markNodeKeys !== undefined) {
                                markNodeKeys.delete(key);
                                if (markNodeKeys.size === 0) {
                                    markNodeMap.delete(id);
                                }
                            }
                        }
                        else {
                            if (markNodeKeys === undefined) {
                                markNodeKeys = new Set();
                                markNodeMap.set(id, markNodeKeys);
                            }
                            if (!markNodeKeys.has(key)) {
                                markNodeKeys.add(key);
                            }
                        }
                    }
                }
            });
        }), editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const selection = $getSelection();
                let hasActiveIds = false;
                if ($isRangeSelection(selection)) {
                    if (selection.getTextContent() !== "" && floatRef.current) {
                        reset(floatRef.current, ["Document", "Recommend"]);
                    }
                    const anchorNode = selection.anchor.getNode();
                    if ($isTextNode(anchorNode)) {
                        const markIDs = $getMarkIDs(anchorNode, selection.anchor.offset);
                        if (markIDs !== null) {
                            setActiveIDs(markIDs);
                            hasActiveIds = true;
                        }
                    }
                }
                if (!hasActiveIds) {
                    setActiveIDs((_activeIds) => _activeIds.length === 0 ? _activeIds : []);
                }
            });
        }), editor.registerCommand(INSERT_INLINE_HIGHLIGHT, () => {
            const domSelection = window.getSelection();
            if (domSelection !== null) {
                domSelection.removeAllRanges();
            }
            addCustomHighlight();
            return true;
        }, COMMAND_PRIORITY_EDITOR));
    }, [editor, highlights, isLock]);
    const recommendComponentHighlights = Object.values(highlights).filter((h) => h.kind === EditorHighlightKind.COMPONENT &&
        (h.data
            ?.getSuggestion ||
            h.data
                ?.isRecommendChange));
    const documentComponentHighlights = Object.values(highlights).filter((h) => h.kind === EditorHighlightKind.COMPONENT);
    const componentHighlights = activeIDs
        .map((id) => highlights[id])
        .filter((h) => h.kind === EditorHighlightKind.COMPONENT);
    const componentHighlightsWithSameMessage = componentHighlights.flatMap((ch) => Object.values(highlights).filter((h) => h.data?.message === ch.data?.message));
    const EditorFloatPropsWithRef = useMemo(() => ({
        ref: floatRef,
        isShadow: true,
        anchorElement: anchorElement,
        isBackgroundTransparent: false,
        delayTransitionSeconds: 0,
        zIndex: 2,
        children: (React.createElement(React.Fragment, null,
            showCardHighlightComponent &&
                !showCardDocument &&
                !showCardRecommend &&
                !showCardGenerate && (React.createElement(EditorCardHighlightComponent, { highlights: componentHighlights, subComponentHighlights: wrappedSubComponentIds.map((id) => highlights[id]), editor: editor, onClickGoToSubComponent: onClickGoToSubComponent, onClose: () => reset(floatRef.current), markNodeMap: markNodeMap, setEditorCardGenerateProps: setEditorCardGenerateProps, showRisk: showRisk, isCompare: isCompare, getSubComponentEdits: getSubComponentEdits, selfFloatElement: floatRef.current ? floatRef.current : undefined, componentHighlightsWithSameMessage: componentHighlightsWithSameMessage, onClickGoToComponent: onClickGoToComponent, initialExpand: false })),
            showCardHighlightSubComponent &&
                !showCardDocument &&
                !showCardRecommend &&
                !showCardHighlightComponent &&
                !showCardGenerate && (React.createElement(EditorCardHighlightSubComponent, { highlights: activeIDs
                    .map((id) => highlights[id])
                    .filter((h) => h.kind === EditorHighlightKind.SUB_COMPONENT), onClickGoToComponent: () => onClickGoToComponent(activeIDs
                    .map((id) => highlights[id])
                    .filter((h) => h.kind === EditorHighlightKind.COMPONENT)[0].id), componentHighlights: activeIDs
                    .map((id) => highlights[id])
                    .filter((h) => h.kind === EditorHighlightKind.COMPONENT), showRisk: showRisk, editor: editor, selfFloatElement: floatRef.current ? floatRef.current : undefined, onClose: () => reset(floatRef.current), markNodeMap: markNodeMap })),
            showCardGenerate && editorCardGenerateProps && (React.createElement(EditorCardGenerate, { ...editorCardGenerateProps, onClose: () => reset(floatRef.current) })))),
    }), [
        editor,
        highlights,
        floatRef,
        activeIDs,
        showCardHighlightComponent,
        showCardHighlightSubComponent,
        showCardDocument,
        showCardRecommend,
        wrappedSubComponentIds,
        showCardGenerate,
        editorCardGenerateProps,
        isLock,
    ]);
    const EditorFloatPropsWithRefDR = useMemo(() => ({
        ref: floatRefDR,
        anchorElement: superAnchorElement,
        delayTransitionSeconds: 0,
        zIndex: 2,
        children: (React.createElement(React.Fragment, null,
            showCardRecommend && (React.createElement(EditorCardRecommend, { componentHighlights: recommendComponentHighlights, editor: editor, onClose: () => reset(floatRefDR.current), markNodeMap: markNodeMap, selfFloatRef: floatRefDR, anchorElement: anchorElement, setEditorFloatDataOnId: setEditorFloatDataOnId, unsetEditorFloatDataOnId: unsetEditorFloatDataOnId, onClickGoToComponent: onClickGoToComponent, editorPluginHighlightHandleRef: ref })),
            showCardDocument && !showCardRecommend && (React.createElement(EditorCardDocument, { componentHighlights: documentComponentHighlights, selfFloatRef: floatRefDR, editor: editor, onClose: () => reset(floatRefDR.current), markNodeMap: markNodeMap, anchorElement: anchorElement, setEditorFloatDataOnId: setEditorFloatDataOnId, unsetEditorFloatDataOnId: unsetEditorFloatDataOnId, onClickGoToComponent: onClickGoToComponent, editorPluginHighlightHandleRef: ref, showRisk: showRisk, isCompare: isCompare })))),
    }), [
        editor,
        highlights,
        floatRef,
        activeIDs,
        showCardHighlightComponent,
        showCardHighlightSubComponent,
        showCardDocument,
        showCardRecommend,
        wrappedSubComponentIds,
        showCardGenerate,
        editorCardGenerateProps,
        isLock,
    ]);
    useEffect(() => {
        setEditorFloatDataOnId?.("highlight", [EditorFloatPropsWithRef], highlightFloatHandleRef);
    }, [EditorFloatPropsWithRef]);
    useEffect(() => {
        setEditorFloatDataOnId?.("highlightDR", [EditorFloatPropsWithRefDR], highlightFloatHandleRefDR);
    }, [EditorFloatPropsWithRefDR]);
    // ===========================
    // EXPERIMENT WITH MULTI-FLOAT
    // ===========================
    // TODO: Look at the implementation for multi-float here and in the EditorCardRecommend
    //       and EditorCardDocument and isolate the common code, and create a hook
    const chs = Object.values(highlights).filter((h) => h.kind === EditorHighlightKind.COMPONENT &&
        !isHighlightComponentMissing(h));
    // to cause trigger re-render when required
    const [counter, setCounter] = useState(0);
    const refs = useRef({});
    const floatsHandleRef = useRef(null);
    const allFloats = chs.map((h, idx) => ({
        ref: (r) => {
            refs.current[h.id] = r;
            // trigger rerender when the ref is set
            setCounter((c) => c + 1);
        },
        anchorElement: anchorElement,
        children: (React.createElement(React.Fragment, null,
            React.createElement(EditorCardHighlightComponent, { highlights: [h], editor: editor, onClose: () => floatsHandleRef.current?.resolveAndNext(idx, {
                    mode: EditorCardResolvedMode.IGNORED,
                    onClickRevert: () => floatsHandleRef.current?.unresolve(idx),
                }), markNodeMap: markNodeMap, setEditorCardGenerateProps: setEditorCardGenerateProps }),
            React.createElement(Button, { text: "next via handleRef", onClick: () => floatsHandleRef.current?.next() }),
            React.createElement(Button, { text: "prev via handleRef", onClick: () => floatsHandleRef.current?.prev() }))),
    }));
    const showAll = () => {
        lock();
        setEditorFloatDataOnId?.("highlight", allFloats, floatsHandleRef, () => {
            unsetEditorFloatDataOnId?.("highlight");
            unlock();
        });
    };
    useEffect(() => {
        if (refs.current) {
            Object.entries(refs.current).map(([id, floatElement]) => {
                if (floatElement) {
                    showFloatOnHighlight(id, markNodeMap, editor, floatElement, anchorElement);
                }
            });
        }
    }, [counter]);
    // ===============================
    // END EXPERIMENT WITH MULTI-FLOAT
    // ===============================
    return (React.createElement(React.Fragment, null,
        !setEditorFloatDataOnId && React.createElement(EditorFloat, { ...EditorFloatPropsWithRef }),
        debug && (React.createElement(React.Fragment, null,
            React.createElement(Button, { isBlock: false, size: "sm", variant: "tertiary", text: "Manual Highlight", onClick: addCustomHighlight }),
            React.createElement(Button, { isBlock: false, size: "sm", variant: "tertiary", text: "Clauses", onClick: toggleEditorCardDocument }),
            React.createElement(Button, { isBlock: false, size: "sm", variant: "tertiary", text: "Recommendations", onClick: toggleEditorCardRecommend }),
            React.createElement(Button, { text: "Show all Component highlights", isBlock: false, size: "sm", variant: "tertiary", onClick: showAll }),
            React.createElement(Button, { isBlock: false, size: "sm", variant: "tertiary", text: isLock ? "Unlock" : "Lock", onClick: isLock ? unlock : lock }),
            React.createElement(Text, null,
                "Current Status isLock: ",
                isLock ? "True" : "False",
                " "),
            React.createElement(Button, { isBlock: false, size: "sm", variant: "tertiary", text: "Unfocus", onClick: toggleUnfocus }),
            React.createElement(Text, null,
                "Current Status unfocus: ",
                unfocus ? "Unfocus" : "Not Unfocus")))));
};
export default forwardRef(EditorPluginHighlight);
