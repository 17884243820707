import React from "react";
import MailWarningIcon from "../../../static/icons/mail-warning.svg";
import Button from "../../Button/ButtonAligned/Button";
import Text from "../../Text/TextAligned/Text";
import BaseAuthContent from "../AuthContentBase";
const AuthConfirmEmail = ({ onClickLogin, onClickBack, }) => {
    return (React.createElement(BaseAuthContent, { onClickBack: onClickBack, title: "Verify Email", iconProps: {
            IC: MailWarningIcon,
            width: 40,
            height: 40,
        }, subTitle: "Thank you for Signing Up! An email verification link is sent your email ID.", footer: React.createElement(React.Fragment, null,
            React.createElement(Button, { text: "Login", textColor: "red-primary", variant: "ghost", size: "xs", onClick: onClickLogin }),
            React.createElement(Text, { variant: "body-xs", color: "grey-dark-60" }, "Into A Different Account?")) }));
};
export default AuthConfirmEmail;
