/** @format */
import React from "react";
import "../../../styles/base.scss";
import "./Text.scss";
import colorMapping from "../../../constants/colorMapping";
import displayMapping from "../../../constants/displayMapping";
import fontSizeMapping from "../../../constants/fontSizeMapping";
import fontStyleMapping from "../../../constants/fontStyleMapping";
import fontWeightMapping from "../../../constants/fontWeightMapping";
import lineHeightMapping from "../../../constants/lineHeightMapping";
import textAlignMapping from "../../../constants/textAlignMapping";
import textDecorationMapping from "../../../constants/textDecorationMapping";
import { getTooltipClassNames } from "../../Other/Tooltip/Tooltip.utils";
function Text({ children, align = "", weight = "", size = "", color = "", display = "", className = "", decoration = "", fontStyle = "", lineHeight = "", ellipsis = false, innerRef = undefined, onClick = () => null, preserveNewlines = false, tooltipProps, }) {
    const textClasses = `
    ${fontSizeMapping[size]}
    ${textAlignMapping[align]}
    ${colorMapping[color]}
    ${fontWeightMapping[weight]}
    ${textDecorationMapping[decoration]}
    ${fontStyleMapping[fontStyle]}
    ${lineHeightMapping[lineHeight]}
    ${displayMapping[display]}
    ${ellipsis ? "text-snip-ellipsis" : ""}
    ${preserveNewlines ? "text-whitespace-pre-line" : ""}
    ${tooltipProps
        ? getTooltipClassNames({
            ...tooltipProps,
        })
        : ""}
    ${className}`;
    return (React.createElement("span", { className: textClasses, "data-tooltip": tooltipProps?.message, onClick: onClick, ref: innerRef }, children));
}
export default Text;
