// This can potentially be a shared function if also required in other components.
// In which case, can move this to utils/utils.ts
export const deleteUndefinedProps = ({ fill, width, height, stroke, viewBox, opacity, }) => {
    const props = {
        width,
        height,
        stroke,
        fill,
        viewBox,
        opacity,
    };
    Object.keys(props).forEach((key) => {
        if (props[key] == undefined) {
            delete props[key];
        }
    });
    return props;
};
export default { getRefinedProps: deleteUndefinedProps };
