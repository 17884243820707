export default {
    "0": "border--radius-0",
    "4": "border--radius-4",
    "8": "border--radius-8",
    "16": "border--radius-16",
    "1em": "border--radius-1em",
    "1.5em": "border--radius-1p5",
    "0.5em": "border--radius-0p5",
    "": "",
};
