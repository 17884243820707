export default {
    "3": "font-size-3",
    "2": "font-size-2",
    "1.5": "font-size-1p5",
    "1.2": "font-size-1p2",
    large: "font-size-1p2",
    "1": "font-size-1",
    medium: "font-size-1",
    "0.9": "font-size-0p9",
    "0.875": "font-size-0p875",
    "0.8125": "font-size-0p8125",
    "0.8": "font-size-0p8",
    "0.5": "font-size-0p5",
    "0.75": "font-size-0p75",
    "0.625": "font-size-0p625",
    small: "font-size-0p875",
    "0.375": "font-size-0p375",
    "": "",
};
