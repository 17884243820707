import React, { forwardRef, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./CardRiskDetail.scss";
import { useCardStateImport } from "../../../hooks";
import BadgeCoverage from "../../Badge/BadgeCoverage";
import BadgeRisk from "../../Badge/BadgeRisk";
import ButtonAligned from "../../Button/ButtonAligned";
import Button from "../../Button/ButtonRevamped/Button";
import { CDRT } from "../../Canvas/Canvas/Canvas.types";
import { useCanvasData, useCanvasDataDispatch, useOnCanvasDataChange, } from "../../Canvas/Canvas/CanvasDataProvider";
import { isCardAvailable } from "../../Canvas/Canvas/utils/Canvas.utils.basic";
import { getStructureIdOfEntity } from "../../Canvas/Canvas/utils/Canvas.utils.getter_and_setter";
import { findElementsWithCategory, findLinkFromElementWithCategory, scrollElementIntoView, } from "../../Canvas/Canvas/utils/Canvas.utils.misc";
import Spinner from "../../Loader/Spinner";
import CopyToClipboard from "../../Other/CopyToClipboard";
import HeaderIconAndTitle from "../../Other/HeaderIconAndTitle";
import ReferenceFromDocumentAccordion from "../../Other/ReferenceFromDocumentAccordion";
import RiskDetailAccordion from "../../Other/RiskDetailAccordion";
import Text from "../../Text/Text";
import TextAligned from "../../Text/TextAligned";
import Card from "../Card/Card";
const CardRiskDetail = ({ title, Icon, section, risk, coverage = null, getDescription, description, suggestion, presentAttributes, missingAttributes, commonAttributes, draftElement, documentReferenceText, riskDetailItems, loading, isAllowEditDocument, ...cardBaseProps }, ref) => {
    const [dataProps, setDataProps] = useState({
        title: title,
        Icon: Icon,
        risk: risk,
        section: section,
        coverage: coverage || null,
        getDescription: getDescription,
        description: description,
        suggestion: suggestion,
        presentAttributes: presentAttributes,
        missingAttributes: missingAttributes,
        commonAttributes: commonAttributes,
        documentReferenceText: documentReferenceText,
        riskDetailItems: riskDetailItems,
        loading: loading,
        isAllowEditDocument: isAllowEditDocument,
    });
    const { elementId } = cardBaseProps;
    const [descriptionStream, setDescriptionStream] = useState("");
    const [linkFromState, ,] = useCardStateImport({
        elementId,
    });
    const [launchedEditor, setLaunchedEditor] = useState(false);
    const canvasData = useCanvasData();
    const dispatch = useCanvasDataDispatch();
    const onCanvasDataChange = useOnCanvasDataChange();
    // Update props from linked card
    useEffect(() => {
        if (Object.keys(linkFromState).length > 0) {
            setDataProps({ ...linkFromState });
            setDescriptionStream("");
            linkFromState.getDescription?.(linkFromState.section, setDescriptionStream, () => null);
        }
    }, [linkFromState]);
    // If getDescription is given, generate the stream
    useEffect(() => {
        getDescription?.(section, setDescriptionStream, () => null);
    }, []);
    useEffect(() => {
        if (elementId && launchedEditor && canvasData) {
            // close the current card
            setTimeout(() => dispatch?.({
                type: CDRT.REMOVE_ELEMENT,
                data: {
                    onCanvasDataChange,
                    elementId: elementId,
                },
            }), 200);
            // hide pdf
            const pdfElements = findElementsWithCategory(canvasData, "pdf", false);
            // hide main compare card
            console.log("closing pdf element", pdfElements);
            if (pdfElements) {
                pdfElements.map((pe) => setTimeout(() => dispatch?.({
                    type: CDRT.SET_ELEMENT_HIDDEN,
                    data: {
                        onCanvasDataChange,
                        elementId: pe,
                        value: true,
                    },
                }), 400));
            }
            const riskElements = findElementsWithCategory(canvasData, "risk", false);
            // hide main compare card
            if (riskElements) {
                console.log("closing risk element", riskElements);
                riskElements.map((re) => {
                    setTimeout(() => dispatch?.({
                        type: CDRT.SET_ELEMENT_HIDDEN,
                        data: {
                            onCanvasDataChange,
                            elementId: re,
                            value: true,
                        },
                    }), 500);
                });
            }
        }
    }, [linkFromState, launchedEditor]);
    // If draftElement is given, view suggestion button would appear. This handler
    // will be used onClick.
    const viewDraftHandler = () => {
        if (elementId && canvasData) {
            const targetElementId = findLinkFromElementWithCategory(canvasData, elementId, "draft");
            if (!targetElementId && draftElement) {
                const draftElementId = uuidv4();
                dispatch?.({
                    type: CDRT.ADD_ELEMENT,
                    data: {
                        idGenerator: () => draftElementId,
                        onCanvasDataChange: onCanvasDataChange,
                        structureId: getStructureIdOfEntity(canvasData, elementId),
                        element: draftElement,
                    },
                });
                setTimeout(() => {
                    scrollElementIntoView(draftElementId);
                }, 100);
            }
            else {
                scrollElementIntoView(targetElementId);
            }
        }
    };
    const handleEditDocument = () => {
        if (canvasData !== null) {
            const targetElementIds = findElementsWithCategory(canvasData, "editor", true);
            if (targetElementIds.length > 0) {
                targetElementIds.map((te) => {
                    if (isCardAvailable(canvasData, te)) {
                        dispatch?.({
                            type: CDRT.LAUNCH_AVAILABLE_ELEMENT,
                            data: {
                                availableElementId: te,
                                onCanvasDataChange: onCanvasDataChange,
                            },
                        });
                    }
                });
            }
        }
        setLaunchedEditor(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { headerChildren: React.createElement(HeaderIconAndTitle, { title: dataProps.title, iconProps: { IC: dataProps.Icon || Icon }, isLoading: dataProps.loading }), footerChildren: isAllowEditDocument && (React.createElement("div", { className: "card-risk-detail__footer" },
                React.createElement(ButtonAligned, { text: "Edit Document", onClick: handleEditDocument, isFullWidth: true }))), className: "card-risk-detail", bodyHeightFill: true, bodyNoPad: true, ...cardBaseProps, ref: ref },
            React.createElement("div", { className: "card-risk-detail__badges" },
                React.createElement(BadgeRisk, { className: "card-risk-detail__badges__risk", percent: dataProps.risk, displayPercent: false }),
                dataProps.coverage && React.createElement(BadgeCoverage, { percent: dataProps.coverage })),
            ((dataProps.presentAttributes &&
                dataProps.presentAttributes.length > 0) ||
                (dataProps.missingAttributes &&
                    dataProps.missingAttributes.length > 0) ||
                (dataProps.commonAttributes &&
                    dataProps.commonAttributes.length > 0)) && (React.createElement(TextAligned, { className: "card-risk-detail__badges__attribute-heading", variant: "body-sm/semibold", isBlock: true }, "Sub-Clauses")),
            dataProps.presentAttributes && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "card-risk-detail__badges" }, dataProps.presentAttributes.map((e, i) => (React.createElement(Button, { className: "card-risk-detail__badges__present-attribute", borderRadius: "16", key: i, tooltipProps: {
                        message: "New in Clause",
                        delayInSeconds: 0,
                    }, defaultCursor: true },
                    React.createElement(Text, { ellipsis: true }, e))))))),
            dataProps.missingAttributes && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "card-risk-detail__badges" }, dataProps.missingAttributes.map((e, i) => (React.createElement(Button, { className: "card-risk-detail__badges__missing-attribute", borderRadius: "16", key: i, tooltipProps: {
                        message: "Missing in Clause",
                        delayInSeconds: 0,
                    }, defaultCursor: true },
                    React.createElement(Text, { ellipsis: true }, e))))))),
            dataProps.commonAttributes && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "card-risk-detail__badges" }, dataProps.commonAttributes.map((e, i) => (React.createElement(Button, { className: "card-risk-detail__badges__common-attribute", borderRadius: "16", key: i, tooltipProps: {
                        message: "Present in Clause",
                        delayInSeconds: 0,
                    }, defaultCursor: true },
                    React.createElement(Text, { ellipsis: true }, e))))))),
            React.createElement(Text, { className: "card-risk-detail__description", display: "block", color: "grey-dark", size: "0.875", lineHeight: "1.6", preserveNewlines: true },
                dataProps.description,
                descriptionStream),
            loading && React.createElement(Spinner, { size: "small", position: "center" }),
            dataProps.suggestion && (React.createElement("div", { className: "card-risk-detail__suggestion" },
                React.createElement("div", { className: "card-risk-detail__suggestion__title" },
                    React.createElement(Text, { size: "0.9", weight: "500" }, "Suggestion"),
                    React.createElement(CopyToClipboard, { height: "1.5em", width: "1.5em", text: dataProps.suggestion })),
                React.createElement(Text, { className: "card-risk-detail__suggestion__text", display: "block", color: "grey-dark", size: "0.875", lineHeight: "1.6" }, dataProps.suggestion))),
            draftElement && (React.createElement(Button, { className: "margin-left-1 margin-bottom-1", outline: true, onClick: viewDraftHandler, variant: "primary" }, "View Suggestion")),
            dataProps.documentReferenceText && (React.createElement(ReferenceFromDocumentAccordion, { className: "padding-bottom-1", text: dataProps.documentReferenceText })),
            dataProps.riskDetailItems && (React.createElement(RiskDetailAccordion, { riskDetailItems: dataProps.riskDetailItems })))));
};
export default forwardRef(CardRiskDetail);
