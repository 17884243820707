import React from "react";
import ShortTextIcon from "../../../static/icons/short-text.svg";
import "./ListItemDocumentTypeAndTitle.scss";
import Button from "../../Button/ButtonRevamped";
import Text from "../../Text/Text";
import ListItemTitleIconBody from "../ListItemTitleIconBody";
const ListItemDocumentTypeAndTitle = ({ containerClassName = "", typeName = "", badgeColor = "#EAE0B0", documentTitle = "", }) => {
    return (React.createElement(ListItemTitleIconBody, { Icon: ShortTextIcon, title: "Title" },
        React.createElement("div", { "data-testid": "list-item-document-type-and-title", className: `list-item-document-type-and-title__container ${containerClassName}` },
            typeName && (React.createElement(Button, { isBadge: true, variant: "secondary", borderRadius: "1.5em", size: "semimedium", style: {
                    background: badgeColor,
                } },
                React.createElement(Text, { color: "black", size: "0.75", weight: "600" }, typeName))),
            React.createElement(Text, { color: "black", size: "0.75", weight: "600" }, documentTitle))));
};
export default ListItemDocumentTypeAndTitle;
