import React from "react";
import TimeIcon from "../../../static/icons/time.svg";
import "./ListItemTermAndDuration.scss";
import Button from "../../Button/ButtonRevamped";
import Text from "../../Text/Text";
import ListItemTitleIconBody from "../ListItemTitleIconBody/ListItemTitleIconBody";
const ListItemTermAndDuration = ({ containerClassName = "", badgeText = "", badgeColor = "#EFD7B9", mainText = "", pageNumber = "", }) => {
    return (React.createElement(ListItemTitleIconBody, { Icon: TimeIcon, iconProps: {
            fill: "#888888",
        }, title: "Duration of Document" },
        React.createElement("div", { "data-testid": "list-item-term-and-duration", className: `list-item-term-and-duration__container ${containerClassName}` },
            badgeText && (React.createElement(Button, { isBadge: true, variant: "secondary", borderRadius: "1em", size: "size-0.65 with 8 4 padding", style: {
                    background: badgeColor,
                } },
                React.createElement(Text, { color: "black", size: "0.75", weight: "600" }, badgeText))),
            React.createElement(Text, { color: "black", size: "0.75", weight: "600" }, mainText),
            React.createElement(Text, { color: "main-color", size: "0.75", weight: "500" },
                "Page ",
                pageNumber))));
};
export default ListItemTermAndDuration;
