/** @format */
import React from "react";
import DocOutlineIcon from "../../../static/icons/doc-outline.svg";
import PdfFileIcon from "../../../static/icons/pdf-file.svg";
import PencilIcon from "../../../static/icons/pencil.svg";
import "../../../styles/base.scss";
import "./DocumentEdit.scss";
import Button from "../../Button/ButtonRevamped";
import CheckboxYesOrNo from "../../Checkbox/CheckboxYesOrNo";
import InputText from "../../Input/InputText";
import Divider from "../../Other/Divider";
import Text from "../../Text/Text";
import FileIcon from "./FileIcon";
const DocumentEdit = ({ fileName = "Default.pdf", ocrValue = false, onToggleOcr = () => null, onUploadDocumentClick = () => null, onChangeFileName = () => null, onDocumentUploaderClicked = () => null, acceptedFileConfiguration = null, }) => {
    return (React.createElement("div", { className: "upload-document-edit" },
        React.createElement("div", { className: "upload-document-edit__section" },
            React.createElement("div", { className: "upload-document-edit__section--header" },
                React.createElement(DocOutlineIcon, null),
                React.createElement(Text, { size: "medium", weight: "semibold" }, "Upload Documents")),
            React.createElement("div", { className: "upload-document-edit__section__input-section" },
                React.createElement("div", { className: "upload-document-edit__section__input-section--icon-text" },
                    acceptedFileConfiguration?.Icon ? (React.createElement(FileIcon, { iconClassName: "upload-document-edit__section__input-section--icon", ...acceptedFileConfiguration })) : (React.createElement(PdfFileIcon, { className: "upload-document-edit__section__input-section--icon" })),
                    React.createElement(InputText, { value: fileName, fontSize: "0.9", weight: "semibold", onChange: (e) => onChangeFileName(e.target.value), className: "upload-document-edit__section__input-section--text-input" })),
                React.createElement(PencilIcon, { className: "upload-document-edit__section__input-section--pencil-icon", onClick: onDocumentUploaderClicked })),
            React.createElement(Divider, null),
            React.createElement("div", { className: "upload-document-edit__section__text-section" },
                React.createElement("span", { className: "upload-document-edit__section__text-section--text" },
                    React.createElement(Text, { weight: "semibold", size: "0.9" }, "Is this a scanned document?"),
                    React.createElement(Text, { color: "secondary", size: "0.9" }, "We will run an additional step to convert scanned images to readable text, to work with document(s) more efficiently.")),
                React.createElement(CheckboxYesOrNo, { checked: ocrValue, onChange: onToggleOcr })),
            React.createElement(Divider, null)),
        React.createElement(Button, { onClick: onUploadDocumentClick, size: "medium", variant: "primary" },
            React.createElement(Text, { weight: "semibold", size: "0.9" }, "Upload Document"))));
};
export default DocumentEdit;
