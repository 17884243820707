import React from "react";
import DocOutlineIcon from "../../../static/icons/doc-outline.svg";
import "./ReferenceFromDocumentAccordion.scss";
import Accordion from "../../Accordion/Accordion";
import Text from "../../Text/Text";
const ReferenceFromDocumentAccordion = ({ text, className = "", }) => {
    const itemsData = [
        {
            header: {
                children: (React.createElement("div", { className: "reference-from-document-accordion__header" },
                    React.createElement(DocOutlineIcon, null),
                    React.createElement(Text, { size: "0.9", lineHeight: "1.5", color: "black", className: "margin-left-1" }, "Reference From Document"))),
            },
            body: {
                children: (React.createElement("div", { className: "reference-from-document-accordion__body" },
                    React.createElement(Text, { display: "block", className: "margin-bottom-0p75", weight: "500", color: "grey-dark", size: "0.875", lineHeight: "1.5", preserveNewlines: true }, text))),
            },
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Accordion, { className: className, itemsData: itemsData })));
};
export default ReferenceFromDocumentAccordion;
