/**
 * Validates the button properties based on the variant, size, and loading state.
 * Throws errors if the combination of properties is not allowed.
 *
 * For the "ghost" variant:
 * - Throws an error if the size is "md" or "xs".
 * - Throws an error if loading is true.
 *
 * For the "tertiary", "alternative-1", and "alternative-2" variants:
 * - Throws an error if loading is true and the size is "lg", "md", or "sm".
 *
 * @param {Pick<ButtonProps, "size" | "variant" | "loading">} buttonProps - The button properties to validate.
 */
export function validateButtonVariations(buttonProps) {
    const { size = "", variant = "", loading } = buttonProps;
    if (variant === "ghost") {
        if (size === "md" || size === "xs") {
            throw new Error("Ghost variant is only valid with lg & sm size");
        }
        if (loading) {
            throw new Error("Ghost variant does not support loading");
        }
    }
    if (loading &&
        ["tertiary", "alternative-1", "alternative-2"].includes(variant) &&
        ["lg", "md", "sm"].includes(size)) {
        throw new Error(`Loading is not valid with ${variant} variant of size : ${size}`);
    }
}
/**
 * Generates text properties for a button based on its variant, size, and state.
 * This function determines the text color and variant to be used in the button's label
 * based on the button's current variant, size, whether it is disabled, and whether it is outline.
 *
 * @param {Object} params - The properties of the button.
 * @param {string} [params.variant="primary"] - The variant of the button.
 * @param {string} params.size - The size of the button.
 * @param {boolean} params.disabled - Indicates if the button is disabled.
 * @param {boolean} params.outline - Indicates if the button is outline.
 * @returns {Object} The text properties including color and variant.
 */
export function getTextPropsFromButtonProps({ variant = "primary", size, disabled, outline, }) {
    let color = "black"; // default color
    let textSize;
    // Determine the color based on the button variant and outline state
    switch (variant) {
        case "primary":
            color = outline ? "red-primary" : "white"; // TODO: check outline text color, in design it's white but in code it's red-primary
            break;
        case "ghost":
            color = outline ? "red-primary-dark-50" : "red-primary"; // TODO: check outline text color, in design it's not present
            break;
        case "alternative-1":
            color = outline ? "black" : "white"; // TODO: check outline in design, it's different
            break;
        default:
            color = "black";
    }
    // Determine the text size based on the button size and variant
    switch (size) {
        case "lg":
            textSize = variant === "ghost" ? "sm" : "md";
            break;
        case "md":
            textSize = "sm";
            break;
        default:
            textSize = "xs";
    }
    // Adjust the color if the button is disabled
    if (disabled) {
        color = "grey-dark-60";
    }
    // Form the text variant using the determined text size
    const formedTextVariant = `heading-${textSize}`;
    return { variant: formedTextVariant, color };
}
/**
 * Generates spinner properties based on the button variant and size.
 * @param {Pick<ButtonProps, "size" | "variant">} buttonProps - The button properties to extract spinner properties from.
 * @returns {SpinnerProps} The extracted spinner properties.
 */
export function getSpinnerPropsFromButtonProps({ variant, size, }) {
    // let correctedSize: SpinnerProps["size"];
    // let color: SpinnerProps["color"] = "red-primary";
    // if (size === "xs" || size === "xxs") {
    //   correctedSize = "sm";
    // } else {
    //   correctedSize = size;
    // }
    // if (variant === "tertiary") {
    //   color = "grey-dark-80";
    // } else {
    //   color = "red-primary";
    // }
    // if (variant === "primary") {
    //   correctedSize = "md";
    //   color = "grey-dark-80";
    // }
    // return { size: correctedSize, color };
    return { size: "md", color: "grey-dark-80" };
}
