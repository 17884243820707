import React from "react";
import ThumbsDownIcon from "../../../static/icons/thumbs-down.svg";
import ThumbsUpIcon from "../../../static/icons/thumbs-up.svg";
import classes from "./Feedback.module.scss";
const Feedback = ({ onUp = () => null, onDown = () => null, show = true, className = "", }) => {
    return (React.createElement("div", { className: className }, show && (React.createElement(React.Fragment, null,
        React.createElement(ThumbsUpIcon, { className: `${classes.icon} margin-right-0p5`, onClick: onUp }),
        React.createElement(ThumbsDownIcon, { className: classes.icon, onClick: onDown })))));
};
export default Feedback;
