import React, { useEffect, useMemo, useRef, useState } from "react";
import CancelIcon from "../../../static/icons/cancel.svg";
import classes from "./EditorCardDocument.module.scss";
import useChecklist from "../../../hooks/useChecklist";
import { getBadgeCompareProps } from "../../Badge/BadgeCompare/BadgeCompare";
import { getBadgeRiskProps } from "../../Badge/BadgeRiskAligned/BadgeRiskAligned";
import Button from "../../Button/ButtonAligned/Button";
import Checklist from "../../Other/Checklist";
import HeaderIconAndTitle from "../../Other/HeaderIconAndTitle";
import Text from "../../Text/TextAligned/Text";
import EditorCard from "../EditorCard/EditorCard";
import EditorCardDestroy from "../EditorCardDestroy";
import EditorCardGenerate from "../EditorCardGenerate";
import { EditorCardResolvedMode } from "../EditorCardResolved/EditorCardResolved.types";
import { $selectHighlightStart, deleteAtHighlight, getCardDestroyPropsForComponentDeleteWithGeneration, isHighlightComponentMissing, scrollIntoViewHighlight, showFloatOnHighlight, showFloatOnRootTop, } from "../EditorPluginHighlight/EditorPluginHighlight.utils";
import { insertAtSelectionInputCommand } from "../EditorPluginOffset/EditorPluginOffset.utils";
const EditorCardDocument = ({ componentHighlights, editor, markNodeMap, onClose, anchorElement = document.body, selfFloatRef, setEditorFloatDataOnId, unsetEditorFloatDataOnId, onClickGoToComponent, editorPluginHighlightHandleRef, showRisk, isCompare, getSubComponentEdits, }) => {
    // ===========================
    // EXPERIMENT WITH MULTI-FLOAT
    // ===========================
    // The base code is similar to experimental code in EditorPluginHighlight
    // TODO: Refactor based on common patterns and created hooks and function
    console.log("getSubComponentEdits in EditorCardDocument", getSubComponentEdits);
    const [counter, setCounter] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const refs = useRef({});
    const [show, setShow] = useState(true);
    const floatsHandleRef = useRef(null);
    useEffect(() => {
        selfFloatRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }, []);
    const items = useMemo(() => componentHighlights.map((h) => ({
        id: h.id,
        text: h.data?.message || "",
        selectCheckboxProps: {
            checked: !isHighlightComponentMissing(h),
        },
        badgeProps: showRisk
            ? h.data?.importance
                ? getBadgeRiskProps(h.data.importance)
                : {}
            : isCompare
                ? h.data?.relativeStatus &&
                    getBadgeCompareProps(h.data?.relativeStatus, "xs")
                : {},
        isRecommendedChange: h.data?.isRecommendChange,
        onClickOnHiddenCheckbox: isHighlightComponentMissing(h)
            ? undefined
            : () => {
                onClickGoToComponent?.(h.id);
                setTimeout(() => {
                    scrollIntoViewHighlight(h.id, markNodeMap, editor);
                }, 50);
            },
    })), [componentHighlights]);
    const { updatedItems, staged } = useChecklist(items);
    const addFloats = staged.add
        .map((id) => componentHighlights.find((h) => h.id === id))
        .map((h, idx) => ({
        ref: (r) => {
            if (h) {
                refs.current[h.id] = r;
                setCounter((c) => c + 1);
            }
        },
        anchorElement: anchorElement,
        children: (React.createElement(React.Fragment, null, h && (React.createElement(EditorCardGenerate, { key: h.id, name: h.data?.message, onClose: () => floatsHandleRef.current?.resolveAndNext(idx, {
                mode: EditorCardResolvedMode.IGNORED,
                onClickRevert: () => floatsHandleRef.current?.unresolve(idx),
            }), getGenerate: h?.data?.getSuggestion || (() => null), onClickInsert: (text) => {
                insertAtSelectionInputCommand(editor, {
                    text: text,
                    indicate: true,
                    onDoneInsert: () => {
                        setTimeout(() => {
                            floatsHandleRef.current?.resolveAndNext(idx, {
                                mode: EditorCardResolvedMode.INSERTED,
                            });
                        }, 2000);
                    },
                });
            }, noCompletionOnInsert: true, originalText: h.text })))),
    }));
    const removeFloats = staged.remove
        .map((id) => componentHighlights.find((h) => h.id === id))
        .map((h, idx) => ({
        ref: (r) => {
            if (h) {
                refs.current[h.id] = r;
                setCounter((c) => c + 1);
            }
        },
        anchorElement: anchorElement,
        children: (React.createElement(React.Fragment, null, h &&
            (!getSubComponentEdits ? (React.createElement(EditorCardDestroy, { key: h.id, name: h.data?.message, onClose: 
                // addFloats.length because resolve takes overall index
                // THIS SAME CALLBACK IS COPIED BELOW
                () => floatsHandleRef.current?.resolveAndNext(addFloats.length + idx, {
                    mode: EditorCardResolvedMode.IGNORED,
                    onClickRevert: () => floatsHandleRef.current?.unresolve(addFloats.length + idx),
                }), 
                // text={h.text}
                onClickDelete: () => {
                    deleteAtHighlight(h.id, markNodeMap, editor);
                    setTimeout(() => {
                        floatsHandleRef.current?.resolveAndNext(addFloats.length + idx, {
                            mode: EditorCardResolvedMode.DELETED,
                        });
                    }, 2000);
                } })) : (React.createElement(EditorCardDestroy
            // DUPLICATED NOTE: Here the getSubComponentEdits is being little misused. It is being used to delete
            //       a component when it is meant to delete a sub-component. However, because we're only
            //       deleting by text, it should work fine, but it is not ideal, and should be fixed via
            //       a general edit API, that can be used for both sub-component and compnent and it should
            //       explicitly take in as parameter whether edit is for component or sub-component
            , { ...getCardDestroyPropsForComponentDeleteWithGeneration(editor, markNodeMap, h, getSubComponentEdits, 
                // THIS CALLBACK IS COPIED FROM ABOVE
                () => floatsHandleRef.current?.resolveAndNext(addFloats.length + idx, {
                    mode: EditorCardResolvedMode.IGNORED,
                    onClickRevert: () => floatsHandleRef.current?.unresolve(addFloats.length + idx),
                })) }))))),
    }));
    const allFloats = addFloats.concat(removeFloats);
    useEffect(() => {
        if (refs.current) {
            Object.entries(refs.current).map(([id, floatElement]) => {
                if (floatElement) {
                    const h = componentHighlights.find((h) => h.id === id);
                    if (h && !isHighlightComponentMissing(h)) {
                        editor.update(() => {
                            $selectHighlightStart(h.id, markNodeMap);
                        });
                        setTimeout(() => {
                            showFloatOnHighlight(id, markNodeMap, editor, floatElement, anchorElement, true);
                        }, 2);
                        setTimeout(() => {
                            floatElement.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }, 50);
                    }
                    else {
                        setTimeout(() => {
                            showFloatOnRootTop(editor, floatElement, anchorElement, true);
                        }, 50);
                    }
                }
            });
        }
    }, [counter]);
    const onClickApplyAll = () => {
        editorPluginHighlightHandleRef?.current?.lock();
        setEditorFloatDataOnId?.("document", allFloats, floatsHandleRef, () => {
            unsetEditorFloatDataOnId?.("document");
            editorPluginHighlightHandleRef?.current?.unlock();
            onClose?.();
        });
        setIsEdit(false);
        setShow(false);
    };
    // ===============================
    // END EXPERIMENT WITH MULTI-FLOAT
    // ===============================
    // TODO: To add, currently relying on the getSuggestion of the component, but it may be possible
    //       that the component doesn't have a getSuggestion, in that case, to add, would need another
    //       key / value from consumer of this component
    return (show && (React.createElement(EditorCard, { bodyHeightFill: true, headerChildren: React.createElement(HeaderIconAndTitle, { title: "Clauses" },
            React.createElement(Button, { variant: "ghost", size: "sm", onClick: onClose, leftIconProps: {
                    IC: CancelIcon,
                } })), footerChildren: React.createElement("div", { className: classes.footer },
            !isEdit && (React.createElement(Button, { size: "sm", isFullWidth: true, text: "Edit Clauses", onClick: () => setIsEdit(true) })),
            isEdit && (staged.add.length > 0 || staged.remove.length > 0) && (React.createElement(React.Fragment, null,
                React.createElement(Button, { size: "sm", isFullWidth: true, text: "Apply Changes", onClick: onClickApplyAll }),
                React.createElement(Button, { isFullWidth: true, variant: "tertiary", size: "sm", text: "Cancel", onClick: onClose })))) },
        React.createElement("div", { className: classes.editorCardDocument },
            isEdit && React.createElement(Checklist, { items: updatedItems }),
            !isEdit && (React.createElement("div", { className: classes.nonEditChecklist },
                React.createElement(Checklist, { items: updatedItems.filter(({ selectCheckboxProps }) => selectCheckboxProps?.checked), hideAllCheckbox: true }),
                updatedItems.filter(({ selectCheckboxProps }) => !selectCheckboxProps?.checked).length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classes.divider }),
                    React.createElement(Text, { variant: "body-sm/semibold" }, "Missing"),
                    React.createElement(Checklist, { items: updatedItems.filter(({ selectCheckboxProps }) => !selectCheckboxProps?.checked), hideAllCheckbox: true })))))))));
};
export default EditorCardDocument;
