import clsx from "clsx";
import React from "react";
import CopyIcon from "../../../static/icons/copy-sm.svg";
import DislikeRoundedIcon from "../../../static/icons/dislike-rounded.svg";
import EditRoundedIcon from "../../../static/icons/edit-rounded.svg";
import LikeRoundedIcon from "../../../static/icons/like-rounded.svg";
import RedoIcon from "../../../static/icons/redo-sm.svg";
import classes from "./ChatMessage.module.scss";
import Button from "../../Button/ButtonAligned/Button";
import ButtonGroup from "../../Button/ButtonGroup";
import Loader from "../../Other/Loader";
import Text from "../../Text/TextAligned/Text";
import TextMarkdown from "../../Text/TextMarkdown";
import ChatCitationItem from "../ChatCitationItem";
const ChatMessage = ({ type, content, isLoading = false, error, citationItems = [], onClickRefresh, onClickEdit, onClickLike, onClickCopy, onClickDislike, followUpQuestions, }) => {
    console.log("CitationItems", citationItems);
    return (React.createElement("div", { className: clsx(classes.chatMessage, classes[type], {
            [classes["error"]]: error && !isLoading,
        }) },
        !isLoading && error && (React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "body-sm/semibold", color: "error", isBlock: true }, error.title || "Something went wrong."),
            React.createElement(Text, { variant: "body-sm" }, error.message || "Error Code: Description"))),
        !error && React.createElement(TextMarkdown, { variant: "body-sm" }, content),
        React.createElement("div", { className: classes.citations }, citationItems.map((citation) => (React.createElement(ChatCitationItem, { key: citation.id, ...citation })))),
        type === "assistant" && React.createElement(Loader, { isLoading: isLoading }),
        !isLoading && type === "assistant" && (React.createElement("div", { className: classes.actionsAndFollowups },
            React.createElement("div", { className: classes.actions },
                React.createElement("div", { className: classes.actionsLeft },
                    React.createElement(ButtonGroup, null,
                        !error && onClickCopy && (React.createElement(Button, { leftIconProps: {
                                IC: CopyIcon,
                                stroke: "#888888",
                            }, size: "sm", variant: "tertiary", onClick: onClickCopy })),
                        !error && onClickEdit && (React.createElement(Button, { leftIconProps: {
                                IC: EditRoundedIcon,
                                stroke: "#888888",
                            }, size: "sm", variant: "tertiary", onClick: onClickEdit })),
                        onClickRefresh && (React.createElement(Button, { leftIconProps: {
                                IC: RedoIcon,
                                stroke: "#888888",
                            }, size: "sm", variant: "tertiary", onClick: onClickRefresh })))),
                !error && (React.createElement("div", { className: classes.actionsRight },
                    onClickLike && (React.createElement(Button, { leftIconProps: {
                            IC: LikeRoundedIcon,
                        }, size: "sm", variant: "tertiary", onClick: onClickLike })),
                    onClickDislike && (React.createElement(Button, { leftIconProps: {
                            IC: DislikeRoundedIcon,
                        }, size: "sm", variant: "tertiary", onClick: onClickDislike }))))),
            !error && (React.createElement("div", { className: classes.followUpContainer }, followUpQuestions?.map((props, index) => (React.createElement(Button, { key: index, size: "sm", variant: "tertiary", ...props })))))))));
};
export default ChatMessage;
