import React from "react";
import "../../../styles/base.scss";
import "./Button.scss";
import borderRadiusMapping from "../../../constants/borderRadiusMapping";
import buttonSizeMapping from "../../../constants/buttonSizeMapping";
import buttonVariantMapping from "../../../constants/buttonVariantMapping";
import displayMapping from "../../../constants/displayMapping";
import { getTooltipClassNames, getTooltipStyles, } from "../../Other/Tooltip/Tooltip.utils";
const Button = ({ variant = "", size = "", isBadge = false, isSelected = false, outline = false, display = "", className = "", borderRadius = "8", noBorder = false, tooltipProps, disabled, children, style = {}, onClick = () => null, inactive = false, defaultCursor = false, translucentActive = false, }) => {
    const buttonClasses = `button
    ${buttonVariantMapping[variant]}${outline ? "--outline" : ""}
    ${borderRadiusMapping[borderRadius]}
    ${displayMapping[display]}
    ${buttonSizeMapping[size]}
    ${noBorder ? "button--no-border" : ""}
     ${tooltipProps
        ? getTooltipClassNames({
            ...tooltipProps,
        })
        : ""}
    ${isBadge ? "button--badge" : ""}
    ${isSelected ? "button--selected" : ""}
    ${inactive ? "button--inactive" : ""}
    ${translucentActive ? "button--translucent-active" : ""}
    ${defaultCursor ? "button--default-cursor" : ""}
    ${className}`;
    return (React.createElement("button", { className: buttonClasses, "data-tooltip": tooltipProps?.message, disabled: disabled, onClick: onClick, style: {
            ...style,
            ...getTooltipStyles({
                gap: tooltipProps?.gap,
                delayInSeconds: tooltipProps?.delayInSeconds,
            }),
        }, "data-testid": "test-button" }, children));
};
export default Button;
