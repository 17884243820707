/**
 * Checks if the provided name is valid.
 * @param name - The name to be validated.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the name is valid.
 */
export function isValidName(name, formData) {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(name) && name.length > 2;
}
export function validateName(name) {
    return {
        id: "validName",
        text: "",
        isValid: isValidName(name),
    };
}
/**
 * Checks if the provided email is valid.
 * @param email - The email to be validated.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the email is valid.
 */
export function isValidEmail(email, formData) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
export function validateEmail(email) {
    return {
        id: "validEmail",
        text: "",
        isValid: isValidEmail(email),
    };
}
/**
 * Checks if the provided password is valid.
 * @param password - The password to be validated.
 * @param type - The type of validation to perform ("sign-in" or "sign-up").
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password is valid.
 */
export function isValidPassword(password, type, formData) {
    if (type === "sign-in") {
        return password.length > 8;
    }
    const { isSpecialCharacter, hasCapitalLetter, hasValidLength, hasMatchingPasswords, } = getPasswordCriteria(password, formData);
    return (isSpecialCharacter &&
        hasCapitalLetter &&
        hasValidLength &&
        hasMatchingPasswords);
}
export function validatePassword(password, type) {
    return {
        id: "validPassword",
        text: "",
        isValid: isValidPassword(password, type),
    };
}
/**
 * Gets the criteria for a password.
 * @param password - The password to check criteria for.
 * @param formData - The form data containing the form data.
 * @returns An object containing criteria for the password.
 */
export function getPasswordCriteria(password, formData) {
    const isSpecialCharacter = checkSpecialCharacters(password, formData);
    const hasCapitalLetter = checkCapitalLetter(password, formData);
    const hasValidLength = checkLength(password, formData);
    const hasMatchingPasswords = checkMatchingPasswords(password, formData);
    return {
        isSpecialCharacter,
        hasCapitalLetter,
        hasValidLength,
        hasMatchingPasswords,
    };
}
/**
 * Checks if the password contains special characters.
 * @param password - The password to be checked.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password contains special characters.
 */
export function checkSpecialCharacters(password, formData) {
    const specialCharactersRegex = /[!@#&*]/;
    return specialCharactersRegex.test(password);
}
export function validateSpecialCharacters(password) {
    return {
        id: "checkSpecialCharacters",
        isValid: checkSpecialCharacters(password),
        text: "Use minimum 1 @#!&*",
    };
}
/**
 * Checks if the password contains a capital letter.
 * @param password - The password to be checked.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password contains a capital letter.
 */
export function checkCapitalLetter(password, formData) {
    const capitalLetterRegex = /[A-Z]/;
    return capitalLetterRegex.test(password);
}
export function validateCapitalLetter(password) {
    return {
        id: "checkCapitalLetter",
        isValid: checkCapitalLetter(password),
        text: "Use minimum 1 Capital letter",
    };
}
/**
 * Checks if the password contains a numeric character.
 * @param password - The password to be checked.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password contains a numeric character.
 */
export function checkNumericCharacter(password, formData) {
    const numericCharacterRegex = /[0-9]/;
    return numericCharacterRegex.test(password);
}
/**
 * Checks if the password has user attribution similarity.
 * @param password - The password to be checked.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password has user attribution similarity.
 */
export function checkUserAttributionSimilarity(password, formData) {
    // Implement logic for checking user attribution similarity
    return true;
}
/**
 * Checks if the password is a common password.
 * @param password - The password to be checked.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password is a common password.
 */
export function checkCommonPassword(password, formData) {
    // Implement logic for checking common password
    return true;
}
/**
 * Checks if the password meets the minimum length requirement.
 * @param password - The password to be checked.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password meets the minimum length requirement.
 */
export function checkLength(password, formData) {
    return password.length >= 8;
}
export function validateLength(password) {
    return {
        id: "checkValidLength",
        isValid: checkLength(password),
        text: "Length 8 charaters",
    };
}
/**
 * Checks if the provided password matches the confirmation password.
 * @param password - The password to be checked.
 * @param formData - The form data containing the form data.
 * @returns A boolean indicating if the password matches the confirmation password.
 */
export function checkMatchingPasswords(password, formData) {
    // Implement logic for checking matching passwords
    return true;
}
export function validateMatchingPassword(password) {
    return {
        id: "checkMatchingPasswords",
        isValid: checkMatchingPasswords(password),
        text: "Passowords are matching",
    };
}
