import clsx from "clsx";
import { produce } from "immer";
import React, { useState } from "react";
import classes from "./DocumentUploader.module.scss";
import { MimeType } from "../../../types";
import { formatBytes } from "../../../utils";
import Button from "../../Button/ButtonAligned/Button";
import { MimeDocumentIconMap } from "../../Other/DocumentIcon/DocumentIcon";
import SelectDocumentAligned from "../../Other/SelectDocumentAligned";
import UploadFileItem from "../../Other/UploadFileItem";
import Text from "../../Text/TextAligned/Text";
const DocumentUploader = ({ onFileUpload, onClickContinue, onClickDelete, multiple, maxFiles, fileTypes, filesInRow, selectDescription, variant = "vertical", }) => {
    // Define a language for error and the onFileUpload
    // callback should follow that error language.
    // onFileUpload to return the Promise.
    // All the interaction and states can be build into this
    const [filesData, setFilesData] = useState([]);
    const onToggleOCRChecked = (idx) => {
        setFilesData((oldFilesData) => {
            return produce(oldFilesData, (draft) => {
                draft[idx].isOcr = !draft[idx].isOcr;
            });
        });
    };
    const deleteHandler = (idx) => {
        const uuid = filesData[idx].uuid;
        if (uuid) {
            onClickDelete(uuid || "", () => {
                // success handler
                setFilesData((oldFilesData) => oldFilesData.filter((f) => f.uuid !== uuid));
                // TODO: add failure handler
            });
        }
    };
    const getErrorHandler = (idx) => {
        return () => {
            setFilesData((oldFilesData) => {
                return produce(oldFilesData, (draft) => {
                    draft[idx].isError = true;
                    draft[idx].errorItem = {
                        id: "1",
                        text: "Some error has occured",
                        isRetryAllowed: false,
                    };
                });
            });
        };
    };
    const getOnUploadProgress = (idx) => {
        return (progressEvent) => {
            setFilesData((oldFilesData) => {
                return produce(oldFilesData, (draft) => {
                    draft[idx].progress = progressEvent.progress;
                });
            });
        };
    };
    const uploadHandler = (files) => {
        const presentFileNum = filesData.length;
        files.map((file, idx) => {
            // set the initial data
            setFilesData((oldFilesData) => {
                return produce(oldFilesData, (draft) => {
                    draft[presentFileNum + idx] = {
                        file,
                        isUploaded: false,
                    };
                    return draft;
                });
            });
            onFileUpload(file, getOnUploadProgress(presentFileNum + idx), getErrorHandler(presentFileNum + idx)).then((data) => {
                if (data) {
                    setFilesData((oldFilesData) => {
                        return produce(oldFilesData, (draft) => {
                            draft[presentFileNum + idx].uuid = data.uuid;
                            draft[presentFileNum + idx].isUploaded = true;
                            return draft;
                        });
                    });
                }
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectDocumentAligned, { onFilesSelect: uploadHandler, multiple: multiple, fileTypes: fileTypes, maxFiles: maxFiles, description: selectDescription, variant: variant }),
        filesData.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Text, { className: classes.textMargin, isBlock: true, variant: "heading-lg" }, "Files"),
            React.createElement("div", { className: clsx(classes.files, { [classes.filesRow]: filesInRow }) }, filesData?.map((fileData, idx) => {
                if (fileData.file) {
                    return (React.createElement("div", { key: idx, className: classes.file },
                        React.createElement(UploadFileItem, { text: fileData.file.name, key: idx, id: idx.toString(), size: formatBytes(fileData.file.size), isUploaded: fileData.isUploaded, showProgress: true, showDeleteInFooter: false, progress: fileData.progress, isError: fileData.isError, errorItem: fileData.errorItem, onClickCancel: () => null, showOCRCheckbox: fileData.file.type === MimeType.PDF, iconProps: {
                                IC: MimeDocumentIconMap[fileData.file.type || MimeType.PDF],
                            }, isOCRChecked: fileData.isOcr, onToggleOCRChecked: () => onToggleOCRChecked(idx), onClickDelete: () => deleteHandler(idx) })));
                }
            })))),
        filesData.length > 0 && (React.createElement(Button, { text: "Continue", size: "md", onClick: () => {
                onClickContinue(filesData);
                setFilesData([]); // reset
            }, disabled: !filesData.every((file) => file.isUploaded || file.isError) }))));
};
export default DocumentUploader;
